/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./title.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./title.component";
var styles_TitleComponent = [i0.styles];
var RenderType_TitleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TitleComponent, data: {} });
export { RenderType_TitleComponent as RenderType_TitleComponent };
export function View_TitleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_TitleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-title", [], null, null, null, View_TitleComponent_0, RenderType_TitleComponent)), i1.ɵdid(1, 114688, null, 0, i2.TitleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TitleComponentNgFactory = i1.ɵccf("app-title", i2.TitleComponent, View_TitleComponent_Host_0, {}, {}, ["*"]);
export { TitleComponentNgFactory as TitleComponentNgFactory };
