var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
var listQuery = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nquery listReportsForSite($id: ID) {\n  site(id: $id) {\n    id\n    reports {\n      id\n      created\n      data\n    }\n  }\n}\n"], ["\nquery listReportsForSite($id: ID) {\n  site(id: $id) {\n    id\n    reports {\n      id\n      created\n      data\n    }\n  }\n}\n"])));
var getByIdQuery = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\nquery getReportById($id: ID) {\n  report(id: $id) {\n    id\n    created\n    data\n  }\n}\n"], ["\nquery getReportById($id: ID) {\n  report(id: $id) {\n    id\n    created\n    data\n  }\n}\n"])));
var mutation = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\nmutation reportMutation($report: ireport!) {\n  report(report: $report) {\n    created\n    data\n    id\n    site {\n      id\n    }\n  }\n}\n"], ["\nmutation reportMutation($report: ireport!) {\n  report(report: $report) {\n    created\n    data\n    id\n    site {\n      id\n    }\n  }\n}\n"])));
var deleteMutation = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\nmutation DeleteReportMutation($report: ireport!) {\n  deleteReport(report: $report)\n}\n"], ["\nmutation DeleteReportMutation($report: ireport!) {\n  deleteReport(report: $report)\n}\n"])));
function formatReport(raw) {
    return __assign({}, raw, { created: new Date(raw.created), data: JSON.parse(raw.data) });
}
var ReportService = /** @class */ (function () {
    function ReportService(apollo) {
        this.apollo = apollo;
    }
    ReportService.prototype.list = function (id) {
        return this.apollo.query({ query: listQuery, variables: { id: id } }).pipe(map(function (result) { return result.data.site.reports.map(formatReport); }));
    };
    ReportService.prototype.get = function (id) {
        return this.apollo.query({ query: getByIdQuery, variables: { id: id } }).pipe(map(function (t) { return formatReport(t.data.report); }));
    };
    ReportService.prototype.create = function (model) {
        if (model.id !== undefined && model.id !== null && model.id !== '') {
            throw new Error('You can only create a new Site');
        }
        return this.apollo.mutate({
            mutation: mutation, variables: {
                report: __assign({}, model, { data: JSON.stringify(model.data) })
            }
        }).pipe(map(function (t) { return formatReport(t.data.report); }));
    };
    ReportService.prototype.update = function (model) {
        if (!model.id || typeof model.id !== 'string' || model.id === '') {
            throw new Error('You can only update an existing Site');
        }
        return this.apollo.mutate({
            mutation: mutation, variables: {
                report: __assign({}, model, { data: JSON.stringify(model.data) })
            }
        }).pipe(map(function (t) { return formatReport(t.data.report); }));
    };
    ReportService.prototype.delete = function (model, siteId) {
        if (!model.id || typeof model.id !== 'string' || model.id === '') {
            throw new Error('You can only update an existing User');
        }
        return this.apollo.mutate({
            mutation: deleteMutation,
            variables: {
                report: __assign({}, model, { data: JSON.stringify(model.data) })
            },
            refetchQueries: [{
                    query: listQuery,
                    variables: { id: siteId },
                }]
        });
    };
    return ReportService;
}());
export { ReportService };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
