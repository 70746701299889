import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SiteService } from '../../services/site/site.service';
import { Site } from '../../models/site';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-site-form-dialog',
  templateUrl: './site-form-dialog.component.html',
  styleUrls: ['./site-form-dialog.component.scss']
})
export class SiteFormDialogComponent implements OnInit {

  siteForm: FormGroup;

  constructor(private service: SiteService, private dialogRef: MatDialogRef<SiteFormDialogComponent>, private formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: Site) {
  }

  ngOnInit() {
    this.siteForm = this.formBuilder.group({
      id: [null],
      customer: this.formBuilder.group({
        id: [<string>this.data.customer.id, Validators.required],
        name: [<string>this.data.customer.name, Validators.required]
      }),
      name: [null, Validators.required],
      address: [null, Validators.required],
      zipCode: [null, Validators.required],
      map: [null]
    });

    if (this.data) {
      this.siteForm.patchValue(this.data);
    }
  }

  async onSubmit() {
    if (this.siteForm.valid && this.siteForm.dirty) {
      try {
        let result;
        const site = this.siteForm.value;
        if (site.id) {
          result = await this.service.update(site).toPromise();
        } else {
          result = await this.service.create(site).toPromise();
        }

        this.dialogRef.close({
          succeeded: true,
          site: result
        });
      } catch (err) {
        console.error(err);
      }
    }
  }

}
