import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../services/session/session.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  private returnUrl: string;
  loginForm: FormGroup;

  constructor(private session: SessionService, private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder) {
    this.route.queryParams.subscribe(queryParams => {
      this.returnUrl = queryParams['returnUrl'] || '/';
    });
  }

  async ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    if (this.session.isAuthenticated) {
      await this.router.navigateByUrl('/');
    }
  }

  async onLogin() {
    if (this.loginForm.valid) {
      try {
        const result = await this.session.login(this.loginForm.value);
        if (result.succeeded) {
          return this.router.navigateByUrl(this.returnUrl);
        } else {
          alert(result.message || 'Login failed');
        }
      } catch (err) {
        alert('Login failed');
      }
    }
  }

}
