var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ControlAgentService } from '../../services/control-agent/control-agent.service';
import { SiteService } from '../../services/site/site.service';
import { DocumentType } from '../../models/document';
import { map, startWith } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material';
import { FileService } from '../../services/file/file.service';
var UploadFileDialogComponent = /** @class */ (function () {
    function UploadFileDialogComponent(dialogRef, data, formBuilder, changeDetectorRef, service, agentService, siteService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.formBuilder = formBuilder;
        this.changeDetectorRef = changeDetectorRef;
        this.service = service;
        this.agentService = agentService;
        this.siteService = siteService;
        this.relationOptions = [];
        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }
    UploadFileDialogComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        window.addEventListener('dragenter', this.onDragEnter);
                        window.addEventListener('dragleave', this.onDragLeave);
                        this.containerEl.nativeElement.addEventListener('dragover', this.onDragOver);
                        this.containerEl.nativeElement.addEventListener('drop', this.onDrop);
                        this.form = this.formBuilder.group({
                            file: [null, Validators.required],
                            description: ['', Validators.required],
                            type: [null, Validators.required],
                            relation: [null, Validators.required]
                        });
                        this.filteredRelationOptions = this.form.get('relation').valueChanges.pipe(startWith(''), map(function (value) { return typeof value === 'string' ? value : value.label; }), map(function (name) { return name ? _this.filterRelationOptions(name) : _this.relationOptions.slice(); }));
                        return [4 /*yield*/, this.updateRelationOptions(this.form.get('type').value)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    UploadFileDialogComponent.prototype.ngOnDestroy = function () {
        window.removeEventListener('dragenter', this.onDragEnter);
        window.removeEventListener('dragleave', this.onDragLeave);
        this.containerEl.nativeElement.removeEventListener('dragover', this.onDragOver);
        this.containerEl.nativeElement.removeEventListener('drop', this.onDrop);
    };
    UploadFileDialogComponent.prototype.filterRelationOptions = function (name) {
        return this.relationOptions.filter(function (t) { return t.label.toLowerCase().indexOf(name.toLowerCase()) > -1; });
    };
    UploadFileDialogComponent.prototype.updateRelationOptions = function (type) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _a = type;
                        switch (_a) {
                            case DocumentType.SafetyInstruction: return [3 /*break*/, 1];
                            case DocumentType.SiteSpecificDocument: return [3 /*break*/, 3];
                        }
                        return [3 /*break*/, 5];
                    case 1:
                        _b = this;
                        return [4 /*yield*/, this.agentService.list()
                                .pipe(map(function (t) { return t.map(function (entry) { return ({ id: entry.id, label: entry.displayName }); }); }))
                                .toPromise()];
                    case 2:
                        _b.relationOptions = _d.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        _c = this;
                        return [4 /*yield*/, this.siteService.list()
                                .pipe(map(function (t) { return t.map(function (entry) { return ({ id: entry.id, label: entry.customer.name + ": " + entry.name }); }); }))
                                .toPromise()];
                    case 4:
                        _c.relationOptions = _d.sent();
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    UploadFileDialogComponent.prototype.relationDisplayWithFn = function (value) {
        return value ? value.label : undefined;
    };
    UploadFileDialogComponent.prototype.onDragEnter = function (event) {
        this.dragging = true;
    };
    UploadFileDialogComponent.prototype.onDragLeave = function (event) {
        if (event.clientX === 0 && event.clientY === 0) {
            this.dragging = false;
        }
    };
    UploadFileDialogComponent.prototype.onDragOver = function (event) {
        event.preventDefault();
    };
    UploadFileDialogComponent.prototype.setFile = function (file) {
        this.file = file;
        this.form.get('file').setValue(file.name);
    };
    UploadFileDialogComponent.prototype.onDrop = function (event) {
        event.preventDefault();
        if (event.dataTransfer.files && event.dataTransfer.files.length) {
            var file = event.dataTransfer.files[0];
            this.setFile(file);
        }
        if (event.dataTransfer.items) {
            event.dataTransfer.items.clear();
        }
        else {
            event.dataTransfer.clearData();
        }
        this.dragging = false;
    };
    UploadFileDialogComponent.prototype.onFileChange = function (event) {
        if (event.target.files && event.target.files.length) {
            this.setFile(event.target.files[0]);
        }
    };
    UploadFileDialogComponent.prototype.onSelectFile = function () {
        this.fileInputEl.nativeElement.click();
    };
    UploadFileDialogComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.form.valid) {
            this.service.upload({
                description: this.form.value.description,
                type: this.form.value.type,
                relationId: this.form.value.relation.id
            }, this.file).subscribe(function (result) {
                console.log(result);
                _this.dialogRef.close(result);
            }, function (err) {
                console.error(err);
            });
        }
    };
    return UploadFileDialogComponent;
}());
export { UploadFileDialogComponent };
