import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ControlAgent } from '../../models/control-agent';
import { Site } from '../../models/site';
import { Report } from '../../models/report';
import { ReportService } from '../../services/report/report.service';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  site: Site;
  controlAgents: ControlAgent[];
  report: Report;
  editing = false;

  get canEdit(): boolean {
    return true;
  }

  constructor(private route: ActivatedRoute, private service: ReportService, private router: Router, public session: SessionService) {
  }

  ngOnInit() {
    if (this.route.snapshot.params['reportId'] === 'new') {
      this.editing = true;
    }

    this.site = this.route.snapshot.data.site;
    this.controlAgents = this.route.snapshot.data.controlAgents;
    this.report = this.route.snapshot.data.report || this.service.activeReport || {};
    this.service.activeReport = undefined;
  }

  toggleEdit() {
    this.editing = !this.editing;
  }

  print() {
    window.print();
  }

  onReportChange(reportChange: { type: string, report: Report }) {
    if (reportChange.type === 'cancelled') {
      if (this.route.snapshot.params['reportId'] === 'new') {
        this.router.navigate(['/site', this.route.snapshot.params['siteId']]);
      } else {
        this.editing = false;
      }
    } else if (reportChange.type === 'submitted') {
      if (this.route.snapshot.params['reportId'] === 'new') {
        this.service.create(reportChange.report).subscribe(result => {
          this.report = result;
          this.editing = false;
        });
      } else {
        this.service.update(reportChange.report).subscribe(success => {
          if (success) {
            this.report = reportChange.report;
            this.editing = false;
          } else {
            console.error('Failed to update report');
          }
        });
      }
    }
  }

}
