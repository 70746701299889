<form [formGroup]="form" novalidate (ngSubmit)="onSubmit()">
  <mat-form-field>
    <input matInput placeholder="Tunniste" formControlName="id">
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Kommentti" formControlName="comment">
  </mat-form-field>
  <mat-form-field *ngIf="type < 2">
    <mat-select placeholder="Syötti" formControlName="agentId">
      <mat-option *ngFor="let agent of agents | async" [value]="agent.id">
        {{ agent.displayName }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="controls">
    <button mat-button color="warn" type="button" (click)="onDelete()" *ngIf="isNotNew">
      Poista
    </button>
    <button mat-raised-button color="primary">
      Tallenna
    </button>
  </div>
</form>
