import { Component, Input, OnInit } from '@angular/core';
import { ControlAgent } from '../../../models/control-agent';
import { Site } from '../../../models/site';
import { Report } from '../../../models/report';
import { ControlAgentService } from '../../../services/control-agent/control-agent.service';

@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.scss']
})
export class ReportViewComponent implements OnInit {

  @Input() site: Site;
  @Input() controlAgents: ControlAgent[];
  @Input() report: Report;
  usedControlAgents: any[];

  constructor(private service: ControlAgentService) {}

  async ngOnInit() {
    console.log(this.report);
    const usedAgents: any = this.report.data.baitTrapReports.map(t => ({ agentId: t.agentId, amount: t.agentAmount }))
      .concat(this.report.data.sprayTrapReports.map(t => ({ agentId: t.agentId, amount: t.agentAmount })))
      .reduce((result, entry) => {
        if (!result[entry.agentId]) {
          result[entry.agentId] = 0;
        }
        result[entry.agentId] += +entry.amount;
        return result;
      }, {});

    this.usedControlAgents = (await Promise.all(Object.keys(usedAgents).map(async agentId => {
      const agent = await this.service.get(agentId).toPromise();
      return { ...agent, amount: usedAgents[agentId] };
    }))).filter(agent => agent.amount > 0);
  }

}
