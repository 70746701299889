<div style="height: 300px" leaflet *ngIf="hasMapImage"
     [ngClass]="{'dragging': isDragging}"
     [leafletOptions]="mapOptions"
     [leafletLayers]="mapLayers"
     [leafletCenter]="mapCenter"
     (leafletMapReady)="onMapReady($event)"></div>
<div class="empty" *ngIf="hasMap && !hasMapImage || editable && !hasMapImage" #newMapBanner>
  <h4>Kohteelle ei ole vielä lisätty karttakuvaa!</h4>
  <span *ngIf="editable">Lisää karttakuva pudottamalla kuva tähän.</span>
</div>
