import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ControlAgentService } from '../../services/control-agent/control-agent.service';
import { SiteService } from '../../services/site/site.service';
import { DocumentType } from '../../models/document';
import { Observable } from 'rxjs';
import { map, mergeMap, startWith, tap } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FileService } from '../../services/file/file.service';

@Component({
  selector: 'app-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
  styleUrls: ['./upload-file-dialog.component.scss']
})
export class UploadFileDialogComponent implements OnInit, OnDestroy {

  @ViewChild('container', { read: ElementRef }) containerEl;
  @ViewChild('fileInput', { read: ElementRef }) fileInputEl;
  dragging: boolean;
  relationOptions: { id: string, label: string }[] = [];
  filteredRelationOptions: Observable<{ id: string, label: string }[]>;

  form: FormGroup;
  file: File;

  constructor(private dialogRef: MatDialogRef<UploadFileDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private formBuilder: FormBuilder, private changeDetectorRef: ChangeDetectorRef, private service: FileService,
              private agentService: ControlAgentService, private siteService: SiteService) {
    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  async ngOnInit() {
    window.addEventListener('dragenter', this.onDragEnter);
    window.addEventListener('dragleave', this.onDragLeave);
    this.containerEl.nativeElement.addEventListener('dragover', this.onDragOver);
    this.containerEl.nativeElement.addEventListener('drop', this.onDrop);
    this.form = this.formBuilder.group({
      file: [null, Validators.required],
      description: ['', Validators.required],
      type: [null, Validators.required],
      relation: [null, Validators.required]
    });
    this.filteredRelationOptions = this.form.get('relation').valueChanges.pipe(
      startWith(''),
      map((value: any) => typeof value === 'string' ? value : value.label),
      map(name => name ? this.filterRelationOptions(name) : this.relationOptions.slice())
    );
    await this.updateRelationOptions(this.form.get('type').value);
  }

  ngOnDestroy() {
    window.removeEventListener('dragenter', this.onDragEnter);
    window.removeEventListener('dragleave', this.onDragLeave);
    this.containerEl.nativeElement.removeEventListener('dragover', this.onDragOver);
    this.containerEl.nativeElement.removeEventListener('drop', this.onDrop);
  }

  filterRelationOptions(name: string) {
    return this.relationOptions.filter(t => t.label.toLowerCase().indexOf(name.toLowerCase()) > -1);
  }

  async updateRelationOptions(type: number): Promise<void> {
    switch (type) {
      case DocumentType.SafetyInstruction:
        this.relationOptions = await this.agentService.list()
          .pipe(
            map(t => t.map(entry => ({ id: entry.id, label: entry.displayName })))
          )
          .toPromise();
        break;
      case DocumentType.SiteSpecificDocument:
        this.relationOptions = await this.siteService.list()
          .pipe(
            map(t => t.map(entry => ({ id: entry.id, label: `${entry.customer.name}: ${entry.name}` })))
          )
          .toPromise();
        break;
    }
  }

  relationDisplayWithFn(value: { id: string, label: string }) {
    return value ? value.label : undefined;
  }

  private onDragEnter(event) {
    this.dragging = true;
  }

  private onDragLeave(event) {
    if (event.clientX === 0 && event.clientY === 0) {
      this.dragging = false;
    }
  }

  private onDragOver(event) {
    event.preventDefault();
  }

  private setFile(file: File) {
    this.file = file;
    this.form.get('file').setValue(file.name);
  }

  private onDrop(event) {
    event.preventDefault();

    if (event.dataTransfer.files && event.dataTransfer.files.length) {
      const file = event.dataTransfer.files[0];
      this.setFile(file);
    }

    if (event.dataTransfer.items) {
      event.dataTransfer.items.clear();
    } else {
      event.dataTransfer.clearData();
    }

    this.dragging = false;
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      this.setFile(event.target.files[0]);
    }
  }

  onSelectFile() {
    this.fileInputEl.nativeElement.click();
  }

  onSubmit() {
    if (this.form.valid) {
      this.service.upload({
        description: this.form.value.description,
        type: this.form.value.type,
        relationId: this.form.value.relation.id
      }, this.file).subscribe(result => {
        console.log(result);
        this.dialogRef.close(result);
      }, err => {
        console.error(err);
      });
    }
  }

}
