import { ChangeDetectorRef, Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MapComponent } from '../../elements/map/map.component';
import { ControlMap } from '../../models/map';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatTableDataSource } from '@angular/material';
import { LeafletMouseEvent } from 'leaflet';
import { Trap, TrapType } from '../../models/trap';
import { SiteService } from '../../services/site/site.service';
import { CreateTrapDialogComponent } from '../create-trap-dialog/create-trap-dialog.component';
import { Point } from '../../models/geometry';
import { DialogPosition } from '@angular/material/dialog/typings/dialog-config';

@Component({
  selector: 'app-map-editor',
  templateUrl: './map-editor.component.html',
  styleUrls: ['./map-editor.component.scss']
})
export class MapEditorComponent implements OnInit {

  @ViewChild('map') mapComponent: MapComponent;
  mapData: ControlMap;
  displayedColumns = ['id', 'type'];
  contextMenu: { isOpen: boolean, event: LeafletMouseEvent, position: { x: number, y: number } } = {
    isOpen: false,
    event: null,
    position: {
      x: 0,
      y: 0
    }
  };

  get contextMenuOpen(): boolean {
    return this.contextMenu.isOpen;
  }

  constructor(private dialogRef: MatDialogRef<MapEditorComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private changeDetectorRef: ChangeDetectorRef, private service: SiteService, private dialog: MatDialog,
              private ngZone: NgZone) {
  }

  ngOnInit() {
    if (!this.data.mapData) {
      this.mapData = {
        id: null,
        revision: 0,
        site: {
          id: this.data.site.id
        },
        name: 'control_map_1',
        mapImage: {
          id: null,
          mimeType: null,
          data: null
        },
        traps: []
      };
    } else {
      this.mapData = this.data.mapData;
    }

  }

  onOutsideContextClick(event) {
    if (event.target.parentElement.classList.contains('mat-list-item') || event.target.parentElement.classList.contains('mat-list')) {
      event.preventDefault();
      return;
    }
    this.contextMenu.isOpen = false;
    window.removeEventListener('click', this.onOutsideContextClick.bind(this));
  }

  onContextMenu(event: LeafletMouseEvent) {
    this.contextMenu.event = event;
    this.contextMenu.position.x = event.originalEvent.clientX;
    this.contextMenu.position.y = event.originalEvent.clientY;
    this.contextMenu.isOpen = true;
    window.addEventListener('click', this.onOutsideContextClick.bind(this));
    this.changeDetectorRef.detectChanges();
  }

  openTrapDialog(position: DialogPosition, trap: Trap) {
    const dialogRef = this.dialog.open(CreateTrapDialogComponent, {
      position,
      data: trap
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      const finalTrap = result.trap;
      const index = this.mapData.traps.findIndex(t => t.id === finalTrap.id);

      if (result.type === 'delete') {
        this.mapData = {
          ...this.mapData,
          traps: [
            ...this.mapData.traps.slice(0, index),
            ...this.mapData.traps.slice(index + 1, this.mapData.traps.length),
          ]
        };
        this.mapComponent.removeMarker(finalTrap);
      } else {
        if (index > -1) {
          this.mapData = {
            ...this.mapData,
            traps: [
              ...this.mapData.traps.slice(0, index),
              finalTrap,
              ...this.mapData.traps.slice(index + 1, this.mapData.traps.length),
            ]
          };
        } else {
          this.mapData = {
            ...this.mapData,
            traps: [...this.mapData.traps, finalTrap]
          };
        }

        this.mapComponent.addMarker(finalTrap);
      }

      this.contextMenu.event = null;
      this.changeDetectorRef.detectChanges();
    });
  }

  onAddTrap(type: number) {
    if (!this.contextMenu.isOpen) {
      return;
    }

    this.ngZone.run(() => {
      this.contextMenu.isOpen = false;
      this.openTrapDialog(
        {
          top: this.contextMenu.position.y + 'px',
          left: this.contextMenu.position.x + 'px'
        },
        <Trap>{
          type: type,
          location: {
            x: this.contextMenu.event.latlng.lng,
            y: this.contextMenu.event.latlng.lat
          }
        });
    });
  }

  onMapDataChange(mapData: ControlMap) {
    this.mapData = mapData;
    this.mapComponent.updateMap();
  }

  onTrapChange(trap: Trap) {
    const index = this.mapData.traps.findIndex(t => t.id === trap.id);
    this.mapData = {
      ...this.mapData,
      traps: [
        ...this.mapData.traps.slice(0, index),
        trap,
        ...this.mapData.traps.slice(index + 1, this.mapData.traps.length),
      ]
    };
  }

  onTrapClick(event) {
    this.ngZone.run(() => {
      this.openTrapDialog(
        {
          top: event.location.y + 'px',
          left: event.location.x + 'px'
        },
        event.trap
      );
    });
  }

  async onUpdateMap() {
    await this.service.updateMap(this.data.site, this.mapData);
    this.dialogRef.close(this.mapData);
  }
}
