<div class="flex-container column">
  <div class="control-bar">
    <span class="fill"></span>
    <button mat-raised-button color="primary" (click)="onAdd()">
      <mat-icon>add</mat-icon>
      Lisää asiakkuus
    </button>
  </div>
  <app-list [contentPadding]="0">
    <app-list-item *ngFor="let customer of customers">
      {{ customer.name }}
      <span class="fill"></span>
      <mat-chip-list>
        <mat-chip color="primary" selected="true" routerLink="/sites/{{ customer.id }}">Kohteita: {{ customer.sites.length }}</mat-chip>
      </mat-chip-list>
    </app-list-item>
  </app-list>
</div>
