import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UserService } from '../../services/user/user.service';

function confirmValueFrom(targetControlGetter: () => AbstractControl) {
  return (confirmControl: AbstractControl) => new Promise((resolve) => {
    const targetControl = targetControlGetter();
    if (confirmControl.value !== targetControl.value) {
      resolve({ confirmMismatch: true });
    } else {
      resolve({});
    }
  });
}

@Component({
  selector: 'app-new-password-dialog',
  template: `
    <form [formGroup]="passwordForm" novalidate (ngSubmit)="onSubmit()">
      <mat-form-field>
        <input matInput type="password" placeholder="Uusi salasana" formControlName="password">
      </mat-form-field>
      <mat-form-field>
        <input matInput type="password" placeholder="Vahvista uusi salasana" formControlName="confirmPassword">
      </mat-form-field>
      <button mat-raised-button color="primary">
        Vaihda salasana
      </button>
    </form>
  `,
  styles: [`
    form {
      display: flex;
      flex-direction: column;
    }
  `]
})
export class NewPasswordDialogComponent implements OnInit {

  passwordForm: FormGroup;

  constructor(private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) private userId: string, private service: UserService,
              private dialogRef: MatDialogRef<NewPasswordDialogComponent>) {
  }

  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required, confirmValueFrom(() => this.passwordForm.get('password'))]
    });
  }

  onSubmit() {
    console.log(this.passwordForm.get('confirmPassword').errors);
    if (this.passwordForm.valid) {
      this.service.changePassword(this.userId, this.passwordForm.get('password').value).subscribe(() => {
        this.dialogRef.close();
      });
    }
  }

}
