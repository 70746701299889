import { OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
var ErrorComponent = /** @class */ (function () {
    function ErrorComponent(router, route) {
        this.router = router;
        this.route = route;
        this.errorCode = 404;
    }
    Object.defineProperty(ErrorComponent.prototype, "errorDetails", {
        get: function () {
            var details = 'Status Code: ' + this.errorCode;
            if (this.errorMessage) {
                details += '\nStatus Message: ' + this.errorMessage;
            }
            details += '\nCurrent URL: ' + this.currentUrl;
            return details;
        },
        enumerable: true,
        configurable: true
    });
    ErrorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentUrl = this.router.url.split(';')[0];
        this.route.params.subscribe(function (params) {
            if (params['type']) {
                switch (params['type']) {
                    case 'forbidden':
                        _this.errorCode = 403;
                        _this.errorMessage = 'Forbidden';
                        break;
                    default:
                        break;
                }
            }
        });
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                _this.currentUrl = _this.router.url.split(';')[0];
            }
        });
    };
    return ErrorComponent;
}());
export { ErrorComponent };
