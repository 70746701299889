<div class="flex-container">
  <app-panel>
    <app-panel-header>
      Käyttäjähallinta
      <div class="controls">
        <button mat-button (click)="onAddUser()">
          <mat-icon>add</mat-icon>
          Lisää uusi käyttäjä
        </button>
      </div>
    </app-panel-header>
    <table mat-table [dataSource]="users">
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef>Käyttäjätunnus</th>
        <td mat-cell *matCellDef="let entry">{{ entry.username }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Nimi</th>
        <td mat-cell *matCellDef="let entry">{{ entry.name }}</td>
      </ng-container>

      <ng-container matColumnDef="admin">
        <th mat-header-cell *matHeaderCellDef>Pääkäyttäjä</th>
        <td mat-cell *matCellDef="let entry">
          <mat-icon *ngIf="entry.software_role == 'ADMIN'">done</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Toiminnot</th>
        <td mat-cell *matCellDef="let entry">
          <button mat-button title="Vaihda salasana" color="primary" (click)="onChangePassword(entry)">
            Vaihda Salasana
          </button>
          <button mat-icon-button title="Poista" color="warn" (click)="onDelete(entry)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </app-panel>
</div>
