/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./list.component";
var styles_ListComponent = [i0.styles];
var RenderType_ListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ListComponent, data: {} });
export { RenderType_ListComponent as RenderType_ListComponent };
export function View_ListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "list-wrapper"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "padding": 0 }), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.contentPadding); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-list", [], null, null, null, View_ListComponent_0, RenderType_ListComponent)), i1.ɵdid(1, 114688, null, 0, i3.ListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ListComponentNgFactory = i1.ɵccf("app-list", i3.ListComponent, View_ListComponent_Host_0, { contentPadding: "contentPadding" }, {}, ["*"]);
export { ListComponentNgFactory as ListComponentNgFactory };
