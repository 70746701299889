<div class="container">
  <app-report-edit [site]="site" [controlAgents]="controlAgents" [report]="report" (reportChange)="onReportChange($event)" *ngIf="editing"></app-report-edit>
  <app-report-view [site]="site" [controlAgents]="controlAgents" [report]="report" *ngIf="!editing"></app-report-view>
  <div class="controls">
    <!--<button mat-mini-fab color="warn">
      <mat-icon>delete</mat-icon>
    </button>-->
    <button mat-mini-fab color="primary" (click)="toggleEdit()" *ngIf="canEdit && !editing && session.isAdmin">
      <mat-icon>edit</mat-icon>
    </button>
    <button mat-mini-fab color="primary" title="Tulosta" (click)="print()" *ngIf="!editing">
      <mat-icon>print</mat-icon>
    </button>
  </div>
</div>
