var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
var listQuery = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nquery listUsers {\n  users {\n    id\n    name\n    username\n    software_role\n  }\n}\n"], ["\nquery listUsers {\n  users {\n    id\n    name\n    username\n    software_role\n  }\n}\n"])));
var listByNameQuery = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\nquery listUsersBy($name: String) {\n  users(name: $name) {\n    id\n    name\n    username\n    software_role\n  }\n}\n"], ["\nquery listUsersBy($name: String) {\n  users(name: $name) {\n    id\n    name\n    username\n    software_role\n  }\n}\n"])));
var getByIdQuery = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\nquery getUserById($id: ID) {\n  user(id: $id) {\n    id\n    name\n    username\n    software_role\n  }\n}\n"], ["\nquery getUserById($id: ID) {\n  user(id: $id) {\n    id\n    name\n    username\n    software_role\n  }\n}\n"])));
// TODO: Add permissions to user schema in gql
var mutation = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\nmutation userMutation($user: iuser!) {\n  user(user: $user) {\n    id\n    name\n    username\n    software_role\n  }\n}\n"], ["\nmutation userMutation($user: iuser!) {\n  user(user: $user) {\n    id\n    name\n    username\n    software_role\n  }\n}\n"])));
var deleteMutation = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\nmutation DeleteUserMutation($user: iuser!) {\n  deleteUser(user: $user)\n}\n"], ["\nmutation DeleteUserMutation($user: iuser!) {\n  deleteUser(user: $user)\n}\n"])));
var UserService = /** @class */ (function () {
    function UserService(apollo) {
        this.apollo = apollo;
    }
    UserService.prototype.list = function () {
        return this.apollo.watchQuery({ query: listQuery }).valueChanges.pipe(map(function (result) { return result.data.users; }));
    };
    UserService.prototype.get = function (id) {
        return this.apollo.watchQuery({ query: getByIdQuery, variables: { id: id } }).valueChanges.pipe(map(function (t) { return t.data.user; }));
    };
    UserService.prototype.create = function (model) {
        if (model.id !== undefined && model.id !== null && model.id !== '') {
            throw new Error('You can only create a new User');
        }
        return this.apollo.mutate({
            mutation: mutation,
            variables: { user: model },
            refetchQueries: [{
                    query: listQuery,
                }]
        }).pipe(map(function (t) { return t.data.user; }));
    };
    UserService.prototype.update = function (model) {
        if (!model.id || typeof model.id !== 'string' || model.id === '') {
            throw new Error('You can only update an existing User');
        }
        return this.apollo.mutate({
            mutation: mutation,
            variables: { user: model },
            refetchQueries: [{
                    query: listQuery,
                }]
        }).pipe(map(function (t) { return t.data.user; }));
    };
    UserService.prototype.delete = function (model) {
        if (!model.id || typeof model.id !== 'string' || model.id === '') {
            throw new Error('You can only update an existing User');
        }
        return this.apollo.mutate({
            mutation: deleteMutation,
            variables: { user: model },
            refetchQueries: [{
                    query: listQuery,
                }]
        });
    };
    UserService.prototype.changePassword = function (id, password) {
        if (!id || typeof id !== 'string') {
            throw new Error('You can only change password for an existing User');
        }
        return this.update({ id: id, password: password });
    };
    UserService.prototype.addSitePermission = function (userId, siteId, permission) {
        if (permission === void 0) { permission = 'READ'; }
        if (!userId || typeof userId !== 'string' || userId === '') {
            throw new Error('You can only add permissions on an existing User');
        }
        if (!siteId || typeof siteId !== 'string' || siteId === '') {
            throw new Error('You can only add permissions on an existing Site');
        }
        return this.apollo.mutate({
            mutation: gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n        mutation AddSitePermissionMutation($input: isitePermission!) {\n          userSite(userSite: $input) {\n            id\n          }\n        }\n      "], ["\n        mutation AddSitePermissionMutation($input: isitePermission!) {\n          userSite(userSite: $input) {\n            id\n          }\n        }\n      "]))),
            variables: { input: {
                    permission: permission,
                    site: { id: siteId },
                    user: { id: userId }
                } }
        });
    };
    UserService.prototype.addCustomerPermission = function (userId, customerId, permission) {
        if (permission === void 0) { permission = 'READ'; }
        if (!userId || typeof userId !== 'string' || userId === '') {
            throw new Error('You can only add permissions on an existing User');
        }
        if (!customerId || typeof customerId !== 'string' || customerId === '') {
            throw new Error('You can only add permissions on an existing Customer');
        }
        return this.apollo.mutate({
            mutation: gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n        mutation AddCustomerPermissionMutation($input: icustomerPermission!) {\n          userCustomer(userCustomer: $input) {\n            id\n          }\n        }\n      "], ["\n        mutation AddCustomerPermissionMutation($input: icustomerPermission!) {\n          userCustomer(userCustomer: $input) {\n            id\n          }\n        }\n      "]))),
            variables: { input: {
                    permission: permission,
                    customer: { id: customerId },
                    user: { id: userId }
                } }
        });
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.Apollo)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
