import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SessionService } from './session.service';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

  constructor(private router: Router, private session: SessionService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authReq = req.clone({
      withCredentials: true
    });

    return next.handle(authReq).pipe(
      tap(() => {}, event => {
        if (event instanceof HttpErrorResponse) {
          if (event.status === 401) {
            this.session.resetSession();
            this.router.navigate(['/login']);
          } else if (event.status === 403 && event.error !== 'Login failed.') {
            this.router.navigate(['/error', { type: 'forbidden' }], { skipLocationChange: true });
          }
        }
      })
    );
  }

}
