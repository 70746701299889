import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ReportService } from './report.service';
import { Report } from '../../models/report';

@Injectable()
export class ReportListResolver implements Resolve<Observable<Report[]>> {

  constructor(private service: ReportService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.service.list(route.paramMap.get('siteId'));
  }

}
