var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
var findByNameQuery = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nquery listControlAgents($name: String) {\n  agents(displayName: $name) {\n    id\n    agent\n    displayName\n    maxConcentration\n    unit\n    isSpray\n  }\n}\n"], ["\nquery listControlAgents($name: String) {\n  agents(displayName: $name) {\n    id\n    agent\n    displayName\n    maxConcentration\n    unit\n    isSpray\n  }\n}\n"])));
var findByIdQuery = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\nquery FindControlAgentById($id: ID) {\n  agent(id: $id) {\n    id\n    agent\n    displayName\n    maxConcentration\n    unit\n    isSpray\n  }\n}\n"], ["\nquery FindControlAgentById($id: ID) {\n  agent(id: $id) {\n    id\n    agent\n    displayName\n    maxConcentration\n    unit\n    isSpray\n  }\n}\n"])));
var mutationQuery = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\nmutation agentMutation($controlAgent: iagent!) {\n  agent(agent: $controlAgent) {\n    id\n    agent\n    displayName\n    maxConcentration\n    unit\n    isSpray\n  }\n}\n"], ["\nmutation agentMutation($controlAgent: iagent!) {\n  agent(agent: $controlAgent) {\n    id\n    agent\n    displayName\n    maxConcentration\n    unit\n    isSpray\n  }\n}\n"])));
var ControlAgentService = /** @class */ (function () {
    function ControlAgentService(apollo) {
        this.apollo = apollo;
    }
    ControlAgentService.prototype.list = function () {
        return this.apollo.query({ query: findByNameQuery }).pipe(map(function (result) { return result.data.agents; }));
    };
    ControlAgentService.prototype.find = function (name) {
        return this.apollo.query({ query: findByNameQuery, variables: { name: name } }).pipe(map(function (result) { return result.data.agents; }));
    };
    ControlAgentService.prototype.get = function (id) {
        return this.apollo.query({ query: findByIdQuery, variables: { id: id } }).pipe(map(function (t) { return t.data.agent; }));
    };
    ControlAgentService.prototype.create = function (model) {
        console.log(model);
        if (model.id !== undefined && model.id !== null && model.id !== '') {
            throw new Error('You can only create a new Control Agent');
        }
        return this.apollo.mutate({ mutation: mutationQuery, variables: { controlAgent: model } }).pipe(map(function (t) { return t.data.agent; }));
    };
    ControlAgentService.prototype.update = function (model) {
        if (!model.id || typeof model.id !== 'string' || model.id === '') {
            throw new Error('You can only update an existing Control Agent');
        }
        return this.apollo.mutate({ mutation: mutationQuery, variables: { controlAgent: model } }).pipe(map(function (t) { return t.data.agent; }));
    };
    return ControlAgentService;
}());
export { ControlAgentService };
var templateObject_1, templateObject_2, templateObject_3;
