<div class="flex-container column">
  <div class="control-bar" *ngIf="session?.profile?.software_role === 'ADMIN'">
    <select class="agent-cell" [value]="activeCustomer" (change)="onCustomerSelected($event)">
      <option *ngFor="let customer of customers" [value]="customer.id">{{ customer.name }}</option>
    </select>
    <span class="fill"></span>
    <button mat-raised-button color="primary" (click)="onAdd()">
      <mat-icon>add</mat-icon>
      Lisää kohde
    </button>
  </div>
  <app-list [contentPadding]="0">
    <app-list-item *ngFor="let site of activeSites" routerLink="/site/{{ site.id }}">
      {{ site.name }}<br>
      {{ site.address }}<br>
      {{ site.zipCode}}
    </app-list-item>
    <app-list-item *ngIf="activeSites.length === 0">
      Asiakkaalla ei ole vielä torjuntakohteita
    </app-list-item>
  </app-list>
</div>
