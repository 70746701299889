import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export interface LoginResult {
  succeeded: boolean;
  message?: string;
}

export interface UserProfile {
  id: string;
  name: string;
  software_role: string;
  username: string;
}

export interface SessionState {
  isAuthenticated: boolean;
  profile?: any;
}

const APP_SESSION_STATE = 'DESIRA_SESSION_STATE';
const defaultState: SessionState = {
  isAuthenticated: false
};

@Injectable()
export class SessionService {

  private get session(): SessionState {
    try {
      const state = JSON.parse(localStorage.getItem(APP_SESSION_STATE));
      return state === null ? defaultState : state;
    } catch (err) {
      return defaultState;
    }
  }

  private set session(state: SessionState) {
    localStorage.setItem(APP_SESSION_STATE, JSON.stringify(state));
  }

  get isAuthenticated(): boolean {
    return this.session.isAuthenticated;
  }

  get isAdmin(): boolean {
    return this.isAuthenticated && this.profile.software_role === 'ADMIN';
  }

  get profile(): UserProfile {
    return this.session.profile;
  }

  constructor(private router: Router, private http: HttpClient) {
  }

  async login(credentials: { username: string, password: string }): Promise<LoginResult> {
    let response;
    try {
      response = await this.http.post(environment.apiRoot + '/login', credentials, {
        observe: 'response',
        withCredentials: true
      }).toPromise();
    } catch (e) {
      console.error('Failed to login', response);
      return {
        succeeded: false
      };
    }

    if (response.status !== 200) {
      console.error('Failed to login', response);
      return {
        succeeded: false
      };
    }
    this.session = {
      isAuthenticated: true,
      profile: response.body
    };
    console.log(this.session);
    return {
      succeeded: true
    };
  }

  resetSession() {
    this.session = defaultState;
  }

  async logout() {
    this.resetSession();
    // TODO: Logout via backend
    // Clear cache & JS environment
    window.location.assign('/login');
  }

}
