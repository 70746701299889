import 'rxjs/add/operator/toPromise';

import { Pipe, PipeTransform } from '@angular/core';
import { ControlAgentService } from '../services/control-agent/control-agent.service';

@Pipe({
  name: 'controlAgent'
})
export class ControlAgentPipe implements PipeTransform {

  constructor(private service: ControlAgentService) {}

  async transform(value: string, format?: string, agentId?: string): Promise<string> {
    if (format === undefined || format === null) {
      throw new Error('You must provide ControlAgentPipeOutputFormat to use this pipe');
    }

    if (value == '0') {
      return '-';
    }

    if (format === 'name') {
      const result = await this.service.get(value).toPromise();
      return result.displayName;
    } else if (format === 'amount') {
      if (agentId === undefined) {
        throw new Error('You must provide the agent id as the second argument to format the agent amount properly');
      }
      const agent = await this.service.get(agentId).toPromise();
      return value + ' ' + agent.unit;
    } else {
      throw new Error('You must provide a valid ControlAgentPipeOutputFormat to use this pipe');
    }
  }

}
