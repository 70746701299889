import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Site, TrapSummary } from '../../models/site';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import gql from 'graphql-tag';
import { ControlMap } from '../../models/map';
import { parse } from 'date-fns';
import { listSitesForUser } from './__generated__/listSitesForUser';
import { getSiteById, getSiteByIdVariables } from './__generated__/getSiteById';
import { listSitesForCustomer, listSitesForCustomerVariables } from './__generated__/listSitesForCustomer';

export function getStoredObject(key: string) {
  try {
    const serializedItem = localStorage.getItem(key);
    return JSON.parse(serializedItem);
  } catch (err) {
    console.error(err);
    return null;
  }
}

export function setStoredObject(key: string, value: any) {
  try {
    const serializedValue = JSON.stringify(key, value);
    localStorage.setItem(key, serializedValue);
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}

const listQuery = gql`
query listSitesForUser {
  sites {
    id
    customer {
      id
      name
    }
    name
    address
    zipCode
  }
}
`;

const listByCustomerQuery = gql`
query listSitesForCustomer($id: ID) {
  customer(id: $id) {
    id
    sites {
      id
      customer {
        id
      }
      name
      address
      zipCode
    }
  }
}
`;

const getByIdQuery = gql`
query getSiteById($id: ID) {
  site(id: $id) {
    id
    customer {
      id
      name
    }
    name
    address
    zipCode
    map {
      id
      name
      mapImage {
        id
        mimeType
        data
      }
      traps
    }
    reports {
      id
    }
    documents {
      id
      fileName
      description
      mimeType
      type
    }
  }
}
`;

const statisticsQuery = gql`
query getSiteStatistics($id: ID, $from: date, $to: date) {
  site(id: $id) {
    id
    trapSummaries(from: $from, to: $to) {
      date
      baitCaught
      pheromoneCaught
      lightCaught
    }
  }
}
`;

const mutation = gql`
mutation siteMutation($site: isite!) {
  site(site: $site) {
    id
    customer {
      id
      name
    }
    name
    address
    zipCode
    map {
      id
      name
      mapImage {
        id
        mimeType
        data
      }
      traps
    }
    reports {
      id
    }
    documents {
      id
      fileName
      description
      mimeType
      type
    }
  }
}
`;

const mapMutation = gql`
mutation mapMutation($map: imap!) {
  map(map: $map) {
    id
    mapImage {
      id
    }
    name
    revision
    site {
      id
    }
    traps
  }
}
`;

const mapImageMutation = gql`
mutation mapImageMutation($mapImage: imapImage!) {
  mapImage(mapImage: $mapImage) {
    created
    data
    id
    map {
      id
    }
    mimeType
  }
}
`;

const reParts = /\d+|\D+/g;
const reDigit = /\d/;
function sortStringsWithNumbers(a, b) {
  // Get rid of casing issues.
  a = a.toUpperCase();
  b = b.toUpperCase();

  // Separates the strings into substrings that have only digits and those
  // that have no digits.
  const aParts = a.match(reParts);
  const bParts = b.match(reParts);

  // Used to determine if aPart and bPart are digits.
  let isDigitPart;

  // If `a` and `b` are strings with substring parts that match...
  if (aParts && bParts &&
    (isDigitPart = reDigit.test(aParts[0])) === reDigit.test(bParts[0])) {
    // Loop through each substring part to compare the overall strings.
    const len = Math.min(aParts.length, bParts.length);
    for (let i = 0; i < len; i++) {
      let aPart = aParts[i];
      let bPart = bParts[i];

      // If comparing digits, convert them to numbers (assuming base 10).
      if (isDigitPart) {
        aPart = parseInt(aPart, 10);
        bPart = parseInt(bPart, 10);
      }

      // If the substrings aren't equal, return either -1 or 1.
      if (aPart !== bPart) {
        return aPart < bPart ? -1 : 1;
      }

      // Toggle the value of isDigitPart since the parts will alternate.
      isDigitPart = !isDigitPart;
    }
  }

  // Use normal comparison.
  return <any>(a >= b) - <any>(a <= b);
}

function formatSite(site) {
  if (site.map) {
    return {
      ...site,
      map: {
        ...site.map,
        traps: site.map.traps
          .map(t => JSON.parse(t))
          .sort((a, b) => sortStringsWithNumbers(a.id, b.id))
      }
    };
  }
  return site;
}

@Injectable()
export class SiteService {

  constructor(private apollo: Apollo) {
  }

  list(): Observable<Site[]> {
    return this.apollo.query<listSitesForUser>({ query: listQuery }).pipe(
      map(result => <Site[]>(result.data.sites.map(formatSite)))
    );
  }

  listForCustomer(id: string): Observable<Site[]> {
    return this.apollo.query<listSitesForCustomer, listSitesForCustomerVariables>(
      { query: listByCustomerQuery, variables: { id } }
    ).pipe(
      map(result => <Site[]>(result.data.customer.sites.map(formatSite)))
    );
  }

  get(id: string, force: boolean = false): Observable<Site> {
    return this.apollo.query<getSiteById, getSiteByIdVariables>(
      { query: getByIdQuery, variables: { id }, fetchPolicy: force ? 'network-only' : null}
    ).pipe(
      map(t => <Site>formatSite(t.data.site))
    );
  }

  create(model: Site): Observable<Site> {
    if (model.id !== undefined && model.id !== null && model.id !== '') {
      throw new Error('You can only create a new Site');
    }

    return this.apollo.mutate({ mutation, variables: { site: model } }).pipe(
      map(t => <Site>(<any>t.data).site)
    );
  }

  update(model: Site): Observable<Site> {
    if (!model.id || typeof model.id !== 'string' || model.id === '') {
      throw new Error('You can only update an existing Site');
    }

    return this.apollo.mutate({ mutation, variables: { site: model } }).pipe(
      map(t => <Site>(<any>t.data).site)
    );
  }

  getStatistics(id: string, from: Date, to: Date) {
    return this.apollo.query({
      query: statisticsQuery, variables: {
        id,
        from: from.toISOString().split('T')[0],
        to: to.toISOString().split('T')[0]
      }
    }).pipe(
      map(t => <TrapSummary[]>(<any>t.data).site.trapSummaries.map(entry => {
        return {
          ...entry,
          date: parse(entry.date)
        };
      }))
    );
  }

  checkForUnopenedReports(site: Site): number {
    const storedState = getStoredObject('SITE_REPORT_STATES');
    const siteReportState = storedState[site.id];
    let unread = 0;

    for (let i = 0; i < site.reports.length; i++) {
      if (!siteReportState.includes(site.reports[i])) {
        unread++;
      }
    }

    return unread;
  }

  markReportAsRead(site: Site, reportId: number) {
    const storedState = getStoredObject('SITE_REPORT_STATES');
    const siteReportState = storedState[site.id];
    if (!siteReportState.includes(reportId)) {
      storedState[site.id].push(reportId);
    }
    setStoredObject('SITE_REPORT_STATES', storedState);
  }

  async updateMap(site: Site, mapData: ControlMap) {
    if (!site.id || typeof site.id !== 'string' || site.id === '') {
      throw new Error('You need to provide a valid site for a map update');
    }

    let mapImage;
    if (!(site.map && site.map.mapImage && mapData && mapData.mapImage && site.map.mapImage.data === mapData.mapImage.data)) {
      mapImage = await this.apollo.mutate({ mutation: mapImageMutation, variables: { mapImage: mapData.mapImage } }).pipe(
        map(t => <any>(<any>t.data).mapImage)
      ).toPromise();
    } else {
      mapImage = site.map.mapImage;
    }

    const mapInstance = await this.apollo.mutate({
      mutation: mapMutation, variables: {
        map: {
          ...mapData,
          mapImage: {
            id: mapImage.id
          },
          traps: mapData.traps
        }
      }
    }).pipe(
      map(t => <ControlMap>(<any>t.data).map)
    ).toPromise();

    return this.apollo.mutate({
      mutation, variables: {
        site: {
          id: site.id,
          map: {
            id: mapInstance.id
          }
        }
      }
    }).pipe(
      map(t => <Site>(<any>t.data).site)
    ).toPromise();
  }
}
