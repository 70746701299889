import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SiteService } from './site.service';

@Injectable({
  providedIn: 'root'
})
export class SingleSiteGuard implements CanActivate {

  constructor(private router: Router, private service: SiteService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.service.list().map(sites => {
      if (sites.length === 1) {
        this.router.navigate(['/site', sites[0].id]);
        return false;
      }

      return true;
    });
  }

}
