import { Router } from '@angular/router';
import { SiteService } from './site.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./site.service";
var SingleSiteGuard = /** @class */ (function () {
    function SingleSiteGuard(router, service) {
        this.router = router;
        this.service = service;
    }
    SingleSiteGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        return this.service.list().map(function (sites) {
            if (sites.length === 1) {
                _this.router.navigate(['/site', sites[0].id]);
                return false;
            }
            return true;
        });
    };
    SingleSiteGuard.ngInjectableDef = i0.defineInjectable({ factory: function SingleSiteGuard_Factory() { return new SingleSiteGuard(i0.inject(i1.Router), i0.inject(i2.SiteService)); }, token: SingleSiteGuard, providedIn: "root" });
    return SingleSiteGuard;
}());
export { SingleSiteGuard };
