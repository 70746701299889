var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.browser.print/dist/leaflet.browser.print.min.js';
import 'Leaflet.extra-markers';
import { MatDialog } from '@angular/material';
import { TrapType } from '../../models/trap';
function trapTypeToColor(trapType) {
    switch (trapType) {
        case TrapType.baitIndoor:
            return 'blue';
        case TrapType.baitOutdoor:
            return 'orange-dark';
        case TrapType.pheromone:
            return 'green';
        case TrapType.light:
            return 'orange';
        default:
            return 'white';
    }
}
var MapComponent = /** @class */ (function () {
    function MapComponent(dialog, changeDetectorRef) {
        this.dialog = dialog;
        this.changeDetectorRef = changeDetectorRef;
        this.mapDataChange = new EventEmitter();
        this.contextMenu = new EventEmitter();
        this.trapClicked = new EventEmitter();
        this.trapUpdated = new EventEmitter();
        this.mapOptions = {
            attributionControl: false,
            crs: L.CRS.Simple,
            center: L.latLng([500, 500]),
            zoom: -1,
            minZoom: -3,
            zoomSnap: 0,
        };
        this.mapCenter = L.latLng([500, 500]);
        this.mapLayers = [];
        this.markers = [];
    }
    Object.defineProperty(MapComponent.prototype, "hasMap", {
        get: function () {
            return !!this.mapData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MapComponent.prototype, "hasMapImage", {
        get: function () {
            return this.hasMap &&
                this.mapData.mapImage &&
                this.mapData.mapImage.mimeType &&
                this.mapData.mapImage.data &&
                !!this.mapData.mapImage.data.length;
        },
        enumerable: true,
        configurable: true
    });
    MapComponent.prototype.addMarker = function (trap) {
        var _this = this;
        var marker = new L.Marker({ lng: trap.location.x, lat: trap.location.y }, {
            title: trap.id,
            draggable: this.editable,
            riseOnHover: this.editable,
            icon: L.ExtraMarkers.icon({
                markerColor: trapTypeToColor(trap.type),
                icon: 'fa-number',
                number: /\d+/.exec(trap.id)[0],
            })
        });
        marker.bindTooltip(trap.id + (trap.comment ? "<br>" + trap.comment : ''));
        marker.addTo(this.map);
        marker.addEventListener('click', function (event) {
            _this.trapClicked.emit({
                location: {
                    x: event.originalEvent.clientX,
                    y: event.originalEvent.clientY
                },
                trap: trap
            });
        });
        marker.addEventListener('dragend', function (event) {
            trap = __assign({}, trap, { location: {
                    x: event.target._latlng.lng,
                    y: event.target._latlng.lat,
                } });
            _this.trapUpdated.emit(trap);
        });
        this.markers.push(marker);
    };
    MapComponent.prototype.removeMarker = function (trap) {
        var index = this.markers.findIndex(function (t) { return t.options.title === trap.id; });
        if (index > -1) {
            this.markers[index].remove();
            this.markers.splice(index, 1);
        }
    };
    MapComponent.prototype.ngOnInit = function () {
        this.updateMap();
    };
    MapComponent.prototype.ngOnChanges = function (changes) {
        this.updateMap();
    };
    MapComponent.prototype.updateMap = function () {
        return __awaiter(this, void 0, void 0, function () {
            var image, bounds, _i, _a, trap;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(this.hasMapImage && this.mapData.mapImage.mimeType.startsWith('image/'))) return [3 /*break*/, 2];
                        image = "data:" + this.mapData.mapImage.mimeType + ";base64," + this.mapData.mapImage.data;
                        return [4 /*yield*/, this.calcBounds(image)];
                    case 1:
                        bounds = _b.sent();
                        this.mapCenter = [bounds[1][0] / 2, bounds[1][1] / 2];
                        this.mapLayers[0] = L.imageOverlay(image, bounds);
                        this.map.fitWorld({
                            maxZoom: -3,
                            animate: false,
                            duration: 0
                        });
                        if (this.mapData && this.mapData.traps) {
                            this.markers.forEach(function (marker) { return marker.remove(); });
                            this.markers = [];
                            for (_i = 0, _a = this.mapData.traps; _i < _a.length; _i++) {
                                trap = _a[_i];
                                this.addMarker(trap);
                            }
                        }
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    MapComponent.prototype.ngAfterViewInit = function () {
        if (this.newMapBannerEl) {
            this.bindDragAndDrop(this.newMapBannerEl.nativeElement);
        }
        if (this.hasMapImage) {
            L.control.browserPrint({
                printModesNames: {
                    Portrait: 'Pysty',
                    Landscape: 'Vaaka',
                    Auto: 'Automaattinen',
                    Custom: 'Valitse alue'
                }
            }).addTo(this.map);
        }
    };
    MapComponent.prototype.calcBounds = function (data) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.imageData = new Image();
            _this.imageData.onload = function () {
                var width = _this.imageData.width;
                var height = _this.imageData.height;
                var ratio = width / height;
                var bounds = [(width / ratio), width];
                resolve([[0, 0], bounds]);
            };
            _this.imageData.onerror = reject;
            _this.imageData.src = data;
        });
    };
    MapComponent.prototype.bindDragAndDrop = function (element) {
        var _this = this;
        element.addEventListener('dragenter', function (event) {
            _this.isDragging = true;
        });
        element.addEventListener('dragleave', function (event) {
            _this.isDragging = false;
        });
        element.addEventListener('dragover', function (event) {
            event.preventDefault();
        });
        element.addEventListener('drop', function (event) {
            event.preventDefault();
            if (event.dataTransfer.files && event.dataTransfer.files.length === 1) {
                var file = event.dataTransfer.files[0];
                var fileReader = new FileReader();
                fileReader.addEventListener('load', function (loadEvent) { return __awaiter(_this, void 0, void 0, function () {
                    var dataParts;
                    return __generator(this, function (_a) {
                        dataParts = loadEvent.target.result.split(';');
                        this.mapData = __assign({}, this.mapData, { mapImage: __assign({}, this.mapData.mapImage, { mimeType: dataParts[0].split(':')[1], data: dataParts[1].split(',')[1] }) });
                        this.mapDataChange.emit(this.mapData);
                        return [2 /*return*/];
                    });
                }); });
                fileReader.readAsDataURL(file);
            }
            else {
                alert('Kartan lisääminen epäonnistui. Lisää tasan yksi tiedosto.');
            }
            if (event.dataTransfer.items) {
                event.dataTransfer.items.clear();
            }
            else {
                event.dataTransfer.clearData();
            }
            _this.isDragging = false;
        });
    };
    MapComponent.prototype.onMapReady = function (map) {
        var _this = this;
        this.map = map;
        if (this.editable) {
            this.bindDragAndDrop(this.map.getContainer());
            this.map.on('contextmenu', function (event) {
                _this.contextMenu.emit(event);
                event.originalEvent.preventDefault();
                return false;
            });
        }
    };
    return MapComponent;
}());
export { MapComponent };
