/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./list-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./list-item.component";
var styles_ListItemComponent = [i0.styles];
var RenderType_ListItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ListItemComponent, data: {} });
export { RenderType_ListItemComponent as RenderType_ListItemComponent };
export function View_ListItemComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0)], null, null); }
export function View_ListItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-list-item", [], null, null, null, View_ListItemComponent_0, RenderType_ListItemComponent)), i1.ɵdid(1, 114688, null, 0, i2.ListItemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ListItemComponentNgFactory = i1.ɵccf("app-list-item", i2.ListItemComponent, View_ListItemComponent_Host_0, {}, {}, ["*"]);
export { ListItemComponentNgFactory as ListItemComponentNgFactory };
