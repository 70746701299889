import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { SiteService } from './site.service';
import { Site } from '../../models/site';

@Injectable()
export class SiteResolver implements Resolve<Observable<Site>> {

  constructor(private service: SiteService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.service.get(route.paramMap.get('siteId'));
  }

}
