var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Site } from '../../models/site';
import { SiteService } from '../../services/site/site.service';
import { CustomerService } from '../../services/customer/customer.service';
import { ReportService } from '../../services/report/report.service';
import { MapEditorComponent } from '../../dialogs/map-editor/map-editor.component';
import { MatDialog } from '@angular/material';
import { SessionService } from '../../services/session/session.service';
import { subMonths } from 'date-fns';
import { environment } from '../../../environments/environment';
import { SiteFormDialogComponent } from '../../dialogs/site-form-dialog/site-form-dialog.component';
import { FileService } from '../../services/file/file.service';
import { DocumentType } from '../../models/document';
function labelize(date) {
    return date.toLocaleDateString('fi-FI');
}
function createDataSet(label, values, color) {
    return {
        label: label,
        backgroundColor: color,
        data: values
    };
}
var SiteComponent = /** @class */ (function () {
    function SiteComponent(route, service, customerService, reportService, dialog, session, fileService, router) {
        this.route = route;
        this.service = service;
        this.customerService = customerService;
        this.reportService = reportService;
        this.dialog = dialog;
        this.session = session;
        this.fileService = fileService;
        this.router = router;
        this.customer = {};
        this.site = {};
        this.reports = [];
        this.reportColumns = ['date', 'reason', 'reporter', 'actions'];
        this.agentSafetyInstructions = [];
        this.otherDocuments = [];
        this.fileColumns = ['fileName'];
        this.incompleteReports = [];
        this.baitChart = {};
        this.otherTrapChart = {};
    }
    SiteComponent.prototype.ngOnInit = function () {
        this.customer = this.route.snapshot.data.customer;
        this.setSite(this.route.snapshot.data.site);
        this.setReports(this.route.snapshot.data.reports);
    };
    SiteComponent.prototype.setSite = function (site) {
        this.site = site;
        this.agentSafetyInstructions = this.site.documents.filter(function (t) { return t.type === DocumentType.SafetyInstruction; });
        this.otherDocuments = this.site.documents.filter(function (t) { return t.type !== DocumentType.SafetyInstruction; });
    };
    SiteComponent.prototype.setReports = function (reports) {
        var _this = this;
        this.reports = reports.sort(function (a, b) { return Date.parse(b.data.date) - Date.parse(a.data.date); });
        var statFromDate = subMonths(new Date(), 24);
        var statToDate = new Date();
        this.service.getStatistics(this.site.id, statFromDate, statToDate).subscribe(function (entries) {
            entries.sort(function (a, b) { return a.date.valueOf() - b.date.valueOf(); });
            var labels = [];
            var baitSeries = [];
            var pheromoneSeries = [];
            var lightSeries = [];
            for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
                var entry = entries_1[_i];
                labels.push(labelize(entry.date));
                baitSeries.push(entry.baitCaught);
                pheromoneSeries.push(entry.pheromoneCaught);
                lightSeries.push(entry.lightCaught);
            }
            if (baitSeries.length) {
                _this.baitChart.labels = labels;
                _this.baitChart.datasets = [
                    createDataSet('Syöttiansat', baitSeries, 'blue')
                ];
            }
            if (pheromoneSeries.length || lightSeries.length) {
                _this.otherTrapChart.labels = labels;
                _this.otherTrapChart.datasets = [
                    createDataSet('Feromoniansat', pheromoneSeries, 'green'),
                    createDataSet('Valoansat', lightSeries, 'orange')
                ];
            }
        });
    };
    SiteComponent.prototype.onEditSite = function () {
        var _this = this;
        var dialogRef = this.dialog.open(SiteFormDialogComponent, {
            data: this.site,
            position: {
                top: '74px'
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result.succeeded) {
                _this.setSite(result.site);
            }
        });
    };
    SiteComponent.prototype.onNewMap = function () {
        var dialogRef = this.dialog.open(MapEditorComponent, {
            width: '700px',
            minHeight: '300px',
            data: {
                site: this.site
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log('editor closed', result);
        });
    };
    SiteComponent.prototype.onMapEdit = function () {
        var _this = this;
        var dialogRef = this.dialog.open(MapEditorComponent, {
            width: '700px',
            minHeight: '300px',
            data: {
                mapData: this.site.map,
                site: this.site
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (!result) {
                return;
            }
            _this.site = __assign({}, _this.site, { map: result });
            _this.mapComponent.updateMap();
        });
    };
    SiteComponent.prototype.onFileClick = function (file) {
        window.open(environment.apiRoot + "/files/" + file.id, '_blank');
    };
    SiteComponent.prototype.onReportDelete = function (event, report) {
        var _this = this;
        event.stopPropagation();
        if (confirm('Haluatko varmasti poistaa tämän raportin?')) {
            this.reportService.delete(report, this.site.id).subscribe(function () {
                _this.reportService.list(_this.site.id).subscribe(function (reports) { return _this.setReports(reports); });
            });
        }
    };
    SiteComponent.prototype.onReportCopy = function (event, report) {
        event.stopPropagation();
        var id = report.id, newReport = __rest(report, ["id"]);
        this.reportService.activeReport = newReport;
        this.router.navigate(['site', this.site.id, 'reports', 'new']);
    };
    SiteComponent.prototype.onPrintReport = function (event, report) {
        event.stopPropagation();
        this.router.navigate(['site', this.site.id, 'reports', report.id]).then(function (succeeded) {
            return succeeded && window.setTimeout(window.print, 100);
        });
    };
    SiteComponent.prototype.onDeleteFile = function (event, file) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        event.stopPropagation();
                        if (!confirm('Haluatko varmasti poistaa tämän dokumentin?')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fileService.delete(file.id)];
                    case 1:
                        _a.sent();
                        this.service.get(this.site.id, true).subscribe(function (site) { return _this.setSite(site); });
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    SiteComponent.prototype.onFilesAdded = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var file;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        file = event.srcElement.files[0];
                        if (!file) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fileService.upload({
                                description: prompt('Tiedoston kuvaus', ''),
                                type: DocumentType.SiteSpecificDocument,
                                relationId: this.site.id
                            }, file).subscribe(function (result) {
                                console.log(result);
                                _this.service.get(_this.site.id, true).subscribe(function (site) { return _this.setSite(site); });
                            }, function (err) {
                                console.error(err);
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    return SiteComponent;
}());
export { SiteComponent };
