import { AfterContentInit } from '@angular/core';
import { Directive } from '@angular/core';
import { ElementRef } from '@angular/core';

@Directive({
  selector: '[autofocus], [appAutofocus]'
})
export class AutofocusDirective implements AfterContentInit {

  private elementRef: ElementRef;

  constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;

  }

  public ngAfterContentInit(): void {
    this.elementRef.nativeElement.focus();
  }

}
