import { Component, ErrorHandler } from '@angular/core';
import { SessionService } from './services/session/session.service';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular-link-http';
import { environment } from '../environments/environment';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { concat } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { SiteService } from './services/site/site.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NewPasswordDialogComponent } from './dialogs/new-password-dialog/new-password-dialog.component';
import { MatDialog } from '../../node_modules/@angular/material';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  singleSite = false;
  frontPageUri = '/';

  constructor(public session: SessionService, private apollo: Apollo, private httpLink: HttpLink, private siteService: SiteService,
              private route: ActivatedRoute, private router: Router, private dialog: MatDialog, private errorHandler: ErrorHandler) {
    apollo.create({
      link: concat(
        onError(({ graphQLErrors, networkError, operation }) => {
          if (graphQLErrors) {
            graphQLErrors.map(({ message, locations, path }) =>
               this.errorHandler.handleError(new Error(
                 `[GraphQL error]: Operation ${
                   operation.operationName
                  }, Message: ${
                    message
                  }, Location: ${
                    JSON.stringify(locations)
                  }, Path: ${
                    JSON.stringify(path)
                  }`
                )));
          }

          if (networkError) {
            this.errorHandler.handleError(new Error(`[Network error]: Operation ${operation.operationName}, ${networkError.message}`));
          }
        }),
        httpLink.create({
          uri: environment.apiRoot + environment.graphqlUri,
          withCredentials: true
        })
      ),
      cache: new InMemoryCache({
        dataIdFromObject: (object: any) => object.id || null,
        addTypename: false
      })
    });

    if (session.isAuthenticated) {
      this.siteService.list().subscribe(sites => {
        if (sites.length === 1 && !session.isAdmin) {
          this.singleSite = true;
          this.frontPageUri = '/site/' + sites[0].id;
          this.router.navigate(['/site', sites[0].id]);
        }
      });
    }
  }

  onChangePassword() {
    this.dialog.open(NewPasswordDialogComponent, {
      data: this.session.profile.id
    });
  }

}
