import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { Trap, TrapType } from '../../../models/trap';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ControlAgent } from '../../../models/control-agent';
import { Site } from '../../../models/site';
import { Report } from '../../../models/report';
import { ControlMap } from '../../../models/map';

@Component({
  selector: 'app-report-edit',
  templateUrl: './report-edit.component.html',
  styleUrls: ['./report-edit.component.scss']
})
export class ReportEditComponent implements OnInit {

  @ViewChild('autofocus') autofocus;

  @Input() site: Site;
  @Input() controlAgents: ControlAgent[];
  @Input() report: Report;
  @Output() reportChange = new EventEmitter<{ type: string, report?: Report }>();
  @ViewChildren('sprayTrapRow') sprayTrapRows;
  @ViewChildren('pheromoneTrapRow') pheromoneTrapRows;
  @ViewChildren('lightTrapRow') lightTrapRows;

  get baitAgents(): ControlAgent[] {
    return this.controlAgents.filter(t => !t.isSpray);
  }

  get sprayAgents(): ControlAgent[] {
    return this.controlAgents.filter(t => t.isSpray);
  }

  reportForm: FormGroup;
  newBaitTrapReportForm: FormGroup;
  newPheromoneTrapReport: FormGroup;
  newLightTrapReport: FormGroup;
  newSprayTrapReport: FormGroup;

  get mapSnapshot(): FormGroup {
    return this.reportForm.get('mapSnapshot') as FormGroup;
  }

  get baitTrapReports(): FormArray {
    return this.reportForm.get('baitTrapReports') as FormArray;
  }

  get pheromoneTrapReports(): FormArray {
    return this.reportForm.get('pheromoneTrapReports') as FormArray;
  }

  get lightTrapReports(): FormArray {
    return this.reportForm.get('lightTrapReports') as FormArray;
  }

  get sprayTrapReports(): FormArray {
    return this.reportForm.get('sprayTrapReports') as FormArray;
  }

  constructor(private formBuilder: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {
    this.initializeForm();
    this.initializeBaitTrapReportForm();
    this.initializePheromoneTrapReportForm();
    this.initializeLightTrapReportForm();
    this.initializeSprayTrapReportForm();
  }

  @ViewChildren('baitTrapRow') baitTrapRows;

  addBaitTrapRow(controlName, input, value) {
    switch (controlName) {
      case 'id':
        this.addBaitTrapControl(value);
        break;
      case 'caught':
        this.addBaitTrapControl(null, value);
        break;
      case 'pests':
        this.addBaitTrapControl(null, null, value);
        break;
      case 'agentId':
        this.addBaitTrapControl(null, null, null, value);
        break;
      case 'agentAmount':
        this.addBaitTrapControl(null, null, null, null, value);
        break;
      default:
        break;
    }

    input.value = null;
    this.changeDetectorRef.detectChanges();
    this.baitTrapRows.last.nativeElement.querySelectorAll('[formControlName=' + controlName + ']')[0].focus();

    return false;
  }

  addSprayTrapRow(controlName, input, value) {
    switch (controlName) {
      case 'zoneName':
        this.addSprayTrapControl(value);
        break;
      case 'agentId':
        this.addSprayTrapControl(null, value);
        break;
      case 'agentAmount':
        this.addSprayTrapControl(null, null, value);
        break;
      default:
        break;
    }

    input.value = null;
    this.changeDetectorRef.detectChanges();
    this.sprayTrapRows.last.nativeElement.querySelectorAll('[formControlName=' + controlName + ']')[0].focus();

    return false;
  }

  addPheromoneTrapRow(controlName, input, value) {
    switch (controlName) {
      case 'id':
        this.addPheromoneTrapControl(value);
        input.value = null;
        break;
      case 'caught':
        this.addPheromoneTrapControl(null, value);
        input.value = null;
        break;
      case 'changed':
        this.addPheromoneTrapControl(null, null, input.checked);
        input.checked = false;
        break;
      case 'observations':
        this.addPheromoneTrapControl(null, null, null, value);
        input.value = null;
        break;
      default:
        break;
    }

    this.changeDetectorRef.detectChanges();
    this.pheromoneTrapRows.last.nativeElement.querySelectorAll('[formControlName=' + controlName + ']')[0].focus();

    return false;
  }

  addLightTrapRow(controlName, input, value) {
    switch (controlName) {
      case 'id':
        this.addLightTrapControl(value);
        input.value = null;
        break;
      case 'caught':
        this.addLightTrapControl(null, value);
        input.value = null;
        break;
      case 'paperChanged':
        this.addLightTrapControl(null, null, input.checked);
        input.checked = false;
        break;
      case 'lightChanged':
        this.addLightTrapControl(null, null, null, input.checked);
        break;
      case 'observations':
        this.addLightTrapControl(null, null, null, null, value);
        input.value = null;
        break;
      default:
        break;
    }

    this.changeDetectorRef.detectChanges();
    this.lightTrapRows.last.nativeElement.querySelectorAll('[formControlName=' + controlName + ']')[0].focus();

    return false;
  }

  ngOnInit() {
    if (this.report.data) {
      this.populateMapTrapControls(this.report.data.mapSnapshot.traps.length);
      this.populateArrayControls(TrapType.baitIndoor, this.report.data.baitTrapReports);
      this.populateArrayControls(TrapType.pheromone, this.report.data.pheromoneTrapReports);
      this.populateArrayControls(TrapType.light, this.report.data.lightTrapReports);
      this.populateArrayControls(TrapType.spray, this.report.data.sprayTrapReports);
      this.reportForm.patchValue(this.report.data as { [key: string]: any });
    } else {
      if (this.site.map && this.site.map.traps) {
        const mappedTraps = this.site.map.traps;
        this.populateMapTrapControls(mappedTraps.length);
        this.reportForm.get('mapSnapshot').patchValue(this.site.map);
        mappedTraps.forEach((trap: Trap) => {
          let control: FormGroup;
          switch (trap.type) {
            case TrapType.baitIndoor:
            case TrapType.baitOutdoor:
              control = this.addBaitTrapControl(trap.id, null, null, trap.agentId);
              break;
            case TrapType.pheromone:
              control = this.addPheromoneTrapControl(trap.id);
              break;
            case TrapType.light:
              control = this.addLightTrapControl(trap.id);
              break;
            default:
              console.warn('Unknown trap type', trap.type);
              break;
          }

          if (control) {
            control.get('id').disable();
          }
        });
      }
    }
    this.autofocus.nativeElement.focus();
  }

  private populateArrayControls(type: TrapType, items: ReadonlyArray<any>) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      switch (type) {
        case TrapType.baitIndoor:
        case TrapType.baitOutdoor:
          this.addBaitTrapControl(item.id, item.caught, item.pests, item.agentId, item.agentAmount);
          break;
        case TrapType.pheromone:
          this.addPheromoneTrapControl(item.id, item.caught, item.changed, item.observations);
          break;
        case TrapType.light:
          this.addLightTrapControl(item.id, item.caught, item.paperChanged, item.lightChanged, item.observable);
          break;
        case TrapType.spray:
          this.addSprayTrapControl(item.zoneName, item.agentId, item.agentAmount);
          break;
        default:
          console.warn('Unknown trap type', type);
          break;
      }
    }
  }

  private populateMapTrapControls(trapCount: number) {
    const mapTraps = this.reportForm.get(['mapSnapshot', 'traps']) as FormArray;
    for (let i = 0; i < trapCount; i++) {
      mapTraps.push(this.formBuilder.group({
        id: '',
        type: '',
        agentId: null,
        location: '',
        data: ''
      }));
    }
  }

  private initializeForm() {
    this.reportForm = this.formBuilder.group({
      pestControlZone: ['', Validators.required],
      pestControlReason: ['', Validators.required],
      operator: ['', Validators.required],
      date: [new Date(), Validators.required],
      mapSnapshot: this.formBuilder.group({
        id: [0, Validators.required],
        revision: [0, Validators.required],
        siteId: [0, Validators.required],
        name: ['', Validators.required],
        traps: this.formBuilder.array([])
      }),
      baitTrapReports: this.formBuilder.array([]),
      sprayTrapReports: this.formBuilder.array([]),
      pheromoneTrapReports: this.formBuilder.array([]),
      lightTrapReports: this.formBuilder.array([]),
      pestControlReview: ['']
    });
  }

  private initializeBaitTrapReportForm() {
    this.newBaitTrapReportForm = this.formBuilder.group({
      caught: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]+')])],
      pests: ['', Validators.required],
      agentId: [null, Validators.required],
      agentAmount: ['', Validators.required]
    });
  }

  private initializePheromoneTrapReportForm() {
    this.newPheromoneTrapReport = this.formBuilder.group({
      id: [null, Validators.required],
      caught: [null, Validators.compose([Validators.required, Validators.pattern('[0-9]+')])],
      changed: [false, Validators.required],
      observations: ['']
    });
  }

  private initializeLightTrapReportForm() {
    this.newLightTrapReport = this.formBuilder.group({
      id: [null, Validators.required],
      caught: [null, Validators.compose([Validators.required, Validators.pattern('[0-9]+')])],
      paperChanged: [false, Validators.required],
      lightChanged: [false, Validators.required],
      observations: ['']
    });
  }

  private initializeSprayTrapReportForm() {
    this.newSprayTrapReport = this.formBuilder.group({
      zoneName: ['', Validators.required],
      agentId: [null, Validators.required],
      agentAmount: [null, Validators.required]
    });
  }

  private addBaitTrapControl(id?: string, caught?: string, pests?: string, agentId?: number, agentAmount?: number): FormGroup {
    const control = this.formBuilder.group({
      id: [id, Validators.required],
      trapType: [TrapType.baitIndoor, Validators.required],
      agentId: [agentId || null, Validators.required],
      agentAmount: [agentAmount || null, Validators.required],
      caught: [caught || '', Validators.compose([Validators.required, Validators.pattern('[0-9]+')])],
      pests: [pests || '', Validators.required]
    });
    this.baitTrapReports.push(control);
    return control;
  }

  private addPheromoneTrapControl(id?: string, caught?: number, changed?: boolean, observations?: string) {
    const control = this.formBuilder.group({
      id: [id || null, Validators.required],
      caught: [caught || null, Validators.compose([Validators.required, Validators.pattern('[0-9]+')])],
      changed: [changed || false, Validators.required],
      observations: [observations || '']
    });
    this.pheromoneTrapReports.push(control);
    return control;
  }

  private addLightTrapControl(id?: string, caught?: number, paperChanged?: boolean, lightChanged?: boolean, observations?: string) {
    const control = this.formBuilder.group({
      id: [id || null, Validators.required],
      caught: [caught || null, Validators.compose([Validators.required, Validators.pattern('[0-9]+')])],
      paperChanged: [paperChanged || false, Validators.required],
      lightChanged: [lightChanged || false, Validators.required],
      observations: [observations || '']
    });
    this.lightTrapReports.push(control);
    return control;
  }

  private addSprayTrapControl(zoneName?: string, agentId?: number, agentAmount?: number) {
    const control = this.formBuilder.group({
      zoneName: [zoneName || '', Validators.required],
      agentId: [agentId || null, Validators.required],
      agentAmount: [agentAmount || null, Validators.required]
    });
    this.sprayTrapReports.push(control);
    return control;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof FormArray) {
        for (const subControl of <FormGroup[]>control.controls) {
          this.validateAllFormFields(subControl);
        }
      }
    });
  }

  onCancel() {
    this.reportChange.emit({ type: 'cancelled' });
  }

  onSubmit() {
    this.validateAllFormFields(this.reportForm);
    if (this.reportForm.valid) {
      this.reportChange.emit({
        type: 'submitted',
        report: {
          id: this.report.id || null,
          site: {
            id: this.site.id
          },
          created: this.report.created || new Date().toISOString(),
          data: this.reportForm.getRawValue()
        }
      });
    }
  }

  removeRow(type: string, index: number) {
    if (confirm('Haluatko varmasti poistaa rivin?')) {
      switch (type) {
        case 'bait':
          this.baitTrapReports.removeAt(index);
          break;
        case 'spray':
          this.sprayTrapReports.removeAt(index);
          break;
        case 'light':
          this.lightTrapReports.removeAt(index);
          break;
        case 'pheromone':
          this.pheromoneTrapReports.removeAt(index);
          break;
      }
    }
  }
}
