import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ErrorComponent } from './pages/error/error.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SiteComponent } from './pages/site/site.component';
import { SiteListComponent } from './pages/site-list/site-list.component';

import { SiteResolver } from './services/site/site.resolver';
import { SiteListResolver } from './services/site/site-list.resolver';
import { ReportListResolver } from './services/report/report-list.resolver';
import { ReportResolver } from './services/report/report.resolver';
import { ControlAgentListResolver } from './services/control-agent/control-agent-list.resolver';
import { ReportComponent } from './pages/report/report.component';
import { CustomerListComponent } from './pages/customer-list/customer-list.component';
import { SessionGuard } from './services/session/session.guard';
import { LoginComponent } from './pages/login/login.component';
import { ControlAgentComponent } from './pages/control-agent/control-agent.component';
import { CustomerListResolver } from './services/customer/customer-list.resolver';
import { DocumentsComponent } from './pages/documents/documents.component';
import { SingleSiteGuard } from './services/site/single-site.guard';
import { UserListComponent } from './pages/user-list/user-list.component';
import { UserListResolver } from './services/user/user-list.resolver';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'sites' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [SessionGuard] },
  { path: 'clients', component: CustomerListComponent, canActivate: [SessionGuard] },
  {
    path: 'sites',
    component: SiteListComponent,
    canActivate: [SessionGuard, SingleSiteGuard],
    resolve: { sites: SiteListResolver, customers: CustomerListResolver }
  },
  {
    path: 'sites/:customerId',
    component: SiteListComponent,
    canActivate: [SessionGuard],
    resolve: { sites: SiteListResolver, customers: CustomerListResolver }
  },
  {
    path: 'site',
    canActivate: [SessionGuard],
    children: [
      {
        path: ':siteId', component: SiteComponent, resolve: {
          site: SiteResolver,
          reports: ReportListResolver
        }
      },
      {
        path: ':siteId/reports/:reportId', component: ReportComponent, resolve: {
          site: SiteResolver,
          report: ReportResolver,
          controlAgents: ControlAgentListResolver
        }
      }
    ]
  },
  { path: 'documents', component: DocumentsComponent, canActivate: [SessionGuard], resolve: {} },
  { path: 'agents', component: ControlAgentComponent, canActivate: [SessionGuard], resolve: { agents: ControlAgentListResolver } },
  { path: 'users', component: UserListComponent, canActivate: [SessionGuard], resolve: { users: UserListResolver } },
  { path: 'login', component: LoginComponent },
  { path: '**', component: ErrorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
