import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, Renderer, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-panel-split-header',
  templateUrl: './panel-split-header.component.html',
  styleUrls: ['./panel-split-header.component.scss']
})
export class PanelSplitHeaderComponent implements OnChanges, AfterViewInit {

  @Input() margin: number | string = '15px -15px';

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnChanges() {
    this.renderer.setStyle(this.el.nativeElement, 'margin', this.margin);
  }

  ngAfterViewInit(): void {
    this.renderer.setStyle(this.el.nativeElement, 'margin', this.margin);
  }

}
