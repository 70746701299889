import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.browser.print/dist/leaflet.browser.print.min.js';
import 'Leaflet.extra-markers';
import { MatDialog } from '@angular/material';
import { ControlMap } from '../../models/map';
import { Trap, TrapType } from '../../models/trap';
import { Point } from '../../models/geometry';

function trapTypeToColor(trapType: TrapType) {
  switch (trapType) {
    case TrapType.baitIndoor:
      return 'blue';
    case TrapType.baitOutdoor:
      return 'orange-dark';
    case TrapType.pheromone:
      return 'green';
    case TrapType.light:
      return 'orange';
    default:
      return 'white';
  }
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('newMapBanner', { read: ElementRef }) newMapBannerEl: ElementRef;

  @Input() editable: boolean;
  @Input() mapData: ControlMap;
  @Output() mapDataChange = new EventEmitter<ControlMap>();
  @Output() contextMenu = new EventEmitter<L.LeafletMouseEvent>();
  @Output() trapClicked = new EventEmitter<{ location: Point, trap: Trap }>();
  @Output() trapUpdated = new EventEmitter<Trap>();
  map: L.Map;
  mapOptions: L.MapOptions = {
    attributionControl: false,
    crs: L.CRS.Simple,
    center: L.latLng([500, 500]),
    zoom: -1,
    minZoom: -3,
    zoomSnap: 0,
  };
  mapCenter: L.LatLngExpression = L.latLng([500, 500]);
  mapLayers: L.Layer[] = [];
  markers: L.Marker[] = [];

  private imageData: any;
  private isDragging: boolean;

  get hasMap(): boolean {
    return !!this.mapData;
  }

  get hasMapImage(): boolean {
    return this.hasMap &&
      this.mapData.mapImage &&
      this.mapData.mapImage.mimeType &&
      this.mapData.mapImage.data &&
      !!this.mapData.mapImage.data.length;
  }

  constructor(private dialog: MatDialog, private changeDetectorRef: ChangeDetectorRef) {
  }

  addMarker(trap: Trap) {
    const marker = new L.Marker({ lng: trap.location.x, lat: trap.location.y }, {
      title: trap.id,
      draggable: this.editable,
      riseOnHover: this.editable,
      icon: L.ExtraMarkers.icon({
        markerColor: trapTypeToColor(trap.type),
        icon: 'fa-number',
        number: /\d+/.exec(trap.id)[0],
      })
    });
    marker.bindTooltip(trap.id + (trap.comment ? `<br>${trap.comment}` : ''));
    marker.addTo(this.map);
    marker.addEventListener('click', (event: L.LeafletMouseEvent) => {
      this.trapClicked.emit({
        location: {
          x: event.originalEvent.clientX,
          y: event.originalEvent.clientY
        },
        trap
      });
    });
    marker.addEventListener('dragend', (event) => {
      trap = {
        ...trap,
        location: {
          x: event.target._latlng.lng,
          y: event.target._latlng.lat,
        }
      };

      this.trapUpdated.emit(trap);
    });
    this.markers.push(marker);
  }

  removeMarker(trap: Trap) {
    const index = this.markers.findIndex(t => t.options.title === trap.id);
    if (index > -1) {
      this.markers[index].remove();
      this.markers.splice(index, 1);
    }
  }

  ngOnInit() {
    this.updateMap();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateMap();
  }

  async updateMap() {
    if (this.hasMapImage && this.mapData.mapImage.mimeType.startsWith('image/')) {
      const image = `data:${this.mapData.mapImage.mimeType};base64,${this.mapData.mapImage.data}`;
      const bounds = await this.calcBounds(image);
      this.mapCenter = [bounds[1][0] / 2, bounds[1][1] / 2];
      this.mapLayers[0] = L.imageOverlay(
        image,
        bounds
      );
      this.map.fitWorld({
        maxZoom: -3,
        animate: false,
        duration: 0
      });

      if (this.mapData && this.mapData.traps) {
        this.markers.forEach(marker => marker.remove());
        this.markers = [];
        for (const trap of this.mapData.traps) {
          this.addMarker(trap);
        }
      }
    }
  }

  ngAfterViewInit() {
    if (this.newMapBannerEl) {
      this.bindDragAndDrop(this.newMapBannerEl.nativeElement);
    }
    if (this.hasMapImage) {
      (<any>L.control).browserPrint({
        printModesNames: {
          Portrait: 'Pysty',
          Landscape: 'Vaaka',
          Auto: 'Automaattinen',
          Custom: 'Valitse alue'
        }
      }).addTo(this.map);
    }
  }

  calcBounds(data: string): Promise<L.LatLngBoundsExpression> {
    return new Promise((resolve, reject) => {
      this.imageData = new Image();
      this.imageData.onload = () => {
        const width = this.imageData.width;
        const height = this.imageData.height;
        const ratio = width / height;
        const bounds = [(width / ratio), width];
        resolve(<L.LatLngBoundsExpression>[[0, 0], bounds]);
      };
      this.imageData.onerror = reject;
      this.imageData.src = data;
    });
  }

  bindDragAndDrop(element: HTMLElement) {
    element.addEventListener('dragenter', event => {
      this.isDragging = true;
    });
    element.addEventListener('dragleave', event => {
      this.isDragging = false;
    });
    element.addEventListener('dragover', event => {
      event.preventDefault();
    });
    element.addEventListener('drop', event => {
      event.preventDefault();

      if (event.dataTransfer.files && event.dataTransfer.files.length === 1) {
        const file = event.dataTransfer.files[0];
        const fileReader = new FileReader();
        fileReader.addEventListener('load', async (loadEvent: any) => {
          const dataParts = loadEvent.target.result.split(';');
          this.mapData = {
            ...this.mapData,
            mapImage: {
              ...this.mapData.mapImage,
              mimeType: dataParts[0].split(':')[1],
              data: dataParts[1].split(',')[1],
            }
          };

          this.mapDataChange.emit(this.mapData);
        });
        fileReader.readAsDataURL(file);
      } else {
        alert('Kartan lisääminen epäonnistui. Lisää tasan yksi tiedosto.');
      }

      if (event.dataTransfer.items) {
        event.dataTransfer.items.clear();
      } else {
        event.dataTransfer.clearData();
      }

      this.isDragging = false;
    });
  }

  onMapReady(map: L.Map) {
    this.map = map;
    if (this.editable) {
      this.bindDragAndDrop(this.map.getContainer());
      this.map.on('contextmenu', (event: L.LeafletMouseEvent) => {
        this.contextMenu.emit(event);
        event.originalEvent.preventDefault();
        return false;
      });
    }
  }

}
