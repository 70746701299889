var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef, NgZone, OnInit } from '@angular/core';
import { MapComponent } from '../../elements/map/map.component';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Trap } from '../../models/trap';
import { SiteService } from '../../services/site/site.service';
import { CreateTrapDialogComponent } from '../create-trap-dialog/create-trap-dialog.component';
var MapEditorComponent = /** @class */ (function () {
    function MapEditorComponent(dialogRef, data, changeDetectorRef, service, dialog, ngZone) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.changeDetectorRef = changeDetectorRef;
        this.service = service;
        this.dialog = dialog;
        this.ngZone = ngZone;
        this.displayedColumns = ['id', 'type'];
        this.contextMenu = {
            isOpen: false,
            event: null,
            position: {
                x: 0,
                y: 0
            }
        };
    }
    Object.defineProperty(MapEditorComponent.prototype, "contextMenuOpen", {
        get: function () {
            return this.contextMenu.isOpen;
        },
        enumerable: true,
        configurable: true
    });
    MapEditorComponent.prototype.ngOnInit = function () {
        if (!this.data.mapData) {
            this.mapData = {
                id: null,
                revision: 0,
                site: {
                    id: this.data.site.id
                },
                name: 'control_map_1',
                mapImage: {
                    id: null,
                    mimeType: null,
                    data: null
                },
                traps: []
            };
        }
        else {
            this.mapData = this.data.mapData;
        }
    };
    MapEditorComponent.prototype.onOutsideContextClick = function (event) {
        if (event.target.parentElement.classList.contains('mat-list-item') || event.target.parentElement.classList.contains('mat-list')) {
            event.preventDefault();
            return;
        }
        this.contextMenu.isOpen = false;
        window.removeEventListener('click', this.onOutsideContextClick.bind(this));
    };
    MapEditorComponent.prototype.onContextMenu = function (event) {
        this.contextMenu.event = event;
        this.contextMenu.position.x = event.originalEvent.clientX;
        this.contextMenu.position.y = event.originalEvent.clientY;
        this.contextMenu.isOpen = true;
        window.addEventListener('click', this.onOutsideContextClick.bind(this));
        this.changeDetectorRef.detectChanges();
    };
    MapEditorComponent.prototype.openTrapDialog = function (position, trap) {
        var _this = this;
        var dialogRef = this.dialog.open(CreateTrapDialogComponent, {
            position: position,
            data: trap
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (!result) {
                return;
            }
            var finalTrap = result.trap;
            var index = _this.mapData.traps.findIndex(function (t) { return t.id === finalTrap.id; });
            if (result.type === 'delete') {
                _this.mapData = __assign({}, _this.mapData, { traps: _this.mapData.traps.slice(0, index).concat(_this.mapData.traps.slice(index + 1, _this.mapData.traps.length)) });
                _this.mapComponent.removeMarker(finalTrap);
            }
            else {
                if (index > -1) {
                    _this.mapData = __assign({}, _this.mapData, { traps: _this.mapData.traps.slice(0, index).concat([
                            finalTrap
                        ], _this.mapData.traps.slice(index + 1, _this.mapData.traps.length)) });
                }
                else {
                    _this.mapData = __assign({}, _this.mapData, { traps: _this.mapData.traps.concat([finalTrap]) });
                }
                _this.mapComponent.addMarker(finalTrap);
            }
            _this.contextMenu.event = null;
            _this.changeDetectorRef.detectChanges();
        });
    };
    MapEditorComponent.prototype.onAddTrap = function (type) {
        var _this = this;
        if (!this.contextMenu.isOpen) {
            return;
        }
        this.ngZone.run(function () {
            _this.contextMenu.isOpen = false;
            _this.openTrapDialog({
                top: _this.contextMenu.position.y + 'px',
                left: _this.contextMenu.position.x + 'px'
            }, {
                type: type,
                location: {
                    x: _this.contextMenu.event.latlng.lng,
                    y: _this.contextMenu.event.latlng.lat
                }
            });
        });
    };
    MapEditorComponent.prototype.onMapDataChange = function (mapData) {
        this.mapData = mapData;
        this.mapComponent.updateMap();
    };
    MapEditorComponent.prototype.onTrapChange = function (trap) {
        var index = this.mapData.traps.findIndex(function (t) { return t.id === trap.id; });
        this.mapData = __assign({}, this.mapData, { traps: this.mapData.traps.slice(0, index).concat([
                trap
            ], this.mapData.traps.slice(index + 1, this.mapData.traps.length)) });
    };
    MapEditorComponent.prototype.onTrapClick = function (event) {
        var _this = this;
        this.ngZone.run(function () {
            _this.openTrapDialog({
                top: event.location.y + 'px',
                left: event.location.x + 'px'
            }, event.trap);
        });
    };
    MapEditorComponent.prototype.onUpdateMap = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.service.updateMap(this.data.site, this.mapData)];
                    case 1:
                        _a.sent();
                        this.dialogRef.close(this.mapData);
                        return [2 /*return*/];
                }
            });
        });
    };
    return MapEditorComponent;
}());
export { MapEditorComponent };
