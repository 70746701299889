<app-panel contentPadding="20px">
  <form [formGroup]="loginForm" novalidate (ngSubmit)="onLogin()">
    <h1>DESIRA</h1>
    <mat-form-field>
      <input matInput placeholder="User name" autofocus formControlName="username">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="password" placeholder="Password" formControlName="password">
    </mat-form-field>
    <div class="controls">
      <button mat-raised-button color="primary" type="submit">
        Log In
      </button>
    </div>
  </form>
</app-panel>
