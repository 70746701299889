import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportService } from '../../services/report/report.service';
import { SessionService } from '../../services/session/session.service';
var ReportComponent = /** @class */ (function () {
    function ReportComponent(route, service, router, session) {
        this.route = route;
        this.service = service;
        this.router = router;
        this.session = session;
        this.editing = false;
    }
    Object.defineProperty(ReportComponent.prototype, "canEdit", {
        get: function () {
            return true;
        },
        enumerable: true,
        configurable: true
    });
    ReportComponent.prototype.ngOnInit = function () {
        if (this.route.snapshot.params['reportId'] === 'new') {
            this.editing = true;
        }
        this.site = this.route.snapshot.data.site;
        this.controlAgents = this.route.snapshot.data.controlAgents;
        this.report = this.route.snapshot.data.report || this.service.activeReport || {};
        this.service.activeReport = undefined;
    };
    ReportComponent.prototype.toggleEdit = function () {
        this.editing = !this.editing;
    };
    ReportComponent.prototype.print = function () {
        window.print();
    };
    ReportComponent.prototype.onReportChange = function (reportChange) {
        var _this = this;
        if (reportChange.type === 'cancelled') {
            if (this.route.snapshot.params['reportId'] === 'new') {
                this.router.navigate(['/site', this.route.snapshot.params['siteId']]);
            }
            else {
                this.editing = false;
            }
        }
        else if (reportChange.type === 'submitted') {
            if (this.route.snapshot.params['reportId'] === 'new') {
                this.service.create(reportChange.report).subscribe(function (result) {
                    _this.report = result;
                    _this.editing = false;
                });
            }
            else {
                this.service.update(reportChange.report).subscribe(function (success) {
                    if (success) {
                        _this.report = reportChange.report;
                        _this.editing = false;
                    }
                    else {
                        console.error('Failed to update report');
                    }
                });
            }
        }
    };
    return ReportComponent;
}());
export { ReportComponent };
