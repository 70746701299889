export enum DocumentType {
  SafetyInstruction,
  SiteTypeDocument, // Deprecated
  SiteSpecificDocument
}

export interface Document {
  readonly id?: string;
  readonly fileName?: string;
  readonly mimeType?: string;
  readonly description?: string;
  readonly type?: DocumentType;
  readonly relationId?: string;
}
