import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ControlAgentService } from './control-agent.service';
import { ControlAgent } from '../../models/control-agent';

@Injectable()
export class ControlAgentListResolver implements Resolve<Observable<ControlAgent[]>> {

  constructor(private service: ControlAgentService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.service.list();
  }

}
