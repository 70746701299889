import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ReportService } from './report.service';
import { Report } from '../../models/report';

import 'rxjs/add/operator/toPromise';

@Injectable()
export class ReportResolver implements Resolve<Promise<Report>> {

  constructor(private service: ReportService) {}

  async resolve(route: ActivatedRouteSnapshot) {
    if (route.paramMap.get('reportId') === 'new') {
      return null;
    }

    try {
      return await this.service.get(route.paramMap.get('reportId')).toPromise();
    } catch (err) {
      return null;
    }
  }

}
