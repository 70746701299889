import { ChangeDetectorRef, EventEmitter, OnInit } from '@angular/core';
import { TrapType } from '../../../models/trap';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
var ReportEditComponent = /** @class */ (function () {
    function ReportEditComponent(formBuilder, changeDetectorRef) {
        this.formBuilder = formBuilder;
        this.changeDetectorRef = changeDetectorRef;
        this.reportChange = new EventEmitter();
        this.initializeForm();
        this.initializeBaitTrapReportForm();
        this.initializePheromoneTrapReportForm();
        this.initializeLightTrapReportForm();
        this.initializeSprayTrapReportForm();
    }
    Object.defineProperty(ReportEditComponent.prototype, "baitAgents", {
        get: function () {
            return this.controlAgents.filter(function (t) { return !t.isSpray; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportEditComponent.prototype, "sprayAgents", {
        get: function () {
            return this.controlAgents.filter(function (t) { return t.isSpray; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportEditComponent.prototype, "mapSnapshot", {
        get: function () {
            return this.reportForm.get('mapSnapshot');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportEditComponent.prototype, "baitTrapReports", {
        get: function () {
            return this.reportForm.get('baitTrapReports');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportEditComponent.prototype, "pheromoneTrapReports", {
        get: function () {
            return this.reportForm.get('pheromoneTrapReports');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportEditComponent.prototype, "lightTrapReports", {
        get: function () {
            return this.reportForm.get('lightTrapReports');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportEditComponent.prototype, "sprayTrapReports", {
        get: function () {
            return this.reportForm.get('sprayTrapReports');
        },
        enumerable: true,
        configurable: true
    });
    ReportEditComponent.prototype.addBaitTrapRow = function (controlName, input, value) {
        switch (controlName) {
            case 'id':
                this.addBaitTrapControl(value);
                break;
            case 'caught':
                this.addBaitTrapControl(null, value);
                break;
            case 'pests':
                this.addBaitTrapControl(null, null, value);
                break;
            case 'agentId':
                this.addBaitTrapControl(null, null, null, value);
                break;
            case 'agentAmount':
                this.addBaitTrapControl(null, null, null, null, value);
                break;
            default:
                break;
        }
        input.value = null;
        this.changeDetectorRef.detectChanges();
        this.baitTrapRows.last.nativeElement.querySelectorAll('[formControlName=' + controlName + ']')[0].focus();
        return false;
    };
    ReportEditComponent.prototype.addSprayTrapRow = function (controlName, input, value) {
        switch (controlName) {
            case 'zoneName':
                this.addSprayTrapControl(value);
                break;
            case 'agentId':
                this.addSprayTrapControl(null, value);
                break;
            case 'agentAmount':
                this.addSprayTrapControl(null, null, value);
                break;
            default:
                break;
        }
        input.value = null;
        this.changeDetectorRef.detectChanges();
        this.sprayTrapRows.last.nativeElement.querySelectorAll('[formControlName=' + controlName + ']')[0].focus();
        return false;
    };
    ReportEditComponent.prototype.addPheromoneTrapRow = function (controlName, input, value) {
        switch (controlName) {
            case 'id':
                this.addPheromoneTrapControl(value);
                input.value = null;
                break;
            case 'caught':
                this.addPheromoneTrapControl(null, value);
                input.value = null;
                break;
            case 'changed':
                this.addPheromoneTrapControl(null, null, input.checked);
                input.checked = false;
                break;
            case 'observations':
                this.addPheromoneTrapControl(null, null, null, value);
                input.value = null;
                break;
            default:
                break;
        }
        this.changeDetectorRef.detectChanges();
        this.pheromoneTrapRows.last.nativeElement.querySelectorAll('[formControlName=' + controlName + ']')[0].focus();
        return false;
    };
    ReportEditComponent.prototype.addLightTrapRow = function (controlName, input, value) {
        switch (controlName) {
            case 'id':
                this.addLightTrapControl(value);
                input.value = null;
                break;
            case 'caught':
                this.addLightTrapControl(null, value);
                input.value = null;
                break;
            case 'paperChanged':
                this.addLightTrapControl(null, null, input.checked);
                input.checked = false;
                break;
            case 'lightChanged':
                this.addLightTrapControl(null, null, null, input.checked);
                break;
            case 'observations':
                this.addLightTrapControl(null, null, null, null, value);
                input.value = null;
                break;
            default:
                break;
        }
        this.changeDetectorRef.detectChanges();
        this.lightTrapRows.last.nativeElement.querySelectorAll('[formControlName=' + controlName + ']')[0].focus();
        return false;
    };
    ReportEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.report.data) {
            this.populateMapTrapControls(this.report.data.mapSnapshot.traps.length);
            this.populateArrayControls(TrapType.baitIndoor, this.report.data.baitTrapReports);
            this.populateArrayControls(TrapType.pheromone, this.report.data.pheromoneTrapReports);
            this.populateArrayControls(TrapType.light, this.report.data.lightTrapReports);
            this.populateArrayControls(TrapType.spray, this.report.data.sprayTrapReports);
            this.reportForm.patchValue(this.report.data);
        }
        else {
            if (this.site.map && this.site.map.traps) {
                var mappedTraps = this.site.map.traps;
                this.populateMapTrapControls(mappedTraps.length);
                this.reportForm.get('mapSnapshot').patchValue(this.site.map);
                mappedTraps.forEach(function (trap) {
                    var control;
                    switch (trap.type) {
                        case TrapType.baitIndoor:
                        case TrapType.baitOutdoor:
                            control = _this.addBaitTrapControl(trap.id, null, null, trap.agentId);
                            break;
                        case TrapType.pheromone:
                            control = _this.addPheromoneTrapControl(trap.id);
                            break;
                        case TrapType.light:
                            control = _this.addLightTrapControl(trap.id);
                            break;
                        default:
                            console.warn('Unknown trap type', trap.type);
                            break;
                    }
                    if (control) {
                        control.get('id').disable();
                    }
                });
            }
        }
        this.autofocus.nativeElement.focus();
    };
    ReportEditComponent.prototype.populateArrayControls = function (type, items) {
        for (var i = 0; i < items.length; i++) {
            var item = items[i];
            switch (type) {
                case TrapType.baitIndoor:
                case TrapType.baitOutdoor:
                    this.addBaitTrapControl(item.id, item.caught, item.pests, item.agentId, item.agentAmount);
                    break;
                case TrapType.pheromone:
                    this.addPheromoneTrapControl(item.id, item.caught, item.changed, item.observations);
                    break;
                case TrapType.light:
                    this.addLightTrapControl(item.id, item.caught, item.paperChanged, item.lightChanged, item.observable);
                    break;
                case TrapType.spray:
                    this.addSprayTrapControl(item.zoneName, item.agentId, item.agentAmount);
                    break;
                default:
                    console.warn('Unknown trap type', type);
                    break;
            }
        }
    };
    ReportEditComponent.prototype.populateMapTrapControls = function (trapCount) {
        var mapTraps = this.reportForm.get(['mapSnapshot', 'traps']);
        for (var i = 0; i < trapCount; i++) {
            mapTraps.push(this.formBuilder.group({
                id: '',
                type: '',
                agentId: null,
                location: '',
                data: ''
            }));
        }
    };
    ReportEditComponent.prototype.initializeForm = function () {
        this.reportForm = this.formBuilder.group({
            pestControlZone: ['', Validators.required],
            pestControlReason: ['', Validators.required],
            operator: ['', Validators.required],
            date: [new Date(), Validators.required],
            mapSnapshot: this.formBuilder.group({
                id: [0, Validators.required],
                revision: [0, Validators.required],
                siteId: [0, Validators.required],
                name: ['', Validators.required],
                traps: this.formBuilder.array([])
            }),
            baitTrapReports: this.formBuilder.array([]),
            sprayTrapReports: this.formBuilder.array([]),
            pheromoneTrapReports: this.formBuilder.array([]),
            lightTrapReports: this.formBuilder.array([]),
            pestControlReview: ['']
        });
    };
    ReportEditComponent.prototype.initializeBaitTrapReportForm = function () {
        this.newBaitTrapReportForm = this.formBuilder.group({
            caught: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]+')])],
            pests: ['', Validators.required],
            agentId: [null, Validators.required],
            agentAmount: ['', Validators.required]
        });
    };
    ReportEditComponent.prototype.initializePheromoneTrapReportForm = function () {
        this.newPheromoneTrapReport = this.formBuilder.group({
            id: [null, Validators.required],
            caught: [null, Validators.compose([Validators.required, Validators.pattern('[0-9]+')])],
            changed: [false, Validators.required],
            observations: ['']
        });
    };
    ReportEditComponent.prototype.initializeLightTrapReportForm = function () {
        this.newLightTrapReport = this.formBuilder.group({
            id: [null, Validators.required],
            caught: [null, Validators.compose([Validators.required, Validators.pattern('[0-9]+')])],
            paperChanged: [false, Validators.required],
            lightChanged: [false, Validators.required],
            observations: ['']
        });
    };
    ReportEditComponent.prototype.initializeSprayTrapReportForm = function () {
        this.newSprayTrapReport = this.formBuilder.group({
            zoneName: ['', Validators.required],
            agentId: [null, Validators.required],
            agentAmount: [null, Validators.required]
        });
    };
    ReportEditComponent.prototype.addBaitTrapControl = function (id, caught, pests, agentId, agentAmount) {
        var control = this.formBuilder.group({
            id: [id, Validators.required],
            trapType: [TrapType.baitIndoor, Validators.required],
            agentId: [agentId || null, Validators.required],
            agentAmount: [agentAmount || null, Validators.required],
            caught: [caught || '', Validators.compose([Validators.required, Validators.pattern('[0-9]+')])],
            pests: [pests || '', Validators.required]
        });
        this.baitTrapReports.push(control);
        return control;
    };
    ReportEditComponent.prototype.addPheromoneTrapControl = function (id, caught, changed, observations) {
        var control = this.formBuilder.group({
            id: [id || null, Validators.required],
            caught: [caught || null, Validators.compose([Validators.required, Validators.pattern('[0-9]+')])],
            changed: [changed || false, Validators.required],
            observations: [observations || '']
        });
        this.pheromoneTrapReports.push(control);
        return control;
    };
    ReportEditComponent.prototype.addLightTrapControl = function (id, caught, paperChanged, lightChanged, observations) {
        var control = this.formBuilder.group({
            id: [id || null, Validators.required],
            caught: [caught || null, Validators.compose([Validators.required, Validators.pattern('[0-9]+')])],
            paperChanged: [paperChanged || false, Validators.required],
            lightChanged: [lightChanged || false, Validators.required],
            observations: [observations || '']
        });
        this.lightTrapReports.push(control);
        return control;
    };
    ReportEditComponent.prototype.addSprayTrapControl = function (zoneName, agentId, agentAmount) {
        var control = this.formBuilder.group({
            zoneName: [zoneName || '', Validators.required],
            agentId: [agentId || null, Validators.required],
            agentAmount: [agentAmount || null, Validators.required]
        });
        this.sprayTrapReports.push(control);
        return control;
    };
    ReportEditComponent.prototype.validateAllFormFields = function (formGroup) {
        var _this = this;
        Object.keys(formGroup.controls).forEach(function (field) {
            var control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            }
            else if (control instanceof FormGroup) {
                _this.validateAllFormFields(control);
            }
            else if (control instanceof FormArray) {
                for (var _i = 0, _a = control.controls; _i < _a.length; _i++) {
                    var subControl = _a[_i];
                    _this.validateAllFormFields(subControl);
                }
            }
        });
    };
    ReportEditComponent.prototype.onCancel = function () {
        this.reportChange.emit({ type: 'cancelled' });
    };
    ReportEditComponent.prototype.onSubmit = function () {
        this.validateAllFormFields(this.reportForm);
        if (this.reportForm.valid) {
            this.reportChange.emit({
                type: 'submitted',
                report: {
                    id: this.report.id || null,
                    site: {
                        id: this.site.id
                    },
                    created: this.report.created || new Date().toISOString(),
                    data: this.reportForm.getRawValue()
                }
            });
        }
    };
    ReportEditComponent.prototype.removeRow = function (type, index) {
        if (confirm('Haluatko varmasti poistaa rivin?')) {
            switch (type) {
                case 'bait':
                    this.baitTrapReports.removeAt(index);
                    break;
                case 'spray':
                    this.sprayTrapReports.removeAt(index);
                    break;
                case 'light':
                    this.lightTrapReports.removeAt(index);
                    break;
                case 'pheromone':
                    this.pheromoneTrapReports.removeAt(index);
                    break;
            }
        }
    };
    return ReportEditComponent;
}());
export { ReportEditComponent };
