import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SearchResult } from './search-result.model';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SiteService } from '../../services/site/site.service';
import { CustomerService } from '../../services/customer/customer.service';
import { MatAutocompleteSelectedEvent } from '@angular/material';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  searchControl: FormControl = new FormControl();
  searchResults: Observable<SearchResult[]>;
  siteOptions: SearchResult[];
  customerOptions: SearchResult[];
  loading: boolean;

  @Input() clearOnSelect: boolean;
  @Output() onSelected = new EventEmitter<MatAutocompleteSelectedEvent>();

  constructor(private siteService: SiteService, private customerService: CustomerService) {
  }

  ngOnInit() {
    this.loading = true;
    this.searchResults = this.searchControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    this.customerService.list().subscribe(
      customers => this.customerOptions = customers
        .map(t => ({ displayName: `[ASIAKAS] ${t.name}`, value: t, meta: 'customer' })));
    this.siteService.list().subscribe(
      sites => this.siteOptions = sites
        .map(t => ({ displayName: `[KOHDE] ${t.customer.name}, ${t.name}`, value: t, meta: 'site' })));
  }

  private _filter(value: string | SearchResult): SearchResult[] {
    const filterValue = value
      ? typeof(value) === 'string' ? value.toLowerCase() : value.displayName.toLowerCase()
      : '';
    const filteredCustomers = this.customerOptions
      ? this.customerOptions.filter(option => option.displayName.toLowerCase().includes(filterValue))
      : [];
    const filteredSites = this.siteOptions
      ? this.siteOptions.filter(option => option.displayName.toLowerCase().includes(filterValue))
      : [];
    return [...filteredCustomers, ...filteredSites];
  }

  displayFn(selected: SearchResult): string {
    return selected ? selected.displayName : '';
  }

  handleSelect($event: MatAutocompleteSelectedEvent) {
    this.onSelected.emit($event.option.value);
    if (this.clearOnSelect) {
      this.searchControl.reset();
    }
  }
}
