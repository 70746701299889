import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ControlAgent } from '../../models/control-agent';
import { ControlAgentService } from '../../services/control-agent/control-agent.service';

@Component({
  selector: 'app-control-agent-form',
  templateUrl: './control-agent.component.html',
  styleUrls: ['./control-agent.component.scss']
})
export class ControlAgentFormComponent implements OnInit {

  private isEditing: boolean;
  @Input()
  set editing(value: boolean) {
    this.isEditing = value;
    if (this.agentForm) {
      if (!value) {
        this.agentForm.disable();
      } else {
        this.agentForm.enable();
      }
    }
  }
  get editing() {
    return this.isEditing;
  }
  @Input() agent: ControlAgent;
  agentForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private service: ControlAgentService) {
  }

  ngOnInit() {
    this.agentForm = this.formBuilder.group({
      id: [null],
      displayName: new FormControl({ value: '', disabled: !this.editing }, Validators.required),
      agent: new FormControl({ value: '', disabled: !this.editing }),
      maxConcentration: new FormControl({ value: '', disabled: !this.editing }),
      unit: new FormControl({ value: '', disabled: !this.editing }),
      isSpray: new FormControl({ value: '', disabled: !this.editing })
    });
    if (this.agent) {
      this.agentForm.setValue(this.agent);
    }
  }

  async onSubmit() {
    if (this.agentForm.valid && this.agentForm.dirty) {
      try {
        if (this.agent.id && this.agent.id !== '') {
          await this.service.update(<ControlAgent>{
            id: this.agent.id,
            ...this.agentForm.value
          }).toPromise();
        } else {
          const result = await this.service.create(this.agentForm.value).toPromise();
          this.agent = result;
        }
        this.editing = false;
      } catch (err) {
        // TODO: User feedback
        console.error(err);
      }
    }
  }
}
