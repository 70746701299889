import { Component, Inject, OnInit } from '@angular/core';
import { CustomerService } from '../../services/customer/customer.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Customer } from '../../models/customer';

@Component({
  selector: 'app-customer-form-dialog',
  templateUrl: './customer-form-dialog.component.html',
  styleUrls: ['./customer-form-dialog.component.scss']
})
export class CustomerFormDialogComponent implements OnInit {

  customerForm: FormGroup;

  constructor(private service: CustomerService, private dialogRef: MatDialogRef<CustomerFormDialogComponent>,
              private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) private data: Customer) { }

  ngOnInit() {
    this.customerForm = this.formBuilder.group({
      id: [null],
      name: ['', Validators.required]
    });

    if (this.data) {
      this.customerForm.patchValue(this.data);
    }
  }

  async onSubmit() {
    if (this.customerForm.valid && this.customerForm.dirty) {
      try {
        const result = await this.service.create(this.customerForm.value).toPromise();
        this.dialogRef.close({
          succeeded: true,
          customer: result
        });
      } catch (err) {
        console.error(err);
      }
    }
  }

}
