import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Site } from '../../models/site';
import { SessionService } from '../../services/session/session.service';
import { SiteFormDialogComponent } from '../../dialogs/site-form-dialog/site-form-dialog.component';
import { Customer } from '../../models/customer';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-site-list',
  templateUrl: './site-list.component.html',
  styleUrls: ['./site-list.component.scss']
})
export class SiteListComponent implements OnInit {

  customers: Customer[] = [];
  activeCustomer: string;

  sites: Site[] = [];
  activeSites: Site[] = [];

  constructor(private route: ActivatedRoute, public session: SessionService, private dialog: MatDialog, private router: Router) { }

  ngOnInit() {
    this.sites = this.route.snapshot.data.sites.slice().sort((a, b) => a.name.localeCompare(b.name));
    this.customers = this.route.snapshot.data.customers.slice().sort((a, b) => a.name.localeCompare(b.name));
    this.activeCustomer = this.route.snapshot.params['customerId'];
    if (!this.activeCustomer && this.customers.length > 0) {
      this.activeCustomer = this.customers[0].id;
    }
    this.filterSites();
  }

  filterSites() {
    if (!this.activeCustomer) {
      this.activeSites = this.sites;
      return;
    }

    this.activeSites = this.sites.filter(t => t.customer.id === this.activeCustomer);
  }

  onAdd() {
    const dialogRef = this.dialog.open(SiteFormDialogComponent, {
      data: {
        customer: this.customers.filter(t => t.id === this.activeCustomer)[0]
      },
      position: {
        top: '74px'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.succeeded) {
        this.sites = this.sites.concat(result.site);
      }
    });
  }

  onCustomerSelected(event) {
    this.activeCustomer = event.target.value;
    this.filterSites();
  }

}
