<div class="flex-container">
  <app-panel [contentPadding]="0">
    <app-panel-header>
      <app-title>
        {{ site.customer.name }}, {{ site.name }}
        <span class="mat-subheading-1">{{ site.address }}</span>
      </app-title>
      <button mat-mini-fab color="warn" class="edit-map" *ngIf="session.isAdmin" (click)="onEditSite()">
        <mat-icon>edit</mat-icon>
      </button>
    </app-panel-header>
  </app-panel>
</div>

<div class="flex-container">
  <div class="flex-item">
    <app-panel [contentPadding]="0">
      <app-panel-header>Raportit</app-panel-header>
      <mat-table #latestReportsTable [dataSource]="reports" class="mat-table-interactive-rows">
        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef>Päivämäärä</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.data.date | date:'dd.MM.yyyy'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="reporter">
          <mat-header-cell *matHeaderCellDef>Reportoija</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.data.name }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="reason">
          <mat-header-cell *matHeaderCellDef>Torjunnan kohde</mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element.data.pestControlReason }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element" class="controls">
            <div *ngIf="session.isAdmin">
              <button mat-icon-button title="Tulosta" (click)="onPrintReport($event, element)">
                <mat-icon>print</mat-icon>
              </button>
              <button mat-icon-button title="Kopioi" (click)="onReportCopy($event, element)">
                <mat-icon>flip_to_back</mat-icon>
              </button>
              <button mat-icon-button title="Poista" color="warn" (click)="onReportDelete($event, element)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </mat-cell>
        </ng-container>

        <mat-row *matRowDef="let row; columns: reportColumns;" routerLink="/site/{{ site.id }}/reports/{{ row.id }}"></mat-row>
      </mat-table>
    </app-panel>

    <app-panel [contentPadding]="'15px'" *ngIf="baitChart?.datasets?.length || otherTrapChart?.datasets?.length">
      <app-panel-header *ngIf="baitChart?.datasets?.length">Syöntikaavio käynneittäin</app-panel-header>
      <app-chart [labels]="baitChart.labels" [datasets]="baitChart.datasets" *ngIf="baitChart?.datasets?.length"></app-chart>
      <app-panel-split-header *ngIf="baitChart?.datasets?.length && otherTrapChart?.datasets?.length">Muut ansat käynneittäin</app-panel-split-header>
      <app-panel-header *ngIf="!(baitChart?.datasets?.length) && otherTrapChart?.datasets?.length">Muut ansat käynneittäin</app-panel-header>
      <app-chart [labels]="otherTrapChart.labels" [datasets]="otherTrapChart.datasets" *ngIf="otherTrapChart?.datasets?.length"></app-chart>
    </app-panel>
  </div>

  <div class="flex-item">
    <app-panel [contentPadding]="0">
      <button mat-mini-fab color="warn" class="edit-map" *ngIf="site.map && session.isAdmin" (click)="onMapEdit()">
        <mat-icon>edit</mat-icon>
      </button>
      <app-panel-header>Ansa- ja syöttikartta</app-panel-header>
      <app-map [mapData]="site.map" #map></app-map>
      <app-map-legend></app-map-legend>
      <div class="no-map" *ngIf="!site.map">
        <h4>Kohteelle ei ole vielä luotu karttaa!</h4>
        <button mat-raised-button color="primary" (click)="onNewMap()">Luo uusi kartta</button>
      </div>
    </app-panel>

    <app-panel [contentPadding]="0" *ngIf="session.isAdmin">
      <app-panel-header *ngIf="incompleteReports.length">Keskeneräinen raportti</app-panel-header>

      <mat-list *ngIf="incompleteReports.length">
        <mat-list-item *ngFor="let report of incompleteReports">
          <div class="buttonLine">
            <span>
              {{ report.reporterId }} {{ report.date | date }}
            </span>
            <button mat-raised-button color="primary">Muokkaa</button>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <div class="buttonLine">
            <span>&nbsp;</span>
            <button mat-raised-button color="primary" routerLink="/site/{{ site.id }}/reports/new">Uusi raportti</button>
          </div>
        </mat-list-item>
      </mat-list>
      <div class="new-report" *ngIf="!incompleteReports.length">
        <button mat-raised-button color="primary" routerLink="/site/{{ site.id }}/reports/new">Uusi raportti</button>
      </div>
    </app-panel>

    <app-panel [contentPadding]="0">
      <app-panel-header>
        Asiakirjat
        <input type="file" style="display: none;" #fileUpload (change)="onFilesAdded($event)"/>
        <button mat-raised-button class="add-document" color="white" (click)="fileUpload.click()">Uusi dokumentti</button>
      </app-panel-header>
      <mat-table #latestReportsTable [dataSource]="otherDocuments" class="mat-table-interactive-rows" *ngIf="otherDocuments.length">
        <ng-container matColumnDef="fileName">
          <mat-header-cell *matHeaderCellDef>Tiedoston Nimi</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="file-row">
              {{ element.fileName }}
              <span></span>
              <button mat-icon-button>
                <mat-icon>cloud_download</mat-icon>
              </button>
              <button mat-icon-button title="Poista" color="warn" (click)="onDeleteFile($event, element)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </mat-cell>
        </ng-container>

        <mat-row *matRowDef="let row; columns: fileColumns;" (click)="onFileClick(row)"></mat-row>
      </mat-table>
      <app-panel-split-header [margin]="0" *ngIf="agentSafetyInstructions.length">Käyttöturvatiedotteet</app-panel-split-header>
      <mat-table #latestReportsTable [dataSource]="agentSafetyInstructions" class="mat-table-interactive-rows" *ngIf="agentSafetyInstructions.length">
        <ng-container matColumnDef="fileName">
          <mat-header-cell *matHeaderCellDef>Tiedoston Nimi</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="file-row">
              {{ element.fileName }}
              <span></span>
              <mat-icon>cloud_download</mat-icon>
            </div>
          </mat-cell>
        </ng-container>

        <mat-row *matRowDef="let row; columns: fileColumns;" (click)="onFileClick(row)"></mat-row>
      </mat-table>
    </app-panel>
  </div>
</div>
