<div class="flex-container">
  <app-panel>
    <app-panel-header>
      Dokumentit
      <div class="controls">
        <button mat-button (click)="onAddFile()">
          <mat-icon>add</mat-icon>
          Lisää Dokumentti
        </button>
      </div>
    </app-panel-header>
    <table mat-table [dataSource]="documents">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>Nro.</th>
        <td mat-cell *matCellDef="let entry">{{ entry.id }}</td>
      </ng-container>

      <ng-container matColumnDef="fileName">
        <th mat-header-cell *matHeaderCellDef>Nimi</th>
        <td mat-cell *matCellDef="let entry">{{ entry.fileName }}</td>
      </ng-container>

      <ng-container matColumnDef="mimeType">
        <th mat-header-cell *matHeaderCellDef>Tiedoston tyyppi</th>
        <td mat-cell *matCellDef="let entry">{{ entry.mimeType }}</td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Tarkoitus</th>
        <td mat-cell *matCellDef="let entry">{{ entry.type | documentType }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Toiminnot</th>
        <td mat-cell *matCellDef="let entry">
          <!--<button mat-icon-button *ngIf="entry.relationId">
            <mat-icon>attachment</mat-icon>
          </button>-->
          <button mat-icon-button color="warn" (click)="onDelete(entry)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </app-panel>
</div>
