import { ErrorHandler } from '@angular/core';

import * as Raven from 'raven-js';

Raven
  .config('https://8bd3fe38d8474eea897503d331991db2@sentry.remod.fi/2')
  .install();

export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    Raven.captureException(err);
  }
}
