var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import gql from 'graphql-tag';
import { parse } from 'date-fns';
export function getStoredObject(key) {
    try {
        var serializedItem = localStorage.getItem(key);
        return JSON.parse(serializedItem);
    }
    catch (err) {
        console.error(err);
        return null;
    }
}
export function setStoredObject(key, value) {
    try {
        var serializedValue = JSON.stringify(key, value);
        localStorage.setItem(key, serializedValue);
        return true;
    }
    catch (err) {
        console.error(err);
        return false;
    }
}
var listQuery = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nquery listSitesForUser {\n  sites {\n    id\n    customer {\n      id\n      name\n    }\n    name\n    address\n    zipCode\n  }\n}\n"], ["\nquery listSitesForUser {\n  sites {\n    id\n    customer {\n      id\n      name\n    }\n    name\n    address\n    zipCode\n  }\n}\n"])));
var listByCustomerQuery = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\nquery listSitesForCustomer($id: ID) {\n  customer(id: $id) {\n    id\n    sites {\n      id\n      customer {\n        id\n      }\n      name\n      address\n      zipCode\n    }\n  }\n}\n"], ["\nquery listSitesForCustomer($id: ID) {\n  customer(id: $id) {\n    id\n    sites {\n      id\n      customer {\n        id\n      }\n      name\n      address\n      zipCode\n    }\n  }\n}\n"])));
var getByIdQuery = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\nquery getSiteById($id: ID) {\n  site(id: $id) {\n    id\n    customer {\n      id\n      name\n    }\n    name\n    address\n    zipCode\n    map {\n      id\n      name\n      mapImage {\n        id\n        mimeType\n        data\n      }\n      traps\n    }\n    reports {\n      id\n    }\n    documents {\n      id\n      fileName\n      description\n      mimeType\n      type\n    }\n  }\n}\n"], ["\nquery getSiteById($id: ID) {\n  site(id: $id) {\n    id\n    customer {\n      id\n      name\n    }\n    name\n    address\n    zipCode\n    map {\n      id\n      name\n      mapImage {\n        id\n        mimeType\n        data\n      }\n      traps\n    }\n    reports {\n      id\n    }\n    documents {\n      id\n      fileName\n      description\n      mimeType\n      type\n    }\n  }\n}\n"])));
var statisticsQuery = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\nquery getSiteStatistics($id: ID, $from: date, $to: date) {\n  site(id: $id) {\n    id\n    trapSummaries(from: $from, to: $to) {\n      date\n      baitCaught\n      pheromoneCaught\n      lightCaught\n    }\n  }\n}\n"], ["\nquery getSiteStatistics($id: ID, $from: date, $to: date) {\n  site(id: $id) {\n    id\n    trapSummaries(from: $from, to: $to) {\n      date\n      baitCaught\n      pheromoneCaught\n      lightCaught\n    }\n  }\n}\n"])));
var mutation = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\nmutation siteMutation($site: isite!) {\n  site(site: $site) {\n    id\n    customer {\n      id\n      name\n    }\n    name\n    address\n    zipCode\n    map {\n      id\n      name\n      mapImage {\n        id\n        mimeType\n        data\n      }\n      traps\n    }\n    reports {\n      id\n    }\n    documents {\n      id\n      fileName\n      description\n      mimeType\n      type\n    }\n  }\n}\n"], ["\nmutation siteMutation($site: isite!) {\n  site(site: $site) {\n    id\n    customer {\n      id\n      name\n    }\n    name\n    address\n    zipCode\n    map {\n      id\n      name\n      mapImage {\n        id\n        mimeType\n        data\n      }\n      traps\n    }\n    reports {\n      id\n    }\n    documents {\n      id\n      fileName\n      description\n      mimeType\n      type\n    }\n  }\n}\n"])));
var mapMutation = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\nmutation mapMutation($map: imap!) {\n  map(map: $map) {\n    id\n    mapImage {\n      id\n    }\n    name\n    revision\n    site {\n      id\n    }\n    traps\n  }\n}\n"], ["\nmutation mapMutation($map: imap!) {\n  map(map: $map) {\n    id\n    mapImage {\n      id\n    }\n    name\n    revision\n    site {\n      id\n    }\n    traps\n  }\n}\n"])));
var mapImageMutation = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\nmutation mapImageMutation($mapImage: imapImage!) {\n  mapImage(mapImage: $mapImage) {\n    created\n    data\n    id\n    map {\n      id\n    }\n    mimeType\n  }\n}\n"], ["\nmutation mapImageMutation($mapImage: imapImage!) {\n  mapImage(mapImage: $mapImage) {\n    created\n    data\n    id\n    map {\n      id\n    }\n    mimeType\n  }\n}\n"])));
var reParts = /\d+|\D+/g;
var reDigit = /\d/;
function sortStringsWithNumbers(a, b) {
    // Get rid of casing issues.
    a = a.toUpperCase();
    b = b.toUpperCase();
    // Separates the strings into substrings that have only digits and those
    // that have no digits.
    var aParts = a.match(reParts);
    var bParts = b.match(reParts);
    // Used to determine if aPart and bPart are digits.
    var isDigitPart;
    // If `a` and `b` are strings with substring parts that match...
    if (aParts && bParts &&
        (isDigitPart = reDigit.test(aParts[0])) === reDigit.test(bParts[0])) {
        // Loop through each substring part to compare the overall strings.
        var len = Math.min(aParts.length, bParts.length);
        for (var i = 0; i < len; i++) {
            var aPart = aParts[i];
            var bPart = bParts[i];
            // If comparing digits, convert them to numbers (assuming base 10).
            if (isDigitPart) {
                aPart = parseInt(aPart, 10);
                bPart = parseInt(bPart, 10);
            }
            // If the substrings aren't equal, return either -1 or 1.
            if (aPart !== bPart) {
                return aPart < bPart ? -1 : 1;
            }
            // Toggle the value of isDigitPart since the parts will alternate.
            isDigitPart = !isDigitPart;
        }
    }
    // Use normal comparison.
    return (a >= b) - (a <= b);
}
function formatSite(site) {
    if (site.map) {
        return __assign({}, site, { map: __assign({}, site.map, { traps: site.map.traps
                    .map(function (t) { return JSON.parse(t); })
                    .sort(function (a, b) { return sortStringsWithNumbers(a.id, b.id); }) }) });
    }
    return site;
}
var SiteService = /** @class */ (function () {
    function SiteService(apollo) {
        this.apollo = apollo;
    }
    SiteService.prototype.list = function () {
        return this.apollo.query({ query: listQuery }).pipe(map(function (result) { return (result.data.sites.map(formatSite)); }));
    };
    SiteService.prototype.listForCustomer = function (id) {
        return this.apollo.query({ query: listByCustomerQuery, variables: { id: id } }).pipe(map(function (result) { return (result.data.customer.sites.map(formatSite)); }));
    };
    SiteService.prototype.get = function (id, force) {
        if (force === void 0) { force = false; }
        return this.apollo.query({ query: getByIdQuery, variables: { id: id }, fetchPolicy: force ? 'network-only' : null }).pipe(map(function (t) { return formatSite(t.data.site); }));
    };
    SiteService.prototype.create = function (model) {
        if (model.id !== undefined && model.id !== null && model.id !== '') {
            throw new Error('You can only create a new Site');
        }
        return this.apollo.mutate({ mutation: mutation, variables: { site: model } }).pipe(map(function (t) { return t.data.site; }));
    };
    SiteService.prototype.update = function (model) {
        if (!model.id || typeof model.id !== 'string' || model.id === '') {
            throw new Error('You can only update an existing Site');
        }
        return this.apollo.mutate({ mutation: mutation, variables: { site: model } }).pipe(map(function (t) { return t.data.site; }));
    };
    SiteService.prototype.getStatistics = function (id, from, to) {
        return this.apollo.query({
            query: statisticsQuery, variables: {
                id: id,
                from: from.toISOString().split('T')[0],
                to: to.toISOString().split('T')[0]
            }
        }).pipe(map(function (t) { return t.data.site.trapSummaries.map(function (entry) {
            return __assign({}, entry, { date: parse(entry.date) });
        }); }));
    };
    SiteService.prototype.checkForUnopenedReports = function (site) {
        var storedState = getStoredObject('SITE_REPORT_STATES');
        var siteReportState = storedState[site.id];
        var unread = 0;
        for (var i = 0; i < site.reports.length; i++) {
            if (!siteReportState.includes(site.reports[i])) {
                unread++;
            }
        }
        return unread;
    };
    SiteService.prototype.markReportAsRead = function (site, reportId) {
        var storedState = getStoredObject('SITE_REPORT_STATES');
        var siteReportState = storedState[site.id];
        if (!siteReportState.includes(reportId)) {
            storedState[site.id].push(reportId);
        }
        setStoredObject('SITE_REPORT_STATES', storedState);
    };
    SiteService.prototype.updateMap = function (site, mapData) {
        return __awaiter(this, void 0, void 0, function () {
            var mapImage, mapInstance;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!site.id || typeof site.id !== 'string' || site.id === '') {
                            throw new Error('You need to provide a valid site for a map update');
                        }
                        if (!!(site.map && site.map.mapImage && mapData && mapData.mapImage && site.map.mapImage.data === mapData.mapImage.data)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.apollo.mutate({ mutation: mapImageMutation, variables: { mapImage: mapData.mapImage } }).pipe(map(function (t) { return t.data.mapImage; })).toPromise()];
                    case 1:
                        mapImage = _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        mapImage = site.map.mapImage;
                        _a.label = 3;
                    case 3: return [4 /*yield*/, this.apollo.mutate({
                            mutation: mapMutation, variables: {
                                map: __assign({}, mapData, { mapImage: {
                                        id: mapImage.id
                                    }, traps: mapData.traps })
                            }
                        }).pipe(map(function (t) { return t.data.map; })).toPromise()];
                    case 4:
                        mapInstance = _a.sent();
                        return [2 /*return*/, this.apollo.mutate({
                                mutation: mutation, variables: {
                                    site: {
                                        id: site.id,
                                        map: {
                                            id: mapInstance.id
                                        }
                                    }
                                }
                            }).pipe(map(function (t) { return t.data.site; })).toPromise()];
                }
            });
        });
    };
    return SiteService;
}());
export { SiteService };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
