import { Pipe, PipeTransform } from '@angular/core';
import { DocumentType } from '../models/document';

@Pipe({
  name: 'documentType'
})
export class DocumentTypePipe implements PipeTransform {

  transform(value: string): any {
    switch (parseInt(value, 10)) {
      case DocumentType.SafetyInstruction:
        return 'Käyttöturvatiedote';
      case DocumentType.SiteSpecificDocument:
        return 'Kohde asiakirja';
      default:
        return 'Tuntematon';
    }
  }

}
