import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { SiteService } from './site.service';
import { Site } from '../../models/site';

@Injectable()
export class SiteListResolver implements Resolve<Observable<Site[] | Site>> {

  constructor(private service: SiteService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Site[] | Site> {
    return this.service.list();
  }

}
