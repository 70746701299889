import { Component, AfterViewInit, Input, ViewChild, OnChanges } from '@angular/core';
import { Chart, ChartDataSet, ChartOptions } from 'chart.js';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements AfterViewInit, OnChanges {

  @Input() type: 'line' | 'bar' = 'bar';
  @Input() labels;
  @Input() datasets: ChartDataSet[];
  @Input() options: ChartOptions = {
    legend: {
      display: true,
      position: 'bottom'
    },
    scales: {
      xAxes: [{
        display: true
      }],
      yAxes: [{
        display: true,
        ticks: {
          beginAtZero: true
        }
      }],
    }
  };

  @ViewChild('chart') chartEl;
  chart: Chart;

  constructor() { }

  private setupChart() {
    if (this.labels && this.datasets) {
    this.chart = new Chart(this.chartEl.nativeElement, {
      type: this.type,
      data: {
        labels: this.labels,
        datasets: this.datasets
      },
      options: this.options
    });
    }
  }

  ngAfterViewInit(): void {
    this.setupChart();
  }

  ngOnChanges() {
    this.setupChart();
  }

}
