import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Site, TrapSummary } from '../../models/site';
import { SiteService } from '../../services/site/site.service';
import { CustomerService } from '../../services/customer/customer.service';
import { ReportService } from '../../services/report/report.service';
import { Report } from '../../models/report';
import { Customer } from '../../models/customer';
import { MapEditorComponent } from '../../dialogs/map-editor/map-editor.component';
import { MatDialog } from '@angular/material';
import { SessionService } from '../../services/session/session.service';
import { subMonths } from 'date-fns';
import { environment } from '../../../environments/environment';
import { SiteFormDialogComponent } from '../../dialogs/site-form-dialog/site-form-dialog.component';
import { FileService } from '../../services/file/file.service';
import { Document, DocumentType } from '../../models/document';

function labelize(date: Date) {
  return date.toLocaleDateString('fi-FI');
}

function createDataSet(label: string, values: number[], color) {
  return {
    label,
    backgroundColor: color,
    data: values
  };
}

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss']
})
export class SiteComponent implements OnInit {

  @ViewChild('map') mapComponent;

  customer: Customer = {} as Customer;

  site: Site = {} as Site;

  reports: Report[] = [];
  reportColumns: string[] = ['date', 'reason', 'reporter', 'actions'];

  agentSafetyInstructions: Document[] = [];
  otherDocuments: Document[] = [];
  fileColumns: string[] = ['fileName'];

  incompleteReports: Report[] = [];

  baitChart: any = {};
  otherTrapChart: any = {};

  constructor(private route: ActivatedRoute, private service: SiteService, private customerService: CustomerService,
              private reportService: ReportService, private dialog: MatDialog, public session: SessionService,
              private fileService: FileService, private router: Router) {
  }

  ngOnInit() {
    this.customer = this.route.snapshot.data.customer;
    this.setSite(this.route.snapshot.data.site);
    this.setReports(this.route.snapshot.data.reports);
  }

  private setSite(site: Site) {
    this.site = site;
    this.agentSafetyInstructions = this.site.documents.filter(t => t.type === DocumentType.SafetyInstruction);
    this.otherDocuments = this.site.documents.filter(t => t.type !== DocumentType.SafetyInstruction);
  }

  private setReports(reports: Report[]) {
    this.reports = reports.sort((a, b) => Date.parse(b.data.date) - Date.parse(a.data.date));
    const statFromDate = subMonths(new Date(), 24);
    const statToDate = new Date();
    this.service.getStatistics(this.site.id, statFromDate, statToDate).subscribe(entries => {
      entries.sort((a, b) => a.date.valueOf() - b.date.valueOf());
      const labels = [];
      const baitSeries = [];
      const pheromoneSeries = [];
      const lightSeries = [];
      for (const entry of entries) {
        labels.push(labelize(entry.date));
        baitSeries.push(entry.baitCaught);
        pheromoneSeries.push(entry.pheromoneCaught);
        lightSeries.push(entry.lightCaught);
      }

      if (baitSeries.length) {
        this.baitChart.labels = labels;
        this.baitChart.datasets = [
          createDataSet('Syöttiansat', baitSeries, 'blue')
        ];
      }

      if (pheromoneSeries.length || lightSeries.length) {
        this.otherTrapChart.labels = labels;
        this.otherTrapChart.datasets = [
          createDataSet('Feromoniansat', pheromoneSeries, 'green'),
          createDataSet('Valoansat', lightSeries, 'orange')
        ];
      }
    });
  }

  onEditSite() {
    const dialogRef = this.dialog.open(SiteFormDialogComponent, {
      data: this.site,
      position: {
        top: '74px'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.succeeded) {
        this.setSite(result.site);
      }
    });
  }

  onNewMap() {
    const dialogRef = this.dialog.open(MapEditorComponent, {
      width: '700px',
      minHeight: '300px',
      data: {
        site: this.site
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('editor closed', result);
    });
  }

  onMapEdit() {
    const dialogRef = this.dialog.open(MapEditorComponent, {
      width: '700px',
      minHeight: '300px',
      data: {
        mapData: this.site.map,
        site: this.site
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      this.site = { ...this.site, map: result };
      this.mapComponent.updateMap();
    });
  }

  onFileClick(file: Document) {
    window.open(`${environment.apiRoot}/files/${file.id}`, '_blank');
  }

  onReportDelete(event: Event, report: Report) {
    event.stopPropagation();
    if (confirm('Haluatko varmasti poistaa tämän raportin?')) {
      this.reportService.delete(report, this.site.id).subscribe(() => {
        this.reportService.list(this.site.id).subscribe(reports => this.setReports(reports));
      });
    }
  }

  onReportCopy(event: Event, report: Report) {
    event.stopPropagation();
    const {id, ...newReport} = report;
    this.reportService.activeReport = newReport as Report;
    this.router.navigate(['site', this.site.id, 'reports', 'new']);
  }

  onPrintReport(event: Event, report: Report) {
    event.stopPropagation();
    this.router.navigate(['site', this.site.id, 'reports', report.id]).then((succeeded) =>
      succeeded && window.setTimeout(window.print, 100)
    );
  }

  async onDeleteFile(event: Event, file: Document) {
    event.stopPropagation();
    if (confirm('Haluatko varmasti poistaa tämän dokumentin?')) {
      await this.fileService.delete(file.id);
      this.service.get(this.site.id, true).subscribe(site => this.setSite(site));
    }
  }

  async onFilesAdded(event: Event) {
    const file = (<HTMLInputElement> event.srcElement).files[0];
    if (file) {
      await this.fileService.upload({
        description: prompt('Tiedoston kuvaus', ''),
        type: DocumentType.SiteSpecificDocument,
        relationId: this.site.id
      }, file).subscribe(result => {
        console.log(result);
        this.service.get(this.site.id, true).subscribe(site => this.setSite(site));
      }, err => {
        console.error(err);
      });
    }
  }
}
