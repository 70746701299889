<div class="flex-container">
  <app-panel [contentPadding]="0">
    <app-panel-header>Bulletins</app-panel-header>
    <mat-table #latestReportsTable [dataSource]="latestReportsSource" class="mat-table-interactive-rows">
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
      </ng-container>

      <mat-row *matRowDef="let row; columns: reportColumns;" (click)="onLatestReportClick()"></mat-row>
    </mat-table>
  </app-panel>
</div>
<div class="flex-container">
  <app-panel [contentPadding]="0">
    <app-panel-header>Latest reports</app-panel-header>
    <mat-table #latestReportsTable [dataSource]="latestReportsSource" class="mat-table-interactive-rows">
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="reportColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: reportColumns;" (click)="onLatestReportClick()"></mat-row>
    </mat-table>
    <app-panel-footer>
      <mat-paginator [length]="latestReportsSource.length" [pageSize]="5"></mat-paginator>
    </app-panel-footer>
  </app-panel>
  <app-panel [contentPadding]="0">
    <app-panel-header>Latest reports</app-panel-header>
    <mat-table #latestReportsTable [dataSource]="latestReportsSource" class="mat-table-interactive-rows">
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="reportColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: reportColumns;" (click)="onLatestReportClick()"></mat-row>
    </mat-table>
    <app-panel-footer>
      <mat-paginator [length]="latestReportsSource.length" [pageSize]="5"></mat-paginator>
    </app-panel-footer>
  </app-panel>
</div>
