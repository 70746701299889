import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../services/user/user.service';
import { MatDialogRef } from '../../../../node_modules/@angular/material';
function confirmValueFrom(targetControlGetter) {
    return function (confirmControl) { return new Promise(function (resolve) {
        var targetControl = targetControlGetter();
        if (confirmControl.value !== targetControl.value) {
            resolve({ confirmMismatch: true });
        }
        else {
            resolve({});
        }
    }); };
}
var UserFormDialogComponent = /** @class */ (function () {
    function UserFormDialogComponent(formBuilder, userService, dialogRef) {
        this.formBuilder = formBuilder;
        this.userService = userService;
        this.dialogRef = dialogRef;
    }
    Object.defineProperty(UserFormDialogComponent.prototype, "permissions", {
        get: function () {
            return this.userForm.get('permissions');
        },
        enumerable: true,
        configurable: true
    });
    UserFormDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userForm = this.formBuilder.group({
            name: ['', Validators.required],
            username: ['', Validators.required],
            password: ['', Validators.minLength(8)],
            confirmPassword: ['', Validators.required, confirmValueFrom(function () { return _this.userForm.get('password'); })],
            admin: [false, Validators.required],
            permissions: this.formBuilder.array([], Validators.required)
        });
    };
    UserFormDialogComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.userForm.valid) {
            this.userService.create({
                name: this.userForm.value.name,
                username: this.userForm.value.username,
                password: this.userForm.value.password,
                software_role: this.userForm.value.admin ? 'ADMIN' : 'USER'
            }).subscribe(function (user) { return (Promise.all(_this.userForm.value.permissions.map(function (permission) {
                return permission.type === 'site' ?
                    _this.userService.addSitePermission(user.id, permission.targetId).toPromise() :
                    _this.userService.addCustomerPermission(user.id, permission.targetId).toPromise();
            })).then(function () { return _this.dialogRef.close({
                succeeded: true,
                user: user
            }); })); }, function (error) {
                console.log('there was an error sending the query', error);
            });
        }
    };
    UserFormDialogComponent.prototype.onPermissionSelected = function (event) {
        this.permissions.push(this.formBuilder.group({
            type: event.meta,
            targetId: event.value.id,
            displayName: event.displayName,
            write: false
        }));
    };
    UserFormDialogComponent.prototype.removePermissions = function (index) {
        this.permissions.removeAt(index);
    };
    return UserFormDialogComponent;
}());
export { UserFormDialogComponent };
