/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./report.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../forms/report/edit/report-edit.component.ngfactory";
import * as i3 from "../../forms/report/edit/report-edit.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../forms/report/view/report-view.component.ngfactory";
import * as i6 from "../../forms/report/view/report-view.component";
import * as i7 from "../../services/control-agent/control-agent.service";
import * as i8 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/platform";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "@angular/platform-browser/animations";
import * as i13 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i14 from "@angular/material/icon";
import * as i15 from "@angular/common";
import * as i16 from "./report.component";
import * as i17 from "@angular/router";
import * as i18 from "../../services/report/report.service";
import * as i19 from "../../services/session/session.service";
var styles_ReportComponent = [i0.styles];
var RenderType_ReportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportComponent, data: {} });
export { RenderType_ReportComponent as RenderType_ReportComponent };
function View_ReportComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-report-edit", [], null, [[null, "reportChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("reportChange" === en)) {
        var pd_0 = (_co.onReportChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ReportEditComponent_0, i2.RenderType_ReportEditComponent)), i1.ɵdid(1, 114688, null, 0, i3.ReportEditComponent, [i4.FormBuilder, i1.ChangeDetectorRef], { site: [0, "site"], controlAgents: [1, "controlAgents"], report: [2, "report"] }, { reportChange: "reportChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.site; var currVal_1 = _co.controlAgents; var currVal_2 = _co.report; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ReportComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-report-view", [], null, null, null, i5.View_ReportViewComponent_0, i5.RenderType_ReportViewComponent)), i1.ɵdid(1, 114688, null, 0, i6.ReportViewComponent, [i7.ControlAgentService], { site: [0, "site"], controlAgents: [1, "controlAgents"], report: [2, "report"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.site; var currVal_1 = _co.controlAgents; var currVal_2 = _co.report; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ReportComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["color", "primary"], ["mat-mini-fab", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleEdit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.Platform, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i13.View_MatIcon_0, i13.RenderType_MatIcon)), i1.ɵdid(3, 638976, null, 0, i14.MatIcon, [i1.ElementRef, i14.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["edit"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 1, 0, currVal_2); _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 3).inline; _ck(_v, 2, 0, currVal_3); }); }
function View_ReportComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["color", "primary"], ["mat-mini-fab", ""], ["title", "Tulosta"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.print() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.Platform, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i13.View_MatIcon_0, i13.RenderType_MatIcon)), i1.ɵdid(3, 638976, null, 0, i14.MatIcon, [i1.ElementRef, i14.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["print"]))], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 1, 0, currVal_2); _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 3).inline; _ck(_v, 2, 0, currVal_3); }); }
export function View_ReportComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportComponent_1)), i1.ɵdid(2, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportComponent_2)), i1.ɵdid(4, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "controls"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportComponent_3)), i1.ɵdid(7, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportComponent_4)), i1.ɵdid(9, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editing; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.editing; _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.canEdit && !_co.editing) && _co.session.isAdmin); _ck(_v, 7, 0, currVal_2); var currVal_3 = !_co.editing; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_ReportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-report", [], null, null, null, View_ReportComponent_0, RenderType_ReportComponent)), i1.ɵdid(1, 114688, null, 0, i16.ReportComponent, [i17.ActivatedRoute, i18.ReportService, i17.Router, i19.SessionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReportComponentNgFactory = i1.ɵccf("app-report", i16.ReportComponent, View_ReportComponent_Host_0, {}, {}, []);
export { ReportComponentNgFactory as ReportComponentNgFactory };
