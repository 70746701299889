/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./panel-footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./panel-footer.component";
var styles_PanelFooterComponent = [i0.styles];
var RenderType_PanelFooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PanelFooterComponent, data: {} });
export { RenderType_PanelFooterComponent as RenderType_PanelFooterComponent };
export function View_PanelFooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_PanelFooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-panel-footer", [], null, null, null, View_PanelFooterComponent_0, RenderType_PanelFooterComponent)), i1.ɵdid(1, 114688, null, 0, i2.PanelFooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PanelFooterComponentNgFactory = i1.ɵccf("app-panel-footer", i2.PanelFooterComponent, View_PanelFooterComponent_Host_0, {}, {}, ["*"]);
export { PanelFooterComponentNgFactory as PanelFooterComponentNgFactory };
