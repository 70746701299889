import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import gql from 'graphql-tag';
import { Document } from '../../models/document';
import { Apollo } from 'apollo-angular';

const listQuery = gql`
query listDocuments {
  documents {
    id
    fileName
    description
    type
    mimeType
    relationId
  }
}
`;

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private http: HttpClient, private apollo: Apollo) {
  }

  list(): Observable<Document[]> {
    return this.apollo.query({ query: listQuery }).pipe(
      map(result => <Document[]>(<any>result.data).documents)
    );
  }

  download(id: number) {
    return this.http.get(`${environment.apiRoot}/files/${id}`, {
      reportProgress: true,
      withCredentials: true,
      responseType: 'blob'
    });
  }

  upload(metadata: any, file: File) {
    const formData = new FormData();

    for (const key in metadata) {
      if (metadata.hasOwnProperty(key)) {
        formData.append(key, metadata[key]);
      }
    }
    formData.append('data', file);

    const uploadRequest = new HttpRequest('POST', `${environment.apiRoot}/files`, formData, <any>{
      reportProgress: 'true',
      responseType: 'json',
      withCredentials: true
    });

    return this.http.request(uploadRequest);
  }

  async delete(id: string): Promise<Object> {
    return this.http.delete(`${environment.apiRoot}/files/${id}`, {withCredentials: true}).toPromise();
  }
}
