<app-navigation *ngIf="session.isAuthenticated">
  <img src="assets/logo.png" class="brand" [routerLink]="frontPageUri">
  <!--<a routerLink="/dashboard" routerLinkActive="active">Dashboard</a>-->
  <a routerLink="/clients" routerLinkActive="active" *ngIf="session?.profile?.software_role === 'ADMIN'">Asiakkaat</a>
  <a routerLink="/sites" routerLinkActive="active" *ngIf="!singleSite">Kohteet</a>
  <a routerLink="/documents" routerLinkActive="active" *ngIf="session?.profile?.software_role === 'ADMIN'">Dokumentit</a>
  <a routerLink="/agents" routerLinkActive="active" *ngIf="session?.profile?.software_role === 'ADMIN'">Torjunta-aineet</a>
  <a routerLink="/users" routerLinkActive="active" *ngIf="session?.profile?.software_role === 'ADMIN'">Käyttäjähallinta</a>
  <span class="fill"></span>
  <!--<app-search></app-search>-->
  <button mat-button [matMenuTriggerFor]="userMenu">
    <mat-icon>account_circle</mat-icon>
    <span>{{ session.profile?.name }}</span>
  </button>
  <mat-menu #userMenu="matMenu">
    <button mat-menu-item (click)="onChangePassword()">
      Vaihda salasanaa
    </button>
    <button mat-menu-item (click)="session.logout()">
      Kirjaudu ulos
      <mat-icon>exit_to_app</mat-icon>
    </button>
  </mat-menu>
</app-navigation>
<section [ngClass]="session.isAuthenticated ? 'loggedIn' : ''">
  <div id="background"></div>
  <router-outlet></router-outlet>
</section>
<footer>
  <small class="block">Copyright © 2018 - 2019 Pohjolan Desinfiointi Oy | All rights reserved</small>
</footer>
