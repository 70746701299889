import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '../../models/user';
import { MatDialog } from '@angular/material';
import { UserFormDialogComponent } from '../../dialogs/user-form-dialog/user-form-dialog.component';
import { UserService } from '../../services/user/user.service';
import { NewPasswordDialogComponent } from '../../dialogs/new-password-dialog/new-password-dialog.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  displayedColumns = ['username', 'name', 'admin', 'actions'];
  users: User[];

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.users = this.route.snapshot.data.users;
    this.userService.list().subscribe(users => this.users = users);
  }

  onAddUser() {
    const dialogRef = this.dialog.open(UserFormDialogComponent, {
      width: '350px',
      position: {
        top: '74px'
      }
    });
  }

  onDelete(user: User) {
    if (confirm('Haluatko varmasti poistaa tämän käyttäjän?')) {
      this.userService.delete(user).subscribe();
    }
  }

  onChangePassword(user: User) {
    this.dialog.open(NewPasswordDialogComponent, {
      data: user.id
    });
  }
}
