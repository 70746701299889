import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { UserService } from './user.service';
import { User } from '../../models/user';

@Injectable()
export class UserListResolver implements Resolve<Promise<User[]>> {

  constructor(private service: UserService) { }

  resolve(route: ActivatedRouteSnapshot): Promise<User[]> {
    return new Promise((resolve) => {
      this.service.list().subscribe(resolve);
    });
  }

}
