import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ControlAgent } from '../../models/control-agent';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

const findByNameQuery = gql`
query listControlAgents($name: String) {
  agents(displayName: $name) {
    id
    agent
    displayName
    maxConcentration
    unit
    isSpray
  }
}
`;

const findByIdQuery = gql`
query FindControlAgentById($id: ID) {
  agent(id: $id) {
    id
    agent
    displayName
    maxConcentration
    unit
    isSpray
  }
}
`;

const mutationQuery = gql`
mutation agentMutation($controlAgent: iagent!) {
  agent(agent: $controlAgent) {
    id
    agent
    displayName
    maxConcentration
    unit
    isSpray
  }
}
`;

@Injectable()
export class ControlAgentService {

  constructor(private apollo: Apollo) {
  }

  list(): Observable<ControlAgent[]> {
    return this.apollo.query({ query: findByNameQuery }).pipe(
      map(result => <ControlAgent[]>(<any>result.data).agents)
    );
  }

  find(name: string): Observable<ControlAgent[]> {
    return this.apollo.query({ query: findByNameQuery, variables: { name } }).pipe(
      map(result => <ControlAgent[]>(<any>result.data).agents)
    );
  }

  get(id: string): Observable<ControlAgent> {
    return this.apollo.query({ query: findByIdQuery, variables: { id } }).pipe(
      map(t => <ControlAgent>(<any>t.data).agent)
    );
  }

  create(model: ControlAgent): Observable<ControlAgent> {
    console.log(model);
    if (model.id !== undefined && model.id !== null && model.id !== '') {
      throw new Error('You can only create a new Control Agent');
    }

    return this.apollo.mutate({ mutation: mutationQuery, variables: { controlAgent: model } }).pipe(
      map(t => <ControlAgent>(<any>t.data).agent)
    );
  }

  update(model: ControlAgent): Observable<ControlAgent> {
    if (!model.id || typeof model.id !== 'string' || model.id === '') {
      throw new Error('You can only update an existing Control Agent');
    }

    return this.apollo.mutate({ mutation: mutationQuery, variables: { controlAgent: model } }).pipe(
      map(t => <ControlAgent>(<any>t.data).agent)
    );
  }

}
