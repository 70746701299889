import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { BehaviorSubject, Observable } from 'rxjs';

import { FileService } from '../../services/file/file.service';
import { Document } from '../../models/document';
import { UploadFileDialogComponent } from '../../dialogs/upload-file-dialog/upload-file-dialog.component';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  documents: BehaviorSubject<Document[]> = new BehaviorSubject<Document[]>([]);
  displayedColumns = ['id', 'fileName', 'mimeType', 'type', 'actions'];

  constructor(private service: FileService, private dialog: MatDialog) { }

  ngOnInit() {
    this.service.list().subscribe(t => this.documents.next(t));
  }

  onAddFile() {
    const dialogRef = this.dialog.open(UploadFileDialogComponent, {
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.documents.next(this.documents.getValue().concat(result.body));
      }
    });
  }

  async onDelete(document: Document) {
    if (confirm('Haluatko varmasti poistaa tämän dokumentin?')) {
      await this.service.delete(document.id);
      this.documents.next(this.documents.getValue().filter(t => t.id !== document.id));
    }
  }
}
