import { Component, OnInit } from '@angular/core';

export interface Report {
  id: number;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  reportColumns: string[] = ['id'];

  latestReportsSource: Report[] = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 }
  ];

  constructor() { }

  ngOnInit() {
  }

  onLatestReportClick() {
  }

}
