<div class="container">
  <app-panel [contentPadding]="'15px'">
    <div class="report-grid">
      <div class="section-header full-width">
        Torjuntatodistus - Bekämpningsintyg
      </div>
      <section class="left">
        <div class="site-info">
          <div class="input-container">
            <label>Asiakas - Kund</label>
            <input [value]="site.name" disabled>
          </div>
          <div class="input-container">
            <label>Osoite - Adress</label>
            <input [value]="site.address" disabled>
          </div>
          <div class="input-container">
            <label>Postiosoite - Postadress</label>
            <input [value]="site.zipCode" disabled>
          </div>
        </div>
        <div class="bait-trap-info">
          <h1 class="container-header">Syöttiansat - Betesfällor</h1>
          <table>
            <thead>
            <tr>
              <th>Tunniste</th>
              <th>Syönti</th>
              <th>Tuholainen</th>
              <th>Torjunta-aine</th>
              <th>Määrä</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let baitTrapReport of report.data.baitTrapReports">
              <td>{{ baitTrapReport.id || baitTrapReport.location }}</td>
              <td>{{ baitTrapReport.caught }} %</td>
              <td>{{ baitTrapReport.pests }}</td>
              <td>{{ baitTrapReport.agentId | controlAgent:'name' | async }}</td>
              <td>{{ baitTrapReport.agentAmount | controlAgent:'amount':baitTrapReport.agentId | async }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section class="right">
        <div class="control-info">
          <div class="input-container">
            <label>Torjunta-alue - Bekämpningsområde</label>
            <input [value]="report.data.pestControlZone" disabled>
          </div>
          <div class="input-container">
            <label>Torjuntakohde - Bekämpningsobjekt</label>
            <input [value]="report.data.pestControlReason" disabled>
          </div>
          <div class="input-container">
            <label>Päivämäärä</label>
            <input value="{{ report.data.date | date:'dd-MM-yyyy' }}" disabled>
          </div>
          <div class="input-container">
            <label>Suorittaja</label>
            <input [value]="report.data.operator" disabled>
          </div>
        </div>
        <div class="spray-trap-info">
          <h1 class="container-header">Ruiskuteet - Sprayprodukter</h1>
          <table>
            <thead>
            <tr>
              <th>Riskitorjunta-alue</th>
              <th>Torjunta-aine</th>
              <th>Määrä</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let sprayTrapReport of report.data.sprayTrapReports">
              <td>{{ sprayTrapReport.zoneName }}</td>
              <td>{{ sprayTrapReport.agentId | controlAgent:'name' | async }}</td>
              <td>{{ sprayTrapReport.agentAmount | controlAgent: 'amount':sprayTrapReport.agentId | async }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="pheromone-trap-info">
          <h1 class="container-header">Feromoniansat - Feromonfällor</h1>
          <table>
            <thead>
            <tr>
              <th>Tunniste</th>
              <th>Määrä</th>
              <th>Vaihdettu</th>
              <th>Havainnot</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let pheromoneTrapReport of report.data.pheromoneTrapReports">
              <td>{{ pheromoneTrapReport.id || pheromoneTrapReport.location }}</td>
              <td>{{ pheromoneTrapReport.caught }} kpl</td>
              <td>{{ pheromoneTrapReport.changed ? 'kyllä' : 'ei' }}</td>
              <td>{{ pheromoneTrapReport.observations }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="light-trap-info">
          <h1 class="container-header">Valoansat - Ljus fällor</h1>
          <table>
            <thead>
            <tr>
              <th>Tunniste</th>
              <th>Määrä</th>
              <th>Paperi vaihdettu</th>
              <th>Lamppu uusittu</th>
              <th>Havainnot</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let lightTrapReport of report.data.lightTrapReports">
              <td>{{ lightTrapReport.id || lightTrapReport.location }}</td>
              <td>{{ lightTrapReport.caught }} kpl</td>
              <td>{{ lightTrapReport.paperChanged ? 'kyllä' : 'ei' }}</td>
              <td>{{ lightTrapReport.lightChanged ? 'kyllä' : 'ei' }}</td>
              <td>{{ lightTrapReport.observations }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="control-agent-info">
          <h1 class="container-header">Torjunta-aineet - Bekämpningsmedel</h1>
          <table>
            <thead>
            <tr>
              <th>Nimi</th>
              <th>Myrkky</th>
              <th>Max %</th>
              <th>Määrä</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let entry of usedControlAgents">
              <td>{{ entry.displayName }}</td>
              <td>{{ entry.agent }}</td>
              <td>{{ entry.maxConcentration }}</td>
              <td>{{ entry.amount }} {{ entry.unit }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  </app-panel>

  <app-panel [contentPadding]="'15px'" *ngIf="report.data.pestControlReview">
    <div class="report-grid">
      <div class="section-header full-width">
        Tuholaiskatselmus
      </div>
      <div class="full-width review">
        {{ report.data.pestControlReview }}
      </div>
    </div>
  </app-panel>
</div>
