/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./map-legend.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./map-legend.component";
var styles_MapLegendComponent = [i0.styles];
var RenderType_MapLegendComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MapLegendComponent, data: {} });
export { RenderType_MapLegendComponent as RenderType_MapLegendComponent };
export function View_MapLegendComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "li", [["class", "indoor-bait-trap"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Sis\u00E4sy\u00F6tti-ansa "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "li", [["class", "outdoor-bait-trap"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Ulkosy\u00F6tti-ansa "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "li", [["class", "pheromone-trap"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Feromoni-ansa "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "li", [["class", "light-trap"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Valoansa "]))], null, null); }
export function View_MapLegendComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-map-legend", [], null, null, null, View_MapLegendComponent_0, RenderType_MapLegendComponent)), i1.ɵdid(1, 114688, null, 0, i2.MapLegendComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapLegendComponentNgFactory = i1.ɵccf("app-map-legend", i2.MapLegendComponent, View_MapLegendComponent_Host_0, {}, {}, []);
export { MapLegendComponentNgFactory as MapLegendComponentNgFactory };
