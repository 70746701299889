<app-map #map
         [editable]="true"
         [mapData]="mapData"
         (mapDataChange)="onMapDataChange($event)"
         (contextMenu)="onContextMenu($event)"
         (trapUpdated)="onTrapChange($event)"
         (trapClicked)="onTrapClick($event)"></app-map>
<div class="controls">
  <button mat-raised-button color="primary" (click)="onUpdateMap()">
    <mat-icon>save</mat-icon>
    Tallenna
  </button>
</div>
<div *ngIf="contextMenuOpen" class="context-menu" [ngStyle]="{'left.px': contextMenu.position.x, 'top.px': contextMenu.position.y}">
  <mat-list>
    <mat-list-item (click)="onAddTrap(0)">Lisää sisäsyötti-ansa</mat-list-item>
    <mat-list-item (click)="onAddTrap(1)">Lisää ulkosyötti-ansa</mat-list-item>
    <mat-list-item (click)="onAddTrap(2)">Lisää feromoni-ansa</mat-list-item>
    <mat-list-item (click)="onAddTrap(3)">Lisää valoansa</mat-list-item>
  </mat-list>
</div>
