<div class="flex-container">
  <app-list [contentPadding]="0">
    <app-list-item *ngFor="let controlAgent of controlAgents">
      <app-control-agent-form [agent]="controlAgent" [editing]="!controlAgent.id" #controlAgentRow></app-control-agent-form>
    </app-list-item>
    <app-list-item *ngIf="controlAgents?.length === 0">
      Et ole vielä syöttänyt yhtään torjunta-ainetta.
    </app-list-item>
    <app-list-item class="create-form">
      <mat-form-field>
        <input matInput placeholder="Tuotenimi" (input)="addControlAgentRow('displayName', $event.target, $event.data)">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Vaikuttava-aine" (input)="addControlAgentRow('agent', $event.target, $event.data)">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Max %" (input)="addControlAgentRow('maxConcentration', $event.target, $event.data)">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Mittayksikkö (esim. g)" (input)="addControlAgentRow('unit', $event.target, $event.data)">
      </mat-form-field>
      <mat-checkbox>Ruiskutettava</mat-checkbox>
    </app-list-item>
  </app-list>
</div>
