import { ErrorHandler } from '@angular/core';
import { SessionService } from './services/session/session.service';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular-link-http';
import { environment } from '../environments/environment';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { concat } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { SiteService } from './services/site/site.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NewPasswordDialogComponent } from './dialogs/new-password-dialog/new-password-dialog.component';
import { MatDialog } from '../../node_modules/@angular/material';
var AppComponent = /** @class */ (function () {
    function AppComponent(session, apollo, httpLink, siteService, route, router, dialog, errorHandler) {
        var _this = this;
        this.session = session;
        this.apollo = apollo;
        this.httpLink = httpLink;
        this.siteService = siteService;
        this.route = route;
        this.router = router;
        this.dialog = dialog;
        this.errorHandler = errorHandler;
        this.singleSite = false;
        this.frontPageUri = '/';
        apollo.create({
            link: concat(onError(function (_a) {
                var graphQLErrors = _a.graphQLErrors, networkError = _a.networkError, operation = _a.operation;
                if (graphQLErrors) {
                    graphQLErrors.map(function (_a) {
                        var message = _a.message, locations = _a.locations, path = _a.path;
                        return _this.errorHandler.handleError(new Error("[GraphQL error]: Operation " + operation.operationName + ", Message: " + message + ", Location: " + JSON.stringify(locations) + ", Path: " + JSON.stringify(path)));
                    });
                }
                if (networkError) {
                    _this.errorHandler.handleError(new Error("[Network error]: Operation " + operation.operationName + ", " + networkError.message));
                }
            }), httpLink.create({
                uri: environment.apiRoot + environment.graphqlUri,
                withCredentials: true
            })),
            cache: new InMemoryCache({
                dataIdFromObject: function (object) { return object.id || null; },
                addTypename: false
            })
        });
        if (session.isAuthenticated) {
            this.siteService.list().subscribe(function (sites) {
                if (sites.length === 1 && !session.isAdmin) {
                    _this.singleSite = true;
                    _this.frontPageUri = '/site/' + sites[0].id;
                    _this.router.navigate(['/site', sites[0].id]);
                }
            });
        }
    }
    AppComponent.prototype.onChangePassword = function () {
        this.dialog.open(NewPasswordDialogComponent, {
            data: this.session.profile.id
        });
    };
    return AppComponent;
}());
export { AppComponent };
