<ul>
  <li class="indoor-bait-trap">
    Sisäsyötti-ansa
  </li>
  <li class="outdoor-bait-trap">
    Ulkosyötti-ansa
  </li>
  <li class="pheromone-trap">
    Feromoni-ansa
  </li>
  <li class="light-trap">
    Valoansa
  </li>
</ul>
