/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@asymmetrik/ngx-leaflet/dist/leaflet/core/leaflet.directive";
import * as i4 from "@asymmetrik/ngx-leaflet/dist/leaflet/layers/leaflet-layers.directive";
import * as i5 from "./map.component";
import * as i6 from "@angular/material/dialog";
var styles_MapComponent = [i0.styles];
var RenderType_MapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MapComponent, data: {} });
export { RenderType_MapComponent as RenderType_MapComponent };
function View_MapComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["leaflet", ""], ["style", "height: 300px"]], null, [[null, "leafletMapReady"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("leafletMapReady" === en)) {
        var pd_1 = (_co.onMapReady($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "dragging": 0 }), i1.ɵdid(3, 606208, null, 0, i3.LeafletDirective, [i1.ElementRef, i1.NgZone], { options: [0, "options"], center: [1, "center"] }, { mapReady: "leafletMapReady" }), i1.ɵdid(4, 475136, null, 0, i4.LeafletLayersDirective, [i3.LeafletDirective, i1.IterableDiffers, i1.NgZone], { layers: [0, "layers"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.isDragging); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.mapOptions; var currVal_2 = _co.mapCenter; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = _co.mapLayers; _ck(_v, 4, 0, currVal_3); }, null); }
function View_MapComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Lis\u00E4\u00E4 karttakuva pudottamalla kuva t\u00E4h\u00E4n."]))], null, null); }
function View_MapComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["newMapBanner", 1]], null, 4, "div", [["class", "empty"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Kohteelle ei ole viel\u00E4 lis\u00E4tty karttakuvaa!"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editable; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_MapComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { newMapBannerEl: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasMapImage; _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.hasMap && !_co.hasMapImage) || (_co.editable && !_co.hasMapImage)); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_MapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-map", [], null, null, null, View_MapComponent_0, RenderType_MapComponent)), i1.ɵdid(1, 4833280, null, 0, i5.MapComponent, [i6.MatDialog, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapComponentNgFactory = i1.ɵccf("app-map", i5.MapComponent, View_MapComponent_Host_0, { editable: "editable", mapData: "mapData" }, { mapDataChange: "mapDataChange", contextMenu: "contextMenu", trapClicked: "trapClicked", trapUpdated: "trapUpdated" }, []);
export { MapComponentNgFactory as MapComponentNgFactory };
