import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  errorCode = 404;
  errorMessage: string;
  currentUrl: string;
  detailsVisible: boolean;

  get errorDetails(): string {
    let details = 'Status Code: ' + this.errorCode;
    if (this.errorMessage) {
      details += '\nStatus Message: ' + this.errorMessage;
    }
    details += '\nCurrent URL: ' + this.currentUrl;

    return details;
  }

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.currentUrl = this.router.url.split(';')[0];
    this.route.params.subscribe(params => {
      if (params['type']) {
        switch (params['type']) {
          case 'forbidden':
            this.errorCode = 403;
            this.errorMessage = 'Forbidden';
            break;
          default:
            break;
        }
      }
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = this.router.url.split(';')[0];
      }
    });
  }

}
