import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TrapType, Trap } from '../../models/trap';
import { Point } from '../../models/geometry';
import { ControlAgentService } from '../../services/control-agent/control-agent.service';
import { ControlAgent } from '../../models/control-agent';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-create-trap-dialog',
  templateUrl: './create-trap-dialog.component.html',
  styleUrls: ['./create-trap-dialog.component.scss']
})
export class CreateTrapDialogComponent implements OnInit {

  isNotNew: boolean;
  form: FormGroup;
  agents: Observable<ControlAgent[]>;

  get type(): TrapType {
    return this.form.get('type').value;
  }

  constructor(private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: Trap,
              private dialogRef: MatDialogRef<CreateTrapDialogComponent>, private agentService: ControlAgentService) {
    if (data.id) {
      this.isNotNew = true;
    }
  }

  ngOnInit() {
    const usesAgent = this.data && this.data.agentId && this.data.agentId < 2;
    this.form = this.formBuilder.group({
      id: [this.data.id || null, Validators.required],
      comment: [this.data.comment],
      agentId: [this.data.agentId || null, usesAgent ? Validators.required : undefined],
      type: [this.data.type],
      location: [this.data.location, Validators.required]
    });
    this.agents = this.agentService.list().pipe(
      map(entries => entries.filter(t => !t.isSpray)),
      tap(entries => {
        if (usesAgent && !this.data || !this.data.agentId) {
          this.form.get('agentId').setValue(entries[0].id);
        }
      })
    );
  }

  onSubmit() {
    if (this.form.valid) {
      this.dialogRef.close({
        type: this.data ? 'update' : 'delete',
        trap: this.form.value
      });
    }
  }

  onDelete() {
    if (confirm('Haluatko varmasti poistaa tämän ansan?')) {
      this.dialogRef.close({
        type: 'delete',
        trap: this.form.value
      });
    }
  }

}
