<h1 mat-dialog-title>Lisää uusi kohde asiakkuuteen {{ data.customer.name }}</h1>
<mat-dialog-content>
  <form [formGroup]="siteForm" novalidate (ngSubmit)="onSubmit()">
    <mat-form-field>
      <input matInput type="text" formControlName="name" placeholder="Kohteen nimi">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" formControlName="address" placeholder="Kohteen osoite">
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" formControlName="zipCode" placeholder="Kohteen postinumero">
    </mat-form-field>
    <div class="controls">
      <button mat-raised-button color="primary" type="submit">
        <mat-icon>save</mat-icon>
        Tallenna
      </button>
    </div>
  </form>
</mat-dialog-content>
