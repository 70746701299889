
    <form [formGroup]="passwordForm" novalidate (ngSubmit)="onSubmit()">
      <mat-form-field>
        <input matInput type="password" placeholder="Uusi salasana" formControlName="password">
      </mat-form-field>
      <mat-form-field>
        <input matInput type="password" placeholder="Vahvista uusi salasana" formControlName="confirmPassword">
      </mat-form-field>
      <button mat-raised-button color="primary">
        Vaihda salasana
      </button>
    </form>
  