import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { ApolloModule } from 'apollo-angular';
import { HttpLinkModule } from 'apollo-angular-link-http';

import {
  NgrxCacheModule,
  NgrxCache,
  apolloReducer,
} from 'apollo-angular-cache-ngrx';

import {
  MatAutocompleteModule,
  MatFormFieldModule,
  MatInputModule,
  MatOptionModule,
  MatGridListModule,
  MatToolbarModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatIconModule,
  MatTableModule,
  MatPaginatorModule,
  MatCardModule,
  MatListModule,
  MatSelectModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MAT_DATE_LOCALE, MatChipsModule, MatMenuModule, MatDialogModule, MatProgressBarModule
} from '@angular/material';

import { RavenErrorHandler } from './raven';

import { AppRoutingModule } from './app-routing.module';

import { PanelModule } from './elements/panel/panel.module';

import { CustomerService } from './services/customer/customer.service';

import { SiteService } from './services/site/site.service';
import { SiteListResolver } from './services/site/site-list.resolver';
import { SiteResolver } from './services/site/site.resolver';

import { ReportService } from './services/report/report.service';
import { ReportListResolver } from './services/report/report-list.resolver';
import { ReportResolver } from './services/report/report.resolver';
import { ControlAgentService } from './services/control-agent/control-agent.service';
import { ControlAgentListResolver } from './services/control-agent/control-agent-list.resolver';

import { AppComponent } from './app.component';

import { SearchComponent } from './elements/search/search.component';
import { NavigationComponent } from './elements/navigation/navigation.component';

import { ErrorComponent } from './pages/error/error.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SiteComponent } from './pages/site/site.component';
import { SiteListComponent } from './pages/site-list/site-list.component';
import { ListComponent } from './elements/list/list.component';
import { ListItemComponent } from './elements/list/list-item/list-item.component';
import { ReportComponent } from './pages/report/report.component';
import { ReportEditComponent } from './forms/report/edit/report-edit.component';
import { ReportViewComponent } from './forms/report/view/report-view.component';
import { ControlAgentPipe } from './pipes/control-agent.pipe';
import { ChartComponent } from './elements/chart/chart.component';
import { ToolbarComponent } from './elements/toolbar/toolbar.component';
import { CustomerListComponent } from './pages/customer-list/customer-list.component';
import { TitleComponent } from './elements/title/title.component';
import { LoginComponent } from './pages/login/login.component';
import { SessionService } from './services/session/session.service';
import { SessionGuard } from './services/session/session.guard';
import { SessionInterceptor } from './services/session/session.interceptor';
import { AutofocusDirective } from './directives/autofocus.directive';
import { MapComponent } from './elements/map/map.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

import 'rxjs/add/operator/map';
import { ControlAgentComponent } from './pages/control-agent/control-agent.component';
import { ControlAgentFormComponent } from './forms/control-agent/control-agent-form.component';
import { CustomerFormDialogComponent } from './dialogs/customer-form-dialog/customer-form-dialog.component';
import { SiteFormDialogComponent } from './dialogs/site-form-dialog/site-form-dialog.component';
import { MapEditorComponent } from './dialogs/map-editor/map-editor.component';
import { CreateTrapDialogComponent } from './dialogs/create-trap-dialog/create-trap-dialog.component';
import { FilterPipe } from './pipes/filter.pipe';
import { PanelSplitHeaderComponent } from './elements/panel/panel-split-header/panel-split-header.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { UploadFileDialogComponent } from './dialogs/upload-file-dialog/upload-file-dialog.component';
import { DocumentTypePipe } from './pipes/document-type.pipe';
import { UserListComponent } from './pages/user-list/user-list.component';
import { UserService } from './services/user/user.service';
import { UserListResolver } from './services/user/user-list.resolver';
import { UserFormDialogComponent } from './dialogs/user-form-dialog/user-form-dialog.component';
import { MapLegendComponent } from './elements/map-legend/map-legend.component';
import { NewPasswordDialogComponent } from './dialogs/new-password-dialog/new-password-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    DashboardComponent,
    NavigationComponent,
    ErrorComponent,
    SiteComponent,
    SiteListComponent,
    ListComponent,
    ListItemComponent,
    ReportComponent,
    ReportEditComponent,
    ReportViewComponent,
    ControlAgentPipe,
    ChartComponent,
    ToolbarComponent,
    CustomerListComponent,
    TitleComponent,
    LoginComponent,
    MapComponent,
    AutofocusDirective,
    ControlAgentComponent,
    ControlAgentFormComponent,
    CustomerFormDialogComponent,
    SiteFormDialogComponent,
    MapEditorComponent,
    CreateTrapDialogComponent,
    FilterPipe,
    PanelSplitHeaderComponent,
    DocumentsComponent,
    UploadFileDialogComponent,
    DocumentTypePipe,
    UserListComponent,
    UserFormDialogComponent,
    MapLegendComponent,
    NewPasswordDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ApolloModule,
    HttpLinkModule,

    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatGridListModule,
    MatToolbarModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    MatListModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatMenuModule,
    MatDialogModule,
    MatProgressBarModule,

    PanelModule,
    LeafletModule.forRoot(),

    AppRoutingModule,
    // ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    SessionService, SessionGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true
    },
    ControlAgentService, ControlAgentListResolver, ControlAgentPipe,
    CustomerService,
    SiteService, SiteListResolver, SiteResolver,
    ReportService, ReportListResolver, ReportResolver,
    UserService, UserListResolver,
    {provide: MAT_DATE_LOCALE, useValue: 'fi-FI'},
    AutofocusDirective,
    FilterPipe,
    { provide: ErrorHandler, useClass: RavenErrorHandler }
  ],
  entryComponents: [
    CustomerFormDialogComponent,
    SiteFormDialogComponent,
    MapEditorComponent,
    CreateTrapDialogComponent,
    UploadFileDialogComponent,
    UserFormDialogComponent,
    NewPasswordDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
