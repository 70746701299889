import { Area, Point } from './geometry';

export enum TrapType {
  baitIndoor,
  baitOutdoor,
  pheromone,
  light,
  spray
}

export interface Trap {
  readonly id: string;
  readonly type: TrapType;
  readonly agentId?: number;
  readonly location?: Point;
  readonly comment?: string;
  readonly data?: any;
}
