import { CustomerService } from './customer.service';
import * as i0 from "@angular/core";
import * as i1 from "./customer.service";
var CustomerListResolver = /** @class */ (function () {
    function CustomerListResolver(service) {
        this.service = service;
    }
    CustomerListResolver.prototype.resolve = function (route) {
        return this.service.list();
    };
    CustomerListResolver.ngInjectableDef = i0.defineInjectable({ factory: function CustomerListResolver_Factory() { return new CustomerListResolver(i0.inject(i1.CustomerService)); }, token: CustomerListResolver, providedIn: "root" });
    return CustomerListResolver;
}());
export { CustomerListResolver };
