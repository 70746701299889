<form [formGroup]="form" novalidate (ngSubmit)="onSubmit()" class="container" [ngClass]="{'dragging': dragging}" #container>
  <div class="form-field">
    <span>Tiedosto</span>
    <button mat-raised-button (click)="onSelectFile()" [color]="file ? 'primary' : 'accent'" type="button">
      {{ !file ? 'Valitse tiedosto' : form.get('file').value }}
    </button>
    <input type="file" class="hidden" #fileInput (change)="onFileChange($event)">
  </div>
  <mat-form-field>
    <input matInput placeholder="Kuvaus" formControlName="description">
  </mat-form-field>
  <mat-form-field>
    <mat-select placeholder="Tyyppi" formControlName="type" (valueChange)="updateRelationOptions($event)">
      <mat-option [value]="0">Käyttöturvatiedote</mat-option>
      <mat-option [value]="2">Kohteen Dokumentti</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <input matInput formControlName="relation" [matAutocomplete]="auto" placeholder="Relaatio">
  </mat-form-field>

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="relationDisplayWithFn">
    <mat-option *ngFor="let option of filteredRelationOptions | async" [value]="option">
      {{ option.label }}
    </mat-option>
  </mat-autocomplete>

  <div class="controls">
    <button type="submit" mat-raised-button color="primary">
      <mat-icon>save</mat-icon>
      Tallenna
    </button>
  </div>
</form>
