import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { CustomerService } from './customer.service';
import { Customer } from '../../models/customer';

@Injectable({
  providedIn: 'root'
})
export class CustomerListResolver implements Resolve<Observable<Customer[]>> {

  constructor(private service: CustomerService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Customer[]> {
    return this.service.list();
  }

}
