import { Injectable } from '@angular/core';
import { Customer } from '../../models/customer';

import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CustomerListQuery } from './__generated__/CustomerListQuery';
import { FindCustomersByName, FindCustomersByNameVariables } from './__generated__/FindCustomersByName';
import { FindCustomerById, FindCustomerByIdVariables } from './__generated__/FindCustomerById';
import { MutateCustomer, MutateCustomerVariables } from './__generated__/MutateCustomer';

const customerListQuery = gql`
query CustomerListQuery {
  customers {
    id
    name
    sites {
      id
    }
    users {
      id
    }
  }
}
`;

const customerByNameQuery = gql`
query FindCustomersByName($name: String) {
  customers(name: $name) {
    id
    name
    sites {
      id
    }
    users {
      id
    }
  }
}
`;

const customerByIdQuery = gql`
query FindCustomerById($id: ID) {
  customer(id: $id) {
    id
    name
    sites {
      id
    }
    users {
      id
    }
  }
}
`;

const customerMutation = gql`
mutation MutateCustomer($customer: icustomer!) {
  customer(customer: $customer) {
    id
    name
    sites {
      id
    }
    users {
      id
    }
  }
}
`;

@Injectable()
export class CustomerService {

  constructor(private apollo: Apollo) {
  }

  list(): Observable<Customer[]> {
    return this.apollo.query<CustomerListQuery>({ query: customerListQuery }).pipe(
      map(result => result.data.customers)
    );
  }

  find(name: string): Observable<Customer[]> {
    return this.apollo.query<FindCustomersByName, FindCustomersByNameVariables>({ query: customerByNameQuery, variables: { name } }).pipe(
      map(result => result.data.customers)
    );
  }

  get(id: number): Observable<Customer> {
    return this.apollo.query<FindCustomerById, FindCustomerByIdVariables>({
      query: customerByIdQuery,
      variables: { id: id.toString() }
    }).pipe(
      map(t => t.data.customer)
    );
  }

  create(model: Customer): Observable<Customer> {
    if (model.id !== undefined && model.id !== null && model.id !== '') {
      throw new Error('You can only create a new Customer');
    }

    const variables: MutateCustomerVariables = { customer: model };

    return this.apollo.mutate<MutateCustomer>({ mutation: customerMutation, variables }).pipe(
      map(t => t.data.customer)
    );
  }

  update(model: Customer): Observable<Customer> {
    if (!model.id || typeof model.id !== 'string' || model.id === '') {
      throw new Error('You can only update an existing Customer');
    }

    const variables: MutateCustomerVariables = { customer: model };

    return this.apollo.mutate({ mutation: customerMutation, variables }).pipe(
      map(t => t.data.customer)
    );
  }

}
