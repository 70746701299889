import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Report } from '../../models/report';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

const listQuery = gql`
query listReportsForSite($id: ID) {
  site(id: $id) {
    id
    reports {
      id
      created
      data
    }
  }
}
`;

const getByIdQuery = gql`
query getReportById($id: ID) {
  report(id: $id) {
    id
    created
    data
  }
}
`;

const mutation = gql`
mutation reportMutation($report: ireport!) {
  report(report: $report) {
    created
    data
    id
    site {
      id
    }
  }
}
`;

const deleteMutation = gql`
mutation DeleteReportMutation($report: ireport!) {
  deleteReport(report: $report)
}
`;

function formatReport(raw: any): Report {
  return <Report>{
    ...raw,
    created: new Date(raw.created),
    data: JSON.parse(raw.data)
  };
}

@Injectable()
export class ReportService {

  activeReport: Report;

  constructor(private apollo: Apollo) {
  }

  list(id: string): Observable<Report[]> {
    return this.apollo.query({ query: listQuery, variables: { id } }).pipe(
      map(result => <Report[]>(<any>result.data).site.reports.map(formatReport)
      )
    );
  }

  get(id: string): Observable<Report> {
    return this.apollo.query({ query: getByIdQuery, variables: { id } }).pipe(
      map(t => formatReport(<Report>(<any>t.data).report))
    );
  }

  create(model: Report): Observable<Report> {
    if (model.id !== undefined && model.id !== null && model.id !== '') {
      throw new Error('You can only create a new Site');
    }

    return this.apollo.mutate({
      mutation, variables: {
        report: {
          ...model,
          data: JSON.stringify(model.data)
        }
      }
    }).pipe(
      map(t => formatReport(<Report>(<any>t.data).report))
    );
  }

  update(model: Report): Observable<Report> {
    if (!model.id || typeof model.id !== 'string' || model.id === '') {
      throw new Error('You can only update an existing Site');
    }

    return this.apollo.mutate({
      mutation, variables: {
        report: {
          ...model,
          data: JSON.stringify(model.data)
        }
      }
    }).pipe(
      map(t => formatReport(<Report>(<any>t.data).report))
    );
  }

  delete(model: Report, siteId: String): Observable<Object> {
    if (!model.id || typeof model.id !== 'string' || model.id === '') {
      throw new Error('You can only update an existing User');
    }

    return this.apollo.mutate({
      mutation: deleteMutation,
      variables: {
        report: {
          ...model,
          data: JSON.stringify(model.data)
        }
      },
      refetchQueries: [{
        query: listQuery,
        variables: { id: siteId },
      }]
    });
  }

}
