<form [formGroup]="reportForm" (ngSubmit)="onSubmit()" novalidate>
  <div class="flex-container">
    <app-panel>
      <app-panel-header>Kohteen tiedot</app-panel-header>
      <div class="detail-block">
        <h1>{{ site.name }}</h1>
        <h2>{{ site.customer.name }}</h2>
        {{ site.address }}<br>
        {{ site.zipCode }}
      </div>
    </app-panel>
    <app-panel>
      <app-panel-header>Raportin yleistiedot</app-panel-header>
      <div class="form-field date-aligh">
        <span>Torjunta-alue</span>
        <input placeholder="Anna syy torjunnalle" formControlName="pestControlZone" #autofocus>
      </div>
      <div class="form-field date-aligh">
        <span>Torjunnan kohde</span>
        <input placeholder="Anna syy torjunnalle" formControlName="pestControlReason">
      </div>
      <div class="form-field">
        <span>Päivämäärä</span>
        <input placeholder="Valitse päivä" formControlName="date" [matDatepicker]="myDatepicker">
        <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #myDatepicker></mat-datepicker>
      </div>
      <div class="form-field date-aligh">
        <span>Tekijä</span>
        <input placeholder="Tekijän nimi" formControlName="operator">
      </div>
    </app-panel>
  </div>

  <div class="flex-container">
    <app-panel>
      <app-panel-header>Syöttiansat</app-panel-header>
      <div class="trap-row trap-header">
        <div class="header-cell agent-cell">Tunniste</div>
        <div class="header-cell small">Syönti</div>
        <div class="header-cell flex">Tuholainen</div>
        <div class="header-cell agent-cell">Torjunta-aine</div>
        <div class="header-cell small">Määrä</div>
        <div class="header-cell delete"></div>
      </div>
      <div formArrayName="baitTrapReports" *ngFor="let item of baitTrapReports.controls; let i = index">
        <div class="trap-row" [formGroupName]="i" #baitTrapRow>
          <input placeholder="#" formControlName="id" class="agent-cell">
          <input placeholder="0%" formControlName="caught" class="small">
          <input placeholder="Tuholainen" formControlName="pests" class="flex">
          <select formControlName="agentId" class="agent-cell">
            <option [value]="null">Valitse</option>
            <option *ngFor="let agent of baitAgents" [value]="agent.id">{{ agent.displayName }}</option>
          </select>
          <input placeholder="0" formControlName="agentAmount" class="small">
          <button class="delete" mat-raised-button color="warn" (click)="removeRow('bait', i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <div>
        <div class="trap-row">
          <input placeholder="#" class="agent-cell" (input)="addBaitTrapRow('id', $event.target, $event.data)">
          <input placeholder="0%" class="small" (input)="addBaitTrapRow('caught', $event.target, $event.data)">
          <input placeholder="Tuholainen" class="flex" (input)="addBaitTrapRow('pests', $event.target, $event.data)">
          <select class="agent-cell" (change)="addBaitTrapRow('agentId', $event.target, $event.target.value)">
            <option [value]="null">Valitse</option>
            <option *ngFor="let agent of baitAgents" [value]="agent.id">{{ agent.displayName }}</option>
          </select>
          <input placeholder="0" class="small" (input)="addBaitTrapRow('agentAmount', $event.target, $event.data)">
          <div class="delete"></div>
        </div>
      </div>
    </app-panel>
  </div>

  <div class="flex-container">
    <app-panel>
      <app-panel-header>Ruiskutetorjunta-alueet</app-panel-header>
      <div class="trap-row trap-header">
        <div class="header-cell flex">Alue</div>
        <div class="header-cell agent-cell">Torjunta-aine</div>
        <div class="header-cell small">Määrä</div>
        <div class="header-cell delete"></div>
      </div>
      <div formArrayName="sprayTrapReports" *ngFor="let item of sprayTrapReports.controls; let i = index">
        <div class="trap-row" [formGroupName]="i" #sprayTrapRow>
          <input placeholder="Alue" formControlName="zoneName" class="flex">
          <select formControlName="agentId" class="agent-cell">
            <option [value]="null">Valitse</option>
            <option *ngFor="let agent of sprayAgents" [value]="agent.id">{{ agent.displayName }}</option>
          </select>
          <input placeholder="0" formControlName="agentAmount" class="small">
          <button class="delete" mat-raised-button color="warn" (click)="removeRow('spray', i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <div>
        <div class="trap-row">
          <input placeholder="Alue" class="flex" (input)="addSprayTrapRow('zoneName', $event.target, $event.data)">
          <select class="agent-cell" (change)="addSprayTrapRow('agentId', $event.target, $event.target.value)">
            <option>Valitse</option>
            <option *ngFor="let agent of sprayAgents" [value]="agent.id">{{ agent.displayName }}</option>
          </select>
          <input placeholder="0" class="small" (input)="addSprayTrapRow('agentAmount', $event.target, $event.data)">
          <div class="delete"></div>
        </div>
      </div>
    </app-panel>
  </div>

  <div class="flex-container">
    <app-panel>
      <app-panel-header>Valoansat</app-panel-header>
      <div class="trap-row trap-header">
        <div class="header-cell agent-cell">Tunniste</div>
        <div class="header-cell small">Syönti</div>
        <div class="header-cell medium">Tarranvaihto</div>
        <div class="header-cell medium">Lampunvaihto</div>
        <div class="header-cell flex">Havainnot</div>
        <div class="header-cell delete"></div>
      </div>
      <div formArrayName="lightTrapReports" *ngFor="let item of lightTrapReports.controls; let i = index">
        <div class="trap-row" [formGroupName]="i" #lightTrapRow>
          <input placeholder="#" formControlName="id" class="agent-cell">
          <input placeholder="#" formControlName="caught" class="small">
          <mat-checkbox formControlName="paperChanged" class="medium"></mat-checkbox>
          <mat-checkbox formControlName="lightChanged" class="medium"></mat-checkbox>
          <input placeholder="Havainnot" formControlName="observations" class="flex">
          <button class="delete" mat-raised-button color="warn" (click)="removeRow('light', i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <div>
        <div class="trap-row">
          <input placeholder="#" class="agent-cell" (input)="addLightTrapRow('id', $event.target, $event.data)">
          <input placeholder="#" class="small" (input)="addLightTrapRow('caught', $event.target, $event.data)">
          <mat-checkbox class="medium" (change)="addLightTrapRow('paperChanged', $event, $event.data)"></mat-checkbox>
          <mat-checkbox class="medium" (change)="addLightTrapRow('lightChanged', $event, $event.data)"></mat-checkbox>
          <input placeholder="Havainnot" class="flex"
                 (input)="addLightTrapRow('observations', $event.target, $event.data)">
          <div class="delete"></div>
        </div>
      </div>
    </app-panel>
  </div>

  <div class="flex-container">
    <app-panel>
      <app-panel-header>Feromoniansat</app-panel-header>
      <div class="trap-row trap-header">
        <div class="header-cell agent-cell">Tunniste</div>
        <div class="header-cell small">Syönti</div>
        <div class="header-cell medium">Vaihdettu</div>
        <div class="header-cell flex">Havainnot</div>
      </div>
      <div formArrayName="pheromoneTrapReports" *ngFor="let item of pheromoneTrapReports.controls; let i = index">
        <div class="trap-row" [formGroupName]="i" #pheromoneTrapRow>
          <input placeholder="#" formControlName="id" class="agent-cell">
          <input placeholder="#" formControlName="caught" class="small">
          <mat-checkbox formControlName="changed" class="medium"></mat-checkbox>
          <input placeholder="Havainnot" formControlName="observations" class="flex">
          <button class="delete" mat-raised-button color="warn" (click)="removeRow('pheromone', i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <div>
        <div class="trap-row">
          <input placeholder="#" class="agent-cell" (input)="addPheromoneTrapRow('id', $event.target, $event.data)">
          <input placeholder="#" class="small" (input)="addPheromoneTrapRow('caught', $event.target, $event.data)">
          <mat-checkbox class="medium" (change)="addPheromoneTrapRow('changed', $event, $event.data)"></mat-checkbox>
          <input placeholder="Havainnot" class="flex"
                 (input)="addPheromoneTrapRow('observations', $event.target, $event.data)">
          <div class="delete"></div>
        </div>
      </div>
    </app-panel>
  </div>

  <div class="flex-container">
    <app-panel>
      <app-panel-header>Tuholaishallintakatselmus</app-panel-header>
      <div class="review-grid">
        <div class="form-field">
          <label>Kokonaiskuva</label>
          <textarea formControlName="pestControlReview"></textarea>
        </div>
      </div>
    </app-panel>
  </div>

  <div class="flex-container">
    <app-panel>
      <div class="control-container">
        <button mat-raised-button color="accent" type="button" (click)="onCancel()">
          Peruuta
        </button>
        <span class="flex-fill"></span>
        <button mat-raised-button color="primary" type="submit">
          Tallenna
        </button>
      </div>
    </app-panel>
  </div>
</form>
