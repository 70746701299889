/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./site-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i10 from "@angular/material/icon";
import * as i11 from "../../elements/list/list-item/list-item.component.ngfactory";
import * as i12 from "@angular/router";
import * as i13 from "../../elements/list/list-item/list-item.component";
import * as i14 from "../../elements/list/list.component.ngfactory";
import * as i15 from "../../elements/list/list.component";
import * as i16 from "./site-list.component";
import * as i17 from "../../services/session/session.service";
import * as i18 from "@angular/material/dialog";
var styles_SiteListComponent = [i0.styles];
var RenderType_SiteListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SiteListComponent, data: {} });
export { RenderType_SiteListComponent as RenderType_SiteListComponent };
function View_SiteListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_r, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.id; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.id; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_2); }); }
function View_SiteListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "control-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "select", [["class", "agent-cell"]], [[8, "value", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onCustomerSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SiteListComponent_2)), i1.ɵdid(3, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "fill"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "button", [["color", "primary"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAdd() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i9.View_MatIcon_0, i9.RenderType_MatIcon)), i1.ɵdid(8, 638976, null, 0, i10.MatIcon, [i1.ElementRef, i10.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["add"])), (_l()(), i1.ɵted(-1, 0, [" Lis\u00E4\u00E4 kohde "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.customers; _ck(_v, 3, 0, currVal_1); var currVal_4 = "primary"; _ck(_v, 6, 0, currVal_4); _ck(_v, 8, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeCustomer; _ck(_v, 1, 0, currVal_0); var currVal_2 = (i1.ɵnov(_v, 6).disabled || null); var currVal_3 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵnov(_v, 8).inline; _ck(_v, 7, 0, currVal_5); }); }
function View_SiteListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "app-list-item", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_ListItemComponent_0, i11.RenderType_ListItemComponent)), i1.ɵdid(1, 16384, null, 0, i12.RouterLink, [i12.Router, i12.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(2, 114688, null, 0, i13.ListItemComponent, [], null, null), (_l()(), i1.ɵted(3, 0, [" ", ""])), (_l()(), i1.ɵeld(4, 0, null, 0, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, 0, [" ", ""])), (_l()(), i1.ɵeld(6, 0, null, 0, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, 0, [" ", " "]))], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "/site/", _v.context.$implicit.id, ""); _ck(_v, 1, 0, currVal_0); _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_1); var currVal_2 = _v.context.$implicit.address; _ck(_v, 5, 0, currVal_2); var currVal_3 = _v.context.$implicit.zipCode; _ck(_v, 7, 0, currVal_3); }); }
function View_SiteListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-list-item", [], null, null, null, i11.View_ListItemComponent_0, i11.RenderType_ListItemComponent)), i1.ɵdid(1, 114688, null, 0, i13.ListItemComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, [" Asiakkaalla ei ole viel\u00E4 torjuntakohteita "]))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_SiteListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "flex-container column"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SiteListComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "app-list", [], null, null, null, i14.View_ListComponent_0, i14.RenderType_ListComponent)), i1.ɵdid(4, 114688, null, 0, i15.ListComponent, [], { contentPadding: [0, "contentPadding"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SiteListComponent_3)), i1.ɵdid(6, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SiteListComponent_4)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.session == null) ? null : ((_co.session.profile == null) ? null : _co.session.profile.software_role)) === "ADMIN"); _ck(_v, 2, 0, currVal_0); var currVal_1 = 0; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.activeSites; _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.activeSites.length === 0); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_SiteListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-site-list", [], null, null, null, View_SiteListComponent_0, RenderType_SiteListComponent)), i1.ɵdid(1, 114688, null, 0, i16.SiteListComponent, [i12.ActivatedRoute, i17.SessionService, i18.MatDialog, i12.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SiteListComponentNgFactory = i1.ɵccf("app-site-list", i16.SiteListComponent, View_SiteListComponent_Host_0, {}, {}, []);
export { SiteListComponentNgFactory as SiteListComponentNgFactory };
