var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
var customerListQuery = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nquery CustomerListQuery {\n  customers {\n    id\n    name\n    sites {\n      id\n    }\n    users {\n      id\n    }\n  }\n}\n"], ["\nquery CustomerListQuery {\n  customers {\n    id\n    name\n    sites {\n      id\n    }\n    users {\n      id\n    }\n  }\n}\n"])));
var customerByNameQuery = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\nquery FindCustomersByName($name: String) {\n  customers(name: $name) {\n    id\n    name\n    sites {\n      id\n    }\n    users {\n      id\n    }\n  }\n}\n"], ["\nquery FindCustomersByName($name: String) {\n  customers(name: $name) {\n    id\n    name\n    sites {\n      id\n    }\n    users {\n      id\n    }\n  }\n}\n"])));
var customerByIdQuery = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\nquery FindCustomerById($id: ID) {\n  customer(id: $id) {\n    id\n    name\n    sites {\n      id\n    }\n    users {\n      id\n    }\n  }\n}\n"], ["\nquery FindCustomerById($id: ID) {\n  customer(id: $id) {\n    id\n    name\n    sites {\n      id\n    }\n    users {\n      id\n    }\n  }\n}\n"])));
var customerMutation = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\nmutation MutateCustomer($customer: icustomer!) {\n  customer(customer: $customer) {\n    id\n    name\n    sites {\n      id\n    }\n    users {\n      id\n    }\n  }\n}\n"], ["\nmutation MutateCustomer($customer: icustomer!) {\n  customer(customer: $customer) {\n    id\n    name\n    sites {\n      id\n    }\n    users {\n      id\n    }\n  }\n}\n"])));
var CustomerService = /** @class */ (function () {
    function CustomerService(apollo) {
        this.apollo = apollo;
    }
    CustomerService.prototype.list = function () {
        return this.apollo.query({ query: customerListQuery }).pipe(map(function (result) { return result.data.customers; }));
    };
    CustomerService.prototype.find = function (name) {
        return this.apollo.query({ query: customerByNameQuery, variables: { name: name } }).pipe(map(function (result) { return result.data.customers; }));
    };
    CustomerService.prototype.get = function (id) {
        return this.apollo.query({
            query: customerByIdQuery,
            variables: { id: id.toString() }
        }).pipe(map(function (t) { return t.data.customer; }));
    };
    CustomerService.prototype.create = function (model) {
        if (model.id !== undefined && model.id !== null && model.id !== '') {
            throw new Error('You can only create a new Customer');
        }
        var variables = { customer: model };
        return this.apollo.mutate({ mutation: customerMutation, variables: variables }).pipe(map(function (t) { return t.data.customer; }));
    };
    CustomerService.prototype.update = function (model) {
        if (!model.id || typeof model.id !== 'string' || model.id === '') {
            throw new Error('You can only update an existing Customer');
        }
        var variables = { customer: model };
        return this.apollo.mutate({ mutation: customerMutation, variables: variables }).pipe(map(function (t) { return t.data.customer; }));
    };
    return CustomerService;
}());
export { CustomerService };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
