import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { UserService } from '../../services/user/user.service';
import { MatDialogRef } from '../../../../node_modules/@angular/material';
import { SearchResult } from '../../elements/search/search-result.model';

function confirmValueFrom(targetControlGetter: () => AbstractControl) {
  return (confirmControl: AbstractControl) => new Promise((resolve) => {
    const targetControl = targetControlGetter();
    if (confirmControl.value !== targetControl.value) {
      resolve({ confirmMismatch: true });
    } else {
      resolve({});
    }
  });
}

@Component({
  selector: 'app-user-form-dialog',
  templateUrl: './user-form-dialog.component.html',
  styleUrls: ['./user-form-dialog.component.scss']
})
export class UserFormDialogComponent implements OnInit {

  userForm: FormGroup;

  get permissions(): FormArray {
    return this.userForm.get('permissions') as FormArray;
  }

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private dialogRef: MatDialogRef<UserFormDialogComponent>
  ) { }

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      name: ['', Validators.required],
      username: ['', Validators.required],
      password: ['', Validators.minLength(8)],
      confirmPassword: ['', Validators.required, confirmValueFrom(() => this.userForm.get('password'))],
      admin: [false, Validators.required],
      permissions: this.formBuilder.array([], Validators.required)
    });
  }

  onSubmit() {
    if (this.userForm.valid) {
      this.userService.create({
        name: this.userForm.value.name,
        username: this.userForm.value.username,
        password: this.userForm.value.password,
        software_role: this.userForm.value.admin ? 'ADMIN' : 'USER'
      }).subscribe((user) => (
        Promise.all(this.userForm.value.permissions.map(permission =>
          permission.type === 'site' ?
            this.userService.addSitePermission(user.id, permission.targetId).toPromise() :
            this.userService.addCustomerPermission(user.id, permission.targetId).toPromise()
        )).then(() => this.dialogRef.close({
          succeeded: true,
          user: user
        }))
      ), (error) => {
        console.log('there was an error sending the query', error);
      });
    }
  }

  onPermissionSelected(event: SearchResult) {
    this.permissions.push(this.formBuilder.group({
      type: event.meta,
      targetId: event.value.id,
      displayName: event.displayName,
      write: false
    }));
  }

  removePermissions(index) {
    this.permissions.removeAt(index);
  }
}
