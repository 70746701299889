<h1 mat-dialog-title>Lisää uusi asiakkuus</h1>
<mat-dialog-content>
  <form [formGroup]="customerForm" novalidate (ngSubmit)="onSubmit()">
    <mat-form-field>
      <input matInput formControlName="name" placeholder="Asiakkaan nimi">
    </mat-form-field>
    <div class="controls">
      <button mat-raised-button color="primary" type="submit">
        <mat-icon>save</mat-icon>
        Lisää ja tallenna
      </button>
    </div>
  </form>
</mat-dialog-content>
