<div class="flex-container">
  <app-panel class="direction-row">
      <section>
        <h1>Oh no!</h1>
        <h3>We tried to look for the content you requested but our hard working bits couldn't find it!</h3>
        <button mat-button (click)="detailsVisible = !detailsVisible">Show details</button>
        <pre *ngIf="detailsVisible">{{ errorDetails }}</pre>
      </section>
      <section>
        <!-- TODO: Add a funny picture here -->
      </section>
  </app-panel>
</div>
