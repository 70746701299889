<form [formGroup]="userForm" novalidate (ngSubmit)="onSubmit()">
  <mat-form-field>
    <input matInput placeholder="Käyttäjän nimi" formControlName="name">
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Käyttäjätunnus" formControlName="username">
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Salasana" formControlName="password" type="password">
  </mat-form-field>
  <mat-form-field>
    <input matInput type="password" placeholder="Vahvista salasana" formControlName="confirmPassword">
  </mat-form-field>
  <div class="mat-form-field-wrapper">
    <mat-checkbox formControlName="admin">Pääkäyttäjä</mat-checkbox>
  </div>
  <div class="mat-form-field-wrapper">
    Käyttöoikeudet
    <app-search [clearOnSelect]="true" (onSelected)="onPermissionSelected($event)"></app-search>
    <mat-error *ngIf="permissions.invalid">Käyttäjä tarvii vähintään yhden käyttöoikeuden</mat-error>
    <mat-list dense>
      <mat-list-item *ngFor="let permissionControl of permissions.controls; let i = index" formArrayName="permissions">
        <span class="mat-form-field-wrapper" [formGroupName]="i">
          {{ permissionControl.value.displayName }}
          <!--<mat-checkbox formControlName="write"></mat-checkbox>-->
        </span>
        <button mat-icon-button type="button" color="warn" (click)="removePermissions(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-list-item>
    </mat-list>
  </div>
  <button mat-raised-button color="primary">
    Tallenna
  </button>
</form>
