/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navigation.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./navigation.component";
var styles_NavigationComponent = [i0.styles];
var RenderType_NavigationComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_NavigationComponent, data: {} });
export { RenderType_NavigationComponent as RenderType_NavigationComponent };
export function View_NavigationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "nav", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "section", [], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_NavigationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navigation", [], null, null, null, View_NavigationComponent_0, RenderType_NavigationComponent)), i1.ɵdid(1, 114688, null, 0, i2.NavigationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavigationComponentNgFactory = i1.ɵccf("app-navigation", i2.NavigationComponent, View_NavigationComponent_Host_0, {}, {}, ["*"]);
export { NavigationComponentNgFactory as NavigationComponentNgFactory };
