/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./panel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./panel.component";
var styles_PanelComponent = [i0.styles];
var RenderType_PanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PanelComponent, data: {} });
export { RenderType_PanelComponent as RenderType_PanelComponent };
export function View_PanelComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵeld(1, 0, null, null, 3, "section", [], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "padding": 0 }), i1.ɵncd(null, 1), i1.ɵncd(null, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.contentPadding); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-panel", [], null, null, null, View_PanelComponent_0, RenderType_PanelComponent)), i1.ɵdid(1, 114688, null, 0, i3.PanelComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PanelComponentNgFactory = i1.ɵccf("app-panel", i3.PanelComponent, View_PanelComponent_Host_0, { contentPadding: "contentPadding" }, {}, ["app-panel-header", "*", "app-panel-footer"]);
export { PanelComponentNgFactory as PanelComponentNgFactory };
