/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./map-editor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i4 from "@angular/material/list";
import * as i5 from "../../elements/map/map.component.ngfactory";
import * as i6 from "../../elements/map/map.component";
import * as i7 from "@angular/material/dialog";
import * as i8 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i9 from "@angular/material/button";
import * as i10 from "@angular/cdk/platform";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "@angular/platform-browser/animations";
import * as i13 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i14 from "@angular/material/icon";
import * as i15 from "./map-editor.component";
import * as i16 from "../../services/site/site.service";
var styles_MapEditorComponent = [i0.styles];
var RenderType_MapEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MapEditorComponent, data: {} });
export { RenderType_MapEditorComponent as RenderType_MapEditorComponent };
function View_MapEditorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 28, "div", [["class", "context-menu"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(2, { "left.px": 0, "top.px": 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 25, "mat-list", [["class", "mat-list"]], null, null, null, i3.View_MatList_0, i3.RenderType_MatList)), i1.ɵdid(4, 49152, null, 0, i4.MatList, [], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 5, "mat-list-item", [["class", "mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "click"], [null, "focus"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6)._handleFocus() !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6)._handleBlur() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.onAddTrap(0) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_MatListItem_0, i3.RenderType_MatListItem)), i1.ɵdid(6, 1097728, null, 3, i4.MatListItem, [i1.ElementRef, [2, i4.MatNavList]], null, null), i1.ɵqud(603979776, 2, { _lines: 1 }), i1.ɵqud(335544320, 3, { _avatar: 0 }), i1.ɵqud(335544320, 4, { _icon: 0 }), (_l()(), i1.ɵted(-1, 2, ["Lis\u00E4\u00E4 sis\u00E4sy\u00F6tti-ansa"])), (_l()(), i1.ɵeld(11, 0, null, 0, 5, "mat-list-item", [["class", "mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "click"], [null, "focus"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12)._handleFocus() !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12)._handleBlur() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.onAddTrap(1) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_MatListItem_0, i3.RenderType_MatListItem)), i1.ɵdid(12, 1097728, null, 3, i4.MatListItem, [i1.ElementRef, [2, i4.MatNavList]], null, null), i1.ɵqud(603979776, 5, { _lines: 1 }), i1.ɵqud(335544320, 6, { _avatar: 0 }), i1.ɵqud(335544320, 7, { _icon: 0 }), (_l()(), i1.ɵted(-1, 2, ["Lis\u00E4\u00E4 ulkosy\u00F6tti-ansa"])), (_l()(), i1.ɵeld(17, 0, null, 0, 5, "mat-list-item", [["class", "mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "click"], [null, "focus"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18)._handleFocus() !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 18)._handleBlur() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.onAddTrap(2) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_MatListItem_0, i3.RenderType_MatListItem)), i1.ɵdid(18, 1097728, null, 3, i4.MatListItem, [i1.ElementRef, [2, i4.MatNavList]], null, null), i1.ɵqud(603979776, 8, { _lines: 1 }), i1.ɵqud(335544320, 9, { _avatar: 0 }), i1.ɵqud(335544320, 10, { _icon: 0 }), (_l()(), i1.ɵted(-1, 2, ["Lis\u00E4\u00E4 feromoni-ansa"])), (_l()(), i1.ɵeld(23, 0, null, 0, 5, "mat-list-item", [["class", "mat-list-item"]], [[2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "click"], [null, "focus"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 24)._handleFocus() !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 24)._handleBlur() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.onAddTrap(3) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_MatListItem_0, i3.RenderType_MatListItem)), i1.ɵdid(24, 1097728, null, 3, i4.MatListItem, [i1.ElementRef, [2, i4.MatNavList]], null, null), i1.ɵqud(603979776, 11, { _lines: 1 }), i1.ɵqud(335544320, 12, { _avatar: 0 }), i1.ɵqud(335544320, 13, { _icon: 0 }), (_l()(), i1.ɵted(-1, 2, ["Lis\u00E4\u00E4 valoansa"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.contextMenu.position.x, _co.contextMenu.position.y); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = (i1.ɵnov(_v, 6)._avatar || i1.ɵnov(_v, 6)._icon); var currVal_2 = (i1.ɵnov(_v, 6)._avatar || i1.ɵnov(_v, 6)._icon); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = (i1.ɵnov(_v, 12)._avatar || i1.ɵnov(_v, 12)._icon); var currVal_4 = (i1.ɵnov(_v, 12)._avatar || i1.ɵnov(_v, 12)._icon); _ck(_v, 11, 0, currVal_3, currVal_4); var currVal_5 = (i1.ɵnov(_v, 18)._avatar || i1.ɵnov(_v, 18)._icon); var currVal_6 = (i1.ɵnov(_v, 18)._avatar || i1.ɵnov(_v, 18)._icon); _ck(_v, 17, 0, currVal_5, currVal_6); var currVal_7 = (i1.ɵnov(_v, 24)._avatar || i1.ɵnov(_v, 24)._icon); var currVal_8 = (i1.ɵnov(_v, 24)._avatar || i1.ɵnov(_v, 24)._icon); _ck(_v, 23, 0, currVal_7, currVal_8); }); }
export function View_MapEditorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { mapComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-map", [], null, [[null, "mapDataChange"], [null, "contextMenu"], [null, "trapUpdated"], [null, "trapClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mapDataChange" === en)) {
        var pd_0 = (_co.onMapDataChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("contextMenu" === en)) {
        var pd_1 = (_co.onContextMenu($event) !== false);
        ad = (pd_1 && ad);
    } if (("trapUpdated" === en)) {
        var pd_2 = (_co.onTrapChange($event) !== false);
        ad = (pd_2 && ad);
    } if (("trapClicked" === en)) {
        var pd_3 = (_co.onTrapClick($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i5.View_MapComponent_0, i5.RenderType_MapComponent)), i1.ɵdid(2, 4833280, [[1, 4], ["map", 4]], 0, i6.MapComponent, [i7.MatDialog, i1.ChangeDetectorRef], { editable: [0, "editable"], mapData: [1, "mapData"] }, { mapDataChange: "mapDataChange", contextMenu: "contextMenu", trapClicked: "trapClicked", trapUpdated: "trapUpdated" }), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "controls"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "button", [["color", "primary"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUpdateMap() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatButton_0, i8.RenderType_MatButton)), i1.ɵdid(5, 180224, null, 0, i9.MatButton, [i1.ElementRef, i10.Platform, i11.FocusMonitor, [2, i12.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i13.View_MatIcon_0, i13.RenderType_MatIcon)), i1.ɵdid(7, 638976, null, 0, i14.MatIcon, [i1.ElementRef, i14.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["save"])), (_l()(), i1.ɵted(-1, 0, [" Tallenna "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapEditorComponent_1)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = _co.mapData; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = "primary"; _ck(_v, 5, 0, currVal_4); _ck(_v, 7, 0); var currVal_6 = _co.contextMenuOpen; _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 5).disabled || null); var currVal_3 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵnov(_v, 7).inline; _ck(_v, 6, 0, currVal_5); }); }
export function View_MapEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-map-editor", [], null, null, null, View_MapEditorComponent_0, RenderType_MapEditorComponent)), i1.ɵdid(1, 114688, null, 0, i15.MapEditorComponent, [i7.MatDialogRef, i7.MAT_DIALOG_DATA, i1.ChangeDetectorRef, i16.SiteService, i7.MatDialog, i1.NgZone], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapEditorComponentNgFactory = i1.ɵccf("app-map-editor", i15.MapEditorComponent, View_MapEditorComponent_Host_0, {}, {}, []);
export { MapEditorComponentNgFactory as MapEditorComponentNgFactory };
