import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ControlAgentService } from '../../services/control-agent/control-agent.service';
import { map, tap } from 'rxjs/operators';
var CreateTrapDialogComponent = /** @class */ (function () {
    function CreateTrapDialogComponent(formBuilder, data, dialogRef, agentService) {
        this.formBuilder = formBuilder;
        this.data = data;
        this.dialogRef = dialogRef;
        this.agentService = agentService;
        if (data.id) {
            this.isNotNew = true;
        }
    }
    Object.defineProperty(CreateTrapDialogComponent.prototype, "type", {
        get: function () {
            return this.form.get('type').value;
        },
        enumerable: true,
        configurable: true
    });
    CreateTrapDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        var usesAgent = this.data && this.data.agentId && this.data.agentId < 2;
        this.form = this.formBuilder.group({
            id: [this.data.id || null, Validators.required],
            comment: [this.data.comment],
            agentId: [this.data.agentId || null, usesAgent ? Validators.required : undefined],
            type: [this.data.type],
            location: [this.data.location, Validators.required]
        });
        this.agents = this.agentService.list().pipe(map(function (entries) { return entries.filter(function (t) { return !t.isSpray; }); }), tap(function (entries) {
            if (usesAgent && !_this.data || !_this.data.agentId) {
                _this.form.get('agentId').setValue(entries[0].id);
            }
        }));
    };
    CreateTrapDialogComponent.prototype.onSubmit = function () {
        if (this.form.valid) {
            this.dialogRef.close({
                type: this.data ? 'update' : 'delete',
                trap: this.form.value
            });
        }
    };
    CreateTrapDialogComponent.prototype.onDelete = function () {
        if (confirm('Haluatko varmasti poistaa tämän ansan?')) {
            this.dialogRef.close({
                type: 'delete',
                trap: this.form.value
            });
        }
    };
    return CreateTrapDialogComponent;
}());
export { CreateTrapDialogComponent };
