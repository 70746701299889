/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./panel-split-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./panel-split-header.component";
var styles_PanelSplitHeaderComponent = [i0.styles];
var RenderType_PanelSplitHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PanelSplitHeaderComponent, data: {} });
export { RenderType_PanelSplitHeaderComponent as RenderType_PanelSplitHeaderComponent };
export function View_PanelSplitHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_PanelSplitHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-panel-split-header", [], null, null, null, View_PanelSplitHeaderComponent_0, RenderType_PanelSplitHeaderComponent)), i1.ɵdid(1, 4767744, null, 0, i2.PanelSplitHeaderComponent, [i1.ElementRef, i1.Renderer2], null, null)], null, null); }
var PanelSplitHeaderComponentNgFactory = i1.ɵccf("app-panel-split-header", i2.PanelSplitHeaderComponent, View_PanelSplitHeaderComponent_Host_0, { margin: "margin" }, {}, ["*"]);
export { PanelSplitHeaderComponentNgFactory as PanelSplitHeaderComponentNgFactory };
