import { Component, OnInit } from '@angular/core';
import { Customer } from '../../models/customer';
import { CustomerService } from '../../services/customer/customer.service';
import { MatDialog } from '@angular/material';
import { CustomerFormDialogComponent } from '../../dialogs/customer-form-dialog/customer-form-dialog.component';

@Component({
  selector: 'app-client-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {

  customers: Customer[] = [];

  constructor(private service: CustomerService, private dialog: MatDialog) { }

  ngOnInit() {
    this.service.list().subscribe(customers => this.customers = customers.slice().sort((a, b) => a.name.localeCompare(b.name)));
  }

  onAdd() {
    const dialogRef = this.dialog.open(CustomerFormDialogComponent, {
      position: {
        top: '74px'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.succeeded) {
        this.customers = this.customers.concat(result.customer);
      }
    });
  }

}
