import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { User } from '../../models/user';
import { AddSitePermissionMutation, AddSitePermissionMutationVariables } from './__generated__/AddSitePermissionMutation';
import { AddCustomerPermissionMutation, AddCustomerPermissionMutationVariables } from './__generated__/AddCustomerPermissionMutation';

const listQuery = gql`
query listUsers {
  users {
    id
    name
    username
    software_role
  }
}
`;

const listByNameQuery = gql`
query listUsersBy($name: String) {
  users(name: $name) {
    id
    name
    username
    software_role
  }
}
`;

const getByIdQuery = gql`
query getUserById($id: ID) {
  user(id: $id) {
    id
    name
    username
    software_role
  }
}
`;

// TODO: Add permissions to user schema in gql
const mutation = gql`
mutation userMutation($user: iuser!) {
  user(user: $user) {
    id
    name
    username
    software_role
  }
}
`;

const deleteMutation = gql`
mutation DeleteUserMutation($user: iuser!) {
  deleteUser(user: $user)
}
`;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private apollo: Apollo) {
  }

  list(): Observable<User[]> {
    return this.apollo.watchQuery({ query: listQuery }).valueChanges.pipe(
      map(result => <User[]>(<any>result.data).users)
    );
  }

  get(id: string): Observable<User> {
    return this.apollo.watchQuery({ query: getByIdQuery, variables: { id } }).valueChanges.pipe(
      map(t => <User>(<any>t.data).user)
    );
  }

  create(model: User): Observable<User> {
    if (model.id !== undefined && model.id !== null && model.id !== '') {
      throw new Error('You can only create a new User');
    }

    return this.apollo.mutate({
      mutation,
      variables: { user: model },
      refetchQueries: [{
        query: listQuery,
      }]
    }).pipe(
      map(t => <User>(<any>t.data).user)
    );
  }

  update(model: User): Observable<User> {
    if (!model.id || typeof model.id !== 'string' || model.id === '') {
      throw new Error('You can only update an existing User');
    }

    return this.apollo.mutate({
      mutation,
      variables: { user: model },
      refetchQueries: [{
        query: listQuery,
      }]
    }).pipe(
      map(t => <User>(<any>t.data).user)
    );
  }

  delete(model: User): Observable<Object> {
    if (!model.id || typeof model.id !== 'string' || model.id === '') {
      throw new Error('You can only update an existing User');
    }

    return this.apollo.mutate({
      mutation: deleteMutation,
      variables: { user: model },
      refetchQueries: [{
        query: listQuery,
      }]
    });
  }

  changePassword(id: string, password: string) {
    if (!id || typeof id !== 'string') {
      throw new Error('You can only change password for an existing User');
    }

    return this.update({ id, password });
  }

  addSitePermission(userId: string, siteId: string, permission: string = 'READ') {
    if (!userId || typeof userId !== 'string' || userId === '') {
      throw new Error('You can only add permissions on an existing User');
    }
    if (!siteId || typeof siteId !== 'string' || siteId === '') {
      throw new Error('You can only add permissions on an existing Site');
    }

    return this.apollo.mutate<AddSitePermissionMutation, AddSitePermissionMutationVariables>({
      mutation: gql`
        mutation AddSitePermissionMutation($input: isitePermission!) {
          userSite(userSite: $input) {
            id
          }
        }
      `,
      variables: {input: {
        permission,
        site: {id: siteId},
        user: {id: userId}
      }}
    });
  }

  addCustomerPermission(userId: string, customerId: string, permission: string = 'READ') {
    if (!userId || typeof userId !== 'string' || userId === '') {
      throw new Error('You can only add permissions on an existing User');
    }
    if (!customerId || typeof customerId !== 'string' || customerId === '') {
      throw new Error('You can only add permissions on an existing Customer');
    }

    return this.apollo.mutate<AddCustomerPermissionMutation, AddCustomerPermissionMutationVariables>({
      mutation: gql`
        mutation AddCustomerPermissionMutation($input: icustomerPermission!) {
          userCustomer(userCustomer: $input) {
            id
          }
        }
      `,
      variables: {input: {
        permission,
        customer: {id: customerId},
        user: {id: userId}
      }}
    });
  }
}
