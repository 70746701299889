import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { ControlAgent } from '../../models/control-agent';
import { ControlAgentService } from '../../services/control-agent/control-agent.service';
import { Observable } from 'rxjs';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-control-agent',
  templateUrl: './control-agent.component.html',
  styleUrls: ['./control-agent.component.scss']
})
export class ControlAgentComponent implements OnInit {

  controlAgents: ControlAgent[] = [];
  @ViewChildren('controlAgentRow', {read: ElementRef}) controlAgentRows;

  constructor(private service: ControlAgentService, private formBuilder: FormBuilder, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.service.list().subscribe(agents => {
      this.controlAgents = agents;
    });
  }

  addControlAgentRow(controlName: string, input: HTMLInputElement, value: any) {
    const agentInitialValue = { id: null, displayName: '', agent: null, maxConcentration: null, unit: null, isSpray: false };
    agentInitialValue[controlName] = value;
    input.value = null;
    this.controlAgents = this.controlAgents.concat(agentInitialValue);
    this.changeDetectorRef.detectChanges();
    this.controlAgentRows.last.nativeElement.querySelectorAll('[formControlName=' + controlName + ']')[0].focus();
    return false;
  }

}
