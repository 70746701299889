<form [formGroup]="agentForm" novalidate (ngSubmit)="onSubmit()">
  <mat-form-field>
    <input matInput placeholder="Tuotenimi" formControlName="displayName">
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Vaikuttava-aine" formControlName="agent">
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Max %" formControlName="maxConcentration">
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Mittayksikkö (esim. g)" formControlName="unit">
  </mat-form-field>
  <mat-checkbox formControlName="isSpray">Ruiskutettava</mat-checkbox>
  <button mat-raised-button color="accent" type="button" (click)="editing = true" *ngIf="!editing">
    <mat-icon>edit</mat-icon>
  </button>
  <button mat-raised-button color="primary" type="submit" *ngIf="editing">
    <mat-icon>save</mat-icon>
  </button>
</form>
